import { GET_RUQUESTED_CALLBACKS, GET_RUQUESTED_CALLBACKS_SUCCESS, GET_RUQUESTED_CALLBACKS_FAILURE } from '../actions/callbacks';

const initialState = {
    loading: false,
    requestedCallbacks: null,
    error: null,
};

export const callbacks = (data = initialState, action) => {
    switch (action.type) {
        case GET_RUQUESTED_CALLBACKS:
            return {
                ...data,
                loading: true,
                error: null
            };

        case GET_RUQUESTED_CALLBACKS_SUCCESS:
            return {
                ...data,
                requestedCallbacks: action.payload,
                loading: false,
                error: null
            };

        case GET_RUQUESTED_CALLBACKS_FAILURE:
            return {
                ...data,
                loading: false,
                error: action.payload
            };

        default:
            return data;
    }
}
import {
    GET_REQUESTED_SCHOOL_TRIPS,
    GET_REQUESTED_SCHOOL_TRIPS_SUCCESS,
    GET_REQUESTED_SCHOOL_TRIPS_FAILURE,
} from '../actions/schoolTrip';

const initialState = {
    schoolTrips: null,
    loading: false,
    error: null
}

export const schoolTrips = (data = initialState, action) => {
    switch (action.type) {
        case GET_REQUESTED_SCHOOL_TRIPS:
            return {
                ...data,
                loading: true,
                error: null
            }

        case GET_REQUESTED_SCHOOL_TRIPS_SUCCESS:
            return {
                ...data,
                loading: false,
                schoolTrips: action.payload
            }

        case GET_REQUESTED_SCHOOL_TRIPS_FAILURE:
            return {
                ...data,
                loading: false,
                error: action.payload,
            }

        default:
            return data
    }
}
import { useState, useEffect } from "react";

import {
    Typography,
    Box,
    Grid,
    AppBar,
    Tabs,
    Tab
} from "@mui/material";
import { TabPanel } from "../../components";
import TourIcon from "@mui/icons-material/Tour";
import Table from "./Table";
import { useDispatch, useSelector } from "react-redux"
import { getExperiences } from "../../services/actions/experiences"
import { BackdropLoading } from "../../components";

export const AdminExperiences = () => {
    const dispatch = useDispatch()
    const { experiences, loading, error } = useSelector(state => state.experiences)
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        dispatch(getExperiences())
    }, [dispatch])

    if (loading) return <BackdropLoading open={loading} />
    if (error) return <h1>Error...</h1>

    return (
        <Box>
            <Grid container sx={{ justifyContent: "space-between", py: 2, px: 5 }}>
                <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <TourIcon sx={{ fontSize: 40 }} />
                        <Typography variant='h5' component='h5' ml={1}>
                            Experiences
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            {
                experiences && experiences.length > 0 ? (
                    <Box>
                        <AppBar position='static'>
                            <Tabs
                                value={activeTab}
                                onChange={(e, value) => setActiveTab(value)}
                                sx={{ px: 3 }}
                                textColor="inherit"
                                indicatorColor="secondary"
                            >
                                <Tab label="All" />
                                <Tab label="In Review" />
                                <Tab label="Approved" />
                                {/* <Tab label="Rejected" /> */}
                            </Tabs>
                        </AppBar>

                        {/* All Experiences*/}
                        <TabPanel value={activeTab} index={0}>
                            <Box sx={{ px: 2, py: 3 }}>
                                <Table data={experiences} />
                            </Box>
                        </TabPanel>

                        {/* In Review Experiences*/}
                        <TabPanel value={activeTab} index={1}>
                            <Box sx={{ px: 2, py: 3 }}>
                                <Table data={experiences?.filter((exp) => exp.status === false)} />
                            </Box>
                        </TabPanel>

                        {/* Approved Experiences*/}
                        <TabPanel value={activeTab} index={2}>
                            <Box sx={{ px: 2, py: 3 }}>
                                <Table data={experiences?.filter((exp) => exp.status === true)} />
                            </Box>
                        </TabPanel>

                        {/* Rejected Experiences*/}
                        {/* <TabPanel value={activeTab} index={3}>
                    <Box sx={{ px: 2, py: 3 }}>
                        <Table data={experiences.filter((exp) => exp.status === "Rejected")} />
                    </Box>
                </TabPanel> */}

                    </Box>
                ) : (
                    <Box sx={{ px: 2, py: 3 }}>
                        <Typography variant="h5" component="h5" align="center">
                            No experiences found
                        </Typography>
                    </Box>
                )
            }

        </Box>
    );
};

import {
    GET_EXPERIENCE,
    GET_EXPERIENCE_SUCCESS,
    GET_EXPERIENCE_FAILURE,
    GET_EXPERIENCES,
    GET_EXPERIENCES_SUCCESS,
    GET_EXPERIENCES_FAILURE,
    CREATE_EXPERIENCE,
    CREATE_EXPERIENCE_SUCCESS,
    CREATE_EXPERIENCE_FAILURE,
    EDIT_EXPERIENCE,
    EDIT_EXPERIENCE_SUCCESS,
    EDIT_EXPERIENCE_FAILURE,
    DELETE_EXPERIENCE,
    DELETE_EXPERIENCE_SUCCESS,
    DELETE_EXPERIENCE_FAILURE
} from '../actions/experiences';

import { call, put, takeEvery } from 'redux-saga/effects';

import { expApi } from '../api';

function* getExperience(payload) {
    try {
        const response = yield call(expApi.getExperience, payload.id);
        yield put({ type: GET_EXPERIENCE_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_EXPERIENCE_FAILURE, payload: error });
    }
}

function* getExperiences() {
    try {
        const response = yield call(expApi.getAllExperiences);
        yield put({ type: GET_EXPERIENCES_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_EXPERIENCES_FAILURE, payload: error });
    }
}

function* createExperience(payload) {
    try {
        const response = yield call(expApi.createExperience, payload.data);
        if (response.data.success) {
            yield put({ type: CREATE_EXPERIENCE_SUCCESS, payload: response.data });
        } else {
            yield put({ type: CREATE_EXPERIENCE_FAILURE, payload: response.data.message });
        }
    } catch (error) {
        yield put({ type: CREATE_EXPERIENCE_FAILURE, payload: error });
    }
}

function* editExperience(payload) {
    try {
        const response = yield call(expApi.updateExperience, payload);
        if (response.data.success) {
            yield put({ type: EDIT_EXPERIENCE_SUCCESS, payload: response.data });
        } else {
            yield put({ type: EDIT_EXPERIENCE_FAILURE, payload: response.data.message });
        }
    } catch (error) {
        yield put({ type: EDIT_EXPERIENCE_FAILURE, payload: error });
    }
}

function* deleteExperience(payload) {
    try {
        const response = yield call(expApi.deleteExperience, payload);
        yield put({ type: DELETE_EXPERIENCE_SUCCESS, payload: response });
    } catch (error) {
        yield put({ type: DELETE_EXPERIENCE_FAILURE, payload: error });
    }
}

export default function* experienceSaga() {
    yield takeEvery(GET_EXPERIENCE, getExperience);
    yield takeEvery(GET_EXPERIENCES, getExperiences);
    yield takeEvery(CREATE_EXPERIENCE, createExperience);
    yield takeEvery(EDIT_EXPERIENCE, editExperience);
    yield takeEvery(DELETE_EXPERIENCE, deleteExperience);
}
import { call, put, takeEvery } from 'redux-saga/effects';
import { countriesApi } from '../api';
import {
    GET_COUNTRIES,
    GET_COUNTRIES_SUCCESS,
    GET_COUNTRIES_FAILURE,
    GET_COUNTRY,
    GET_COUNTRY_SUCCESS,
    GET_COUNTRY_FAILURE,
} from '../actions/countries';


function* getCountries() {
    try {
        const response = yield call(countriesApi.getCountries);
        yield put({ type: GET_COUNTRIES_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_COUNTRIES_FAILURE, payload: error });
    }
}

function* getCountry(payload) {
    try {
        const response = yield call(countriesApi.getCountry, payload);
        yield put({ type: GET_COUNTRY_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_COUNTRY_FAILURE, payload: error });
    }
}

export default function* countriesSaga() {
    yield takeEvery(GET_COUNTRIES, getCountries);
    yield takeEvery(GET_COUNTRY, getCountry);
}


import {
    GET_BANNERS,
    GET_BANNERS_SUCCESS,
    GET_BANNERS_FAILURE,
} from '../actions/banners';

const initialState = {
    banners: [],
    loading: false,
    error: null
}

export const banners = (data = initialState, action) => {
    switch (action.type) {
        case GET_BANNERS:
            return {
                ...data,
                loading: true,
                error: null
            }

        case GET_BANNERS_SUCCESS:
            return {
                ...data,
                loading: false,
                banners: action.payload
            }

        case GET_BANNERS_FAILURE:
            return {
                ...data,
                loading: false,
                error: action.payload,
            }

        default:
            return data
    }
}
import { useState, useEffect, useCallback, useRef } from "react";
import {
    Accordion,
    AccordionActions,
    AccordionSummary,
    AccordionDetails,
    Box,
    Grid,
    Typography,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Slider,
    FormHelperText,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    CircularProgress,
} from "@mui/material";
import { CheckCircle, Lock } from '@mui/icons-material';
import { AddBox, Delete } from "@mui/icons-material";
import { ConfirmBox, DialogBox, ImageCropper, BackdropLoading, Editor } from "../../components";
import { useNavigate } from "react-router-dom";
import { formValidator } from "../../utils";
import { expApi } from "../../services/api";
import { mediaApi } from "../../services/api"
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"
import { getSubjects } from "../../services/actions/subjects"
import { getGrades } from "../../services/actions/grades"
import { getExperienceTypes } from "../../services/actions/experienceTypes";
import { getDestinationTypes } from "../../services/actions/destinationTypes";

export const CreateExperience = () => {
    const dispatch = useDispatch()
    const { isAdmin, user } = useSelector(state => state.auth)
    const { subjects, loading: subjectsLoading } = useSelector(state => state.subjects)
    const { grades, loading: gradesLoading } = useSelector(state => state.grades)
    const { experienceTypes, loading: experienceTypesLoading } = useSelector(state => state.experienceTypes)
    const { destinationTypes, loading: destinationTypesLoading } = useSelector(state => state.destinationTypes)

    const { expId } = useParams()
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();
    const [accordionPanel, setAccordionPanel] = useState(0);
    const [showErrors, setShowErrors] = useState(false);

    // for asking confirmation
    const [dialogBox, setDialogBox] = useState(false)

    // for final confirmation
    const [confirmBox, setConfirmBox] = useState({
        open: false,
        type: "",
        message: "",
    })

    const [expDetails, setExpDetails] = useState({
        user_id: user.user.id,
        title: "",
        highlight: "",
        description: "",
        outcome: "",
        primary_subject_id: "",
        secondary_subject_id: "",
        duration: "",
        experience_type: "",
        destination_type: "",
        grades: [1, 6],
        group_size: 5,
        requirement: "",
        pickup_point: "",
        drop_point: "",
        inclusions: "",
        exclusions: "",
        cover_photo: "",
        fee: 99,
        images: [],
        notes: [],
        attending_info: "",
        cancellation_policy: "",
        terms_condition: "",
        event: "",
        no_of_days: 0,
        itineary: [],
        location: "",
        places: [],
        school_trips: false,
        status: false
    })

    const [itinearyDetails, setItinearyDetails] = useState([]);

    const handleItinearyDetailsChange = (dayIndex, updatedDetails) => {
        setItinearyDetails((prevDaysDetails) => {
            const updatedDaysDetails = [...prevDaysDetails];
            updatedDaysDetails[dayIndex] = { ...updatedDetails };
            return updatedDaysDetails;
        });
    };

    const handleIninearyDetailsChangeForExp = (dayIndex, updatedDetails) => {
        setExpDetails((prevExpDetails) => {
            const updatedItineary = [...prevExpDetails.itineary];
            updatedItineary[dayIndex] = { ...updatedDetails };
            return {
                ...prevExpDetails,
                itineary: updatedItineary,
            };
        });
    };

    const [note, setNote] = useState("")
    const [place, setPlace] = useState("")
    const [imageUploading, setImageUploading] = useState(false)
    const [imageUploadingError, setImageUploadingError] = useState(null)

    const handleExpDetailsChange = (e) => {
        const { name, value } = e.target;
        setExpDetails({
            ...expDetails,
            [name]: value,
        })
    }

    const handleEventChange = (e) => {
        const { name, value } = e.target;
        setExpDetails({
            ...expDetails,
            [name]: value,
        })
        if (value === "Singleday") {
            setExpDetails({
                ...expDetails,
                no_of_days: "1",
                event: "Singleday"
            })
        }
    }

    const handleNextPanel = (panel) => () => {
        if (isFormValid(panel)) {
            setAccordionPanel(panel + 1)
        } else {
            setShowErrors(true)
        }
    }

    const handlePreviousPanel = (panel) => () => {
        setAccordionPanel(panel - 1)
    }

    // Cover Photo Upload
    const handleCoverPhotoUpload = useCallback(async (image) => {
        try {
            setImageUploading(true);
            const formData = new FormData();
            formData.append("media", image);
            const response = await mediaApi.uploadImage(formData);

            if (response.data.success) {
                if (response.data.success) {
                    setExpDetails({ ...expDetails, cover_photo: response.data.data.url })
                }
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setImageUploading(false);
        }
    }, [expDetails]);

    //Gallery Photos Upload
    const handleGalleryPhotoUpload = useCallback(async (image) => {
        try {
            if (expDetails.images.length >= 9) {
                setImageUploadingError("You can upload maximum 9 images")
                return
            }
            setImageUploadingError(null)
            setImageUploading(true);
            const formData = new FormData();
            formData.append("media", image);
            const response = await mediaApi.uploadImage(formData);
            if (response.data.success) {
                if (expId) {
                    setExpDetails({ ...expDetails, images: [...expDetails.images, { image_url: response.data.data.url }] })
                } else {
                    setExpDetails({ ...expDetails, images: [...expDetails.images, response.data.data.url] })
                }
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setImageUploading(false);
            // setImageUploadingError(null)
        }
    }, [expDetails, expId])

    const handleDeleteGalleryPhoto = (index) => {
        setExpDetails({ ...expDetails, images: expDetails.images.filter((image, i) => i !== index) })
    }

    const handleSubmit = async () => {
        setDialogBox(false)
        setLoading(true)
        if (expId) {
            if (isAdmin) {
                const response = await expApi.updateExperience({
                    ...expDetails,
                    id: parseInt(expId),
                    group_size: expDetails.group_size.toString(),
                    fee: expDetails.fee.toString(),
                    notes: expDetails.notes.map((note) => note.notes),
                    images: expDetails.images.map((image) => image.image_url),
                    grades: getGradeIds(expDetails.grades),
                    duration: `${expDetails.no_of_days} Days`,
                    no_of_days: expDetails.no_of_days.toString(),
                    itineary: itinearyDetails,
                    places: expDetails.places.map((place) => place.place),
                    status: true
                })
                if (response.data.success) {
                    setConfirmBox({
                        open: true,
                        type: "success",
                        message: "Experience edited successfully",
                    })
                } else {
                    setConfirmBox({
                        open: true,
                        type: "error",
                        message: "Something went wrong. Try again!",
                    })
                }
            } else {
                const response = await expApi.updateExperience({
                    ...expDetails,
                    id: parseInt(expId),
                    group_size: expDetails.group_size.toString(),
                    fee: expDetails.fee.toString(),
                    notes: expDetails.notes.map((note) => note.notes),
                    images: expDetails.images.map((image) => image.image_url),
                    grades: getGradeIds(expDetails.grades),
                    duration: `${expDetails.no_of_days} Days`,
                    no_of_days: expDetails.no_of_days.toString(),
                    itineary: itinearyDetails,
                    places: expDetails.places.map((place) => place.place),
                    status: expDetails.status
                })
                if (response.data.success) {
                    setConfirmBox({
                        open: true,
                        type: "success",
                        message: "Experience edited successfully",
                    })
                } else {
                    setConfirmBox({
                        open: true,
                        type: "error",
                        message: "Something went wrong. Try again!",
                    })
                }
            }
        } else {
            const response = await expApi.createExperience({
                ...expDetails,
                user_id: user.user.id,
                grades: getGradeIds(expDetails.grades),
                group_size: expDetails.group_size.toString(),
                fee: expDetails.fee.toString(),
                notes: expDetails.notes.map((note) => note.notes),
                duration: `${expDetails.no_of_days} Days`,
                no_of_days: expDetails.no_of_days.toString(),
                itineary: itinearyDetails,
                places: expDetails.places.map((place) => place.place),
            })
            if (response.data.success) {
                setConfirmBox({
                    open: true,
                    type: "success",
                    message: "Experience created successfully",
                })
            } else {
                setConfirmBox({
                    open: true,
                    type: "error",
                    message: "Something went wrong. Try again!",
                })
            }
        }
        setLoading(false)
    }

    const handleReject = async () => {
        setLoading(true)
        const response = await expApi.updateExperience({
            ...expDetails,
            id: parseInt(expId),
            group_size: expDetails.group_size.toString(),
            fee: expDetails.fee.toString(),
            notes: expDetails.notes.map((note) => note.notes),
            images: expDetails.images.map((image) => image.image_url),
            grades: getGradeIds(expDetails.grades),
            duration: `${expDetails.no_of_days} Days`,
            no_of_days: expDetails.no_of_days.toString(),
            itineary: itinearyDetails,
            places: expDetails.places.map((place) => place.place),
            status: false
        })
        if (response.data.success) {
            setConfirmBox({
                open: true,
                type: "success",
                message: "Experience Rejected successfully",
            })
        } else {
            setConfirmBox({
                open: true,
                type: "error",
                message: "Something went wrong. Try again!",
            })
        }
        setLoading(false)
    }

    // formValidation
    const isFormValid = (panel) => {
        switch (panel) {
            case 0:
                return (
                    formValidator.stringLength(expDetails.title.trim(), 5, 50) &&
                    !!expDetails.primary_subject_id &&
                    !!expDetails.secondary_subject_id &&
                    formValidator.stringLength(expDetails.highlight.trim(), 10, 250) &&
                    formValidator.stringLength(expDetails.description, 100, 1500) &&
                    formValidator.stringLength(expDetails.outcome, 100, 1500) &&
                    !!expDetails.event &&
                    !!expDetails.experience_type &&
                    !!expDetails.destination_type &&
                    !!expDetails.places?.length
                )

            case 1:
                return (
                    formValidator.stringLength(expDetails.requirement, 0, 1500)
                )

            case 2:
                return (
                    itinearyDetails.length > 0 &&
                    itinearyDetails.every((itineary) => {
                        return (
                            formValidator.stringLength(itineary.title, 10, 50) &&
                            formValidator.stringLength(itineary.description, 0, 1500)
                        )
                    })
                )

            case 3:
                return (
                    formValidator.stringLength(expDetails.inclusions, 0, 1000) &&
                    formValidator.stringLength(expDetails.exclusions, 0, 1000) &&
                    formValidator.stringLength(expDetails.notes, 0, 1000)
                )

            case 4:
                return (
                    !!expDetails.cover_photo &&
                    !!expDetails.images
                )

            case 5:
                return (
                    !!expDetails.fee &&
                    expDetails.fee >= 99 &&
                    expDetails.fee <= 500000
                )

            case 6:
                return (
                    formValidator.stringLength(expDetails.attending_info, 0, 500) &&
                    formValidator.stringLength(expDetails.cancellation_policy, 10, 500) &&
                    formValidator.stringLength(expDetails.terms_condition, 0, 500)
                )

            default:
                return false
        }
    }

    // get grade ids
    const getGradeIds = (gradeArr) => {
        const data = grades.filter((grade) => {
            return gradeArr.includes(parseInt(grade?.grade))
        })
        return data.map((grade) => grade?.id)
    }

    const groupSizes = [
        {
            value: 5,
            label: "5",
        },
        {
            value: 10,
            label: "10",
        },
        {
            value: 15,
            label: "15",
        },
        {
            value: 20,
            label: "20",
        },
        {
            value: 25,
            label: "25",
        },
        {
            value: 30,
            label: "30",
        },
        {
            value: 40,
            label: "40",
        },
        {
            value: 50,
            label: "50",
        },
        {
            value: 75,
            label: "75",
        },
        {
            value: 100,
            label: "100",
        },
        {
            value: 150,
            label: "150",
        },
        {
            value: 200,
            label: "200",
        }
    ]

    const eventTypes = [
        {
            name: "Single Day",
            value: "Singleday",
        },
        {
            name: "Multi Day",
            value: "Multiday",
        },
    ]
    const locationTypes = ["Domestic", "International"]

    // for Itineary
    const debounce = (func, delay) => {
        let timeoutId;
        return function debounced(...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    // for Itineary
    const debouncedSetItinearyDetailsRef = useRef(
        debounce((itinearyDetails) => {
            setItinearyDetails(itinearyDetails);
        }, 100)
    );

    const debouncedSetItinearyDetails = (itinearyDetails) => {
        debouncedSetItinearyDetailsRef.current(itinearyDetails);
    };

    // Edit
    useEffect(() => {
        if (!expId) return
        const getExpData = async () => {
            try {
                setLoading(true)
                const response = await expApi.getExperience(expId)
                if (response.data.success) {
                    const data = response.data.data
                    setExpDetails({
                        ...data.experience,
                        user_id: data?.experience?.partner?.user?.id,
                        primary_subject_id: data?.experience?.primary_subject.id,
                        secondary_subject_id: data?.experience?.secondary_subject.id,
                        notes: data?.things_to_note,
                        images: data?.images,
                        attending_info: data?.policy[0]?.attending_info,
                        cancellation_policy: data?.policy[0]?.cancellation_policy,
                        terms_condition: data?.policy[0]?.terms_condition,
                        grades: data?.grade.map((g) => parseInt(g.grade.grade)),
                        itineary: data?.itineary?.map((itineary) => ({
                            title: itineary?.title,
                            description: itineary?.description,
                        })),
                        places: data?.places
                    })
                }
            } catch (error) {
                console.error(error)
            } finally {
                setLoading(false)
            }
        }
        getExpData()
    }, [expId])

    // for Itineary
    useEffect(() => {
        if (expId) {
            if (expDetails?.itineary?.length) {
                debouncedSetItinearyDetails(expDetails?.itineary);
            }
        }
    }, [expDetails?.itineary, expId])

    // Subjects
    useEffect(() => {
        if (!subjectsLoading && subjects.length === 0) {
            dispatch(getSubjects())
        }
    }, [dispatch, subjectsLoading, subjects])

    // Grades
    useEffect(() => {
        if (!gradesLoading && grades.length === 0) {
            dispatch(getGrades())
        }
    }, [dispatch, gradesLoading, grades])

    // Experinece Types
    useEffect(() => {
        if (!experienceTypesLoading && experienceTypes.length === 0) {
            dispatch(getExperienceTypes())
        }
    }, [dispatch, experienceTypesLoading, experienceTypes])

    // Destination Types
    useEffect(() => {
        if (!destinationTypesLoading && destinationTypes.length === 0) {
            dispatch(getDestinationTypes())
        }
    }, [dispatch, destinationTypesLoading, destinationTypes])

    // Effect for Itineary
    useEffect(() => {
        if (expDetails.event === "Singleday") {
            setItinearyDetails(Array.from({ length: 1 }, (_, i) => ({
                title: "",
                description: "",
            })));
        } else {
            setItinearyDetails(Array.from({ length: Number(expDetails.no_of_days) }, (_, i) => ({
                title: "",
                description: "",
            })));
        }
    }, [expDetails.no_of_days, expDetails.event]);

    if (loading || subjectsLoading || gradesLoading || experienceTypesLoading || destinationTypesLoading) return <BackdropLoading open={true} />

    return (
        <Box sx={{ py: 2, px: { lg: 5, md: 0 } }}>
            <Box>
                <Typography variant="h4">Create Experience</Typography>
            </Box>

            {/* Create Experience Accrodion */}
            <Box>
                {/* Section 1 - Define the Experience */}
                <Accordion expanded={accordionPanel === 0}>
                    <AccordionSummary
                        expandIcon={accordionPanel !== 0 && <CheckCircle />}
                        aria-controls='panel1bh-content'
                        id='panel1bh-header'
                    >
                        <Typography >Define the Experience</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            {/* Title */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="title"
                                    label="Experience Title"
                                    placeholder="Experience Title"
                                    variant="standard"
                                    name="title"
                                    required
                                    value={expDetails.title}
                                    onChange={handleExpDetailsChange}
                                    helperText={`Min 10 chars. ${expDetails.title?.trim().length}/50 Max`}
                                    fullWidth
                                    inputProps={
                                        {
                                            maxLength: 50,
                                        }
                                    }
                                    error={showErrors && expDetails.title.trim().length < 10}
                                />
                            </Grid>

                            {/* Primary Subject */}
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    fullWidth
                                    required
                                    variant="standard"
                                    error={showErrors && expDetails.primary_subject_id === ""}
                                >
                                    <InputLabel>Primary Subject</InputLabel>
                                    <Select
                                        value={expDetails.primary_subject_id}
                                        label="Primary Subject"
                                        onChange={handleExpDetailsChange}
                                        name="primary_subject_id"
                                    >
                                        {
                                            subjects.filter((subject) => subject.id !== expDetails.secondary_subject_id).map((subject) => (
                                                <MenuItem key={subject.id} value={subject.id}>
                                                    {subject.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                    {
                                        showErrors && expDetails.primary_subject_id === "" && (
                                            <FormHelperText>Primary Subject is required</FormHelperText>
                                        )
                                    }
                                </FormControl>
                            </Grid>

                            {/* Secondary Subject */}
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    fullWidth
                                    required
                                    variant="standard"
                                    error={showErrors && expDetails.secondary_subject_id === ""}
                                    disabled={expDetails.primary_subject_id === ""}
                                >
                                    <InputLabel>Secondary Subject</InputLabel>
                                    <Select
                                        value={expDetails.secondary_subject_id}
                                        label="Secondary Subject"
                                        onChange={handleExpDetailsChange}
                                        name="secondary_subject_id"
                                    >
                                        {
                                            subjects.filter((subject) => subject.id !== expDetails.primary_subject_id).map((subject) => (
                                                <MenuItem key={subject.id} value={subject.id}>
                                                    {subject.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                    {
                                        showErrors && expDetails.secondary_subject_id === "" && (
                                            <FormHelperText>Secondary Subject is required</FormHelperText>
                                        )
                                    }
                                </FormControl>
                            </Grid>

                            {/* Highlights */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="highlight"
                                    label="Highlights"
                                    placeholder="Highlights"
                                    variant="standard"
                                    name="highlight"
                                    required
                                    value={expDetails.highlight}
                                    onChange={handleExpDetailsChange}
                                    helperText={`Min 10 chars. ${expDetails.highlight?.trim().length}/250 Max`}
                                    fullWidth
                                    multiline
                                    inputProps={
                                        {
                                            maxLength: 250,
                                        }
                                    }
                                    error={showErrors && (
                                        expDetails.highlight.length < 10 ||
                                        expDetails.highlight.length > 250
                                    )}
                                />

                            </Grid>

                            {/* Description */}
                            <Grid item xs={12} sm={6}>
                                <Editor
                                    name="description"
                                    value={expDetails.description}
                                    placeholder="Enter Description"
                                    lable="Description"
                                    error={showErrors && (
                                        expDetails.description.length < 100 ||
                                        expDetails.description.length > 1500
                                    )}
                                    onChange={(data) => {
                                        setExpDetails({
                                            ...expDetails,
                                            description: data,
                                        })
                                    }}
                                    min={100}
                                    max={1500}
                                    required={true}
                                />
                            </Grid>

                            {/* outcomes */}
                            <Grid item xs={12} sm={6}>
                                <Editor
                                    name="outcome"
                                    value={expDetails.outcome}
                                    placeholder="Learning Outcome"
                                    lable="Learning Outcome"
                                    error={showErrors && (
                                        expDetails.outcome.length < 100 ||
                                        expDetails.outcome.length > 1500
                                    )}
                                    onChange={(data) => {
                                        setExpDetails({
                                            ...expDetails,
                                            outcome: data,
                                        })
                                    }}
                                    min={100}
                                    max={1500}
                                    required={true}
                                />
                            </Grid>

                            {/* Event */}
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    fullWidth
                                    required
                                    variant="standard"
                                    error={showErrors && expDetails.event === ""}
                                    disabled={!!expId}
                                >
                                    <InputLabel>Experience Length</InputLabel>
                                    <Select
                                        value={expDetails.event}
                                        label="Experience Length"
                                        onChange={handleEventChange}
                                        name="event"
                                    >
                                        {
                                            eventTypes.map((event) => (
                                                <MenuItem key={event.value} value={event.value}>
                                                    {event.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                    {
                                        showErrors && expDetails.event === "" && (
                                            <FormHelperText>Event Type is required</FormHelperText>
                                        )
                                    }
                                </FormControl>
                            </Grid>

                            {/* Location */}
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    fullWidth
                                    required
                                    variant="standard"
                                    error={showErrors && expDetails.location === ""}
                                    disabled={expDetails.event === ""}
                                >
                                    <InputLabel>Experience Category</InputLabel>
                                    <Select
                                        value={expDetails.location}
                                        label="Experience Category"
                                        onChange={handleExpDetailsChange}
                                        name="location"
                                    >
                                        {
                                            expDetails.event === "Singleday" ? (
                                                locationTypes.filter(item => item === "Domestic").map((location) => (
                                                    <MenuItem key={location} value={location}>
                                                        {location}
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                locationTypes.map((location) => (
                                                    <MenuItem key={location} value={location}>
                                                        {location}
                                                    </MenuItem>
                                                ))
                                            )
                                        }
                                    </Select>
                                    {
                                        showErrors && expDetails.location === "" && (
                                            <FormHelperText>Location is required</FormHelperText>
                                        )
                                    }
                                </FormControl>
                            </Grid>

                            {/* Exp Type */}
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    fullWidth
                                    required
                                    variant="standard"
                                    error={showErrors && expDetails.experience_type === ""}
                                >
                                    <InputLabel>Experience Type</InputLabel>
                                    <Select
                                        value={expDetails.experience_type}
                                        label="Experience Type"
                                        onChange={handleExpDetailsChange}
                                        name="experience_type"
                                    >
                                        {
                                            experienceTypes.map((exp) => (
                                                <MenuItem key={exp.id} value={exp.name}>
                                                    {exp.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                    {
                                        showErrors && expDetails.experience_type === "" && (
                                            <FormHelperText>Experience Type is required</FormHelperText>
                                        )
                                    }
                                </FormControl>
                            </Grid>


                            {/* Destination Type */}
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    fullWidth
                                    required
                                    variant="standard"
                                    error={showErrors && expDetails.destination_type === ""}
                                >
                                    <InputLabel>Destination Type</InputLabel>
                                    <Select
                                        value={expDetails.destination_type}
                                        label="Destination Type"
                                        onChange={handleExpDetailsChange}
                                        name="destination_type"
                                    >
                                        {
                                            destinationTypes.map((destination) => (
                                                <MenuItem key={destination.id} value={destination.name}>
                                                    {destination.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                    {
                                        showErrors && expDetails.destination_type === "" && (
                                            <FormHelperText>Destination Type is required</FormHelperText>
                                        )
                                    }
                                </FormControl>
                            </Grid>

                            {/* Places */}
                            <Grid container item xs={12} sm={6}>
                                <Grid item style={{ width: '85%' }}>
                                    <TextField
                                        value={place}
                                        onChange={(e) => setPlace(e.target.value)}
                                        label="Location"
                                        placeholder="Location"
                                        fullWidth
                                        size='medium'
                                        variant='standard'
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13 && !!place.trim()) {
                                                if(expDetails.places.length >= 3) {
                                                    setPlace('')
                                                    setShowErrors(true)
                                                    return
                                                }
                                                setExpDetails({
                                                    ...expDetails,
                                                    places: [...expDetails.places, { place: place }],
                                                })
                                                setPlace('')
                                            }
                                        }}
                                        error={showErrors && !expDetails.places.length}
                                        helperText="1-3 places"
                                    />
                                </Grid>
                                <Grid item >
                                    <IconButton
                                        color='primary'
                                        aria-label='add'
                                        size='medium'
                                        sx={{mt:1}}
                                        onClick={() => {
                                            if (place.trim()) {
                                                if(expDetails.places.length >= 3) {
                                                    setPlace('')
                                                    setShowErrors(true)
                                                    return
                                                }
                                                setExpDetails({
                                                    ...expDetails,
                                                    places: [...expDetails.places, { place: place }],
                                                })
                                                setPlace('')
                                            }
                                        }}
                                    >
                                        <AddBox fontSize='inherit' />
                                    </IconButton>
                                </Grid>

                            </Grid>

                            {/* Places List */}
                            <Grid item xs={12} sm={6}>
                                <List>
                                    {expDetails.places && expDetails.places.map((place, index) => (
                                        <ListItem key={index} sx={{ my: 0, py: 0.2 }}>
                                            <ListItemText primary={place?.place} />
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    edge='end'
                                                    aria-label='delete'
                                                    color='error'
                                                    onClick={() => {
                                                        setExpDetails({
                                                            ...expDetails,
                                                            places: expDetails.places.filter(
                                                                (item) => item !== place
                                                            ),
                                                        })
                                                    }}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                    <AccordionActions sx={{ display: "flex", justifyContent: "center", gap: "30px", py: 3 }}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => navigate("/dashboard/experiences")}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNextPanel(0)}
                        >
                            Next
                        </Button>
                    </AccordionActions>
                </Accordion>


                {/* Section 2 - Define the audience */}
                <Accordion expanded={accordionPanel === 1}>
                    <AccordionSummary
                        expandIcon={
                            accordionPanel < 1 ? (
                                <Lock />
                            ) : (
                                accordionPanel !== 1 && <CheckCircle />
                            )
                        }
                        aria-controls='panel2bh-content'
                        id='panel2bh-header'
                    >
                        <Typography>Define the audience</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            {/* Grades */}
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    paragraph
                                >
                                    Grades suitable for
                                </Typography>
                                <Slider
                                    value={expDetails.grades}
                                    onChange={(event, newValue) => {
                                        setExpDetails({
                                            ...expDetails,
                                            grades: newValue,
                                        })
                                    }}
                                    step={1}
                                    max={12}
                                    min={1}
                                    color='secondary'
                                    marks={[
                                        { value: 1, label: '1' },
                                        { value: 6, label: '6' },
                                        { value: 12, label: '12' },
                                    ]}
                                    valueLabelDisplay='auto'
                                    aria-labelledby='range-slider'
                                    getAriaValueText={(value) => `Grades ${value}`}
                                />
                            </Grid>

                            {/* Group Size */}
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    fullWidth
                                    required
                                    variant="standard"
                                >
                                    <InputLabel>Group Size</InputLabel>
                                    <Select
                                        value={expDetails.group_size}
                                        label="Group Size"
                                        onChange={handleExpDetailsChange}
                                        name="group_size"
                                    >
                                        {
                                            groupSizes.map((size) => (
                                                <MenuItem key={size.value} value={size.value}>
                                                    {size.label}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Requirements */}
                            <Grid item xs={12} sm={6}>
                                <Editor
                                    name="requirement"
                                    value={expDetails.requirement}
                                    placeholder="Requirements"
                                    lable="Requirements"
                                    onChange={(data) => {
                                        setExpDetails({
                                            ...expDetails,
                                            requirement: data,
                                        })
                                    }}
                                    error={showErrors && expDetails.requirement.length > 1500}
                                    max={1500}
                                    required={false}
                                />
                            </Grid>

                        </Grid>
                    </AccordionDetails>
                    <AccordionActions sx={{ display: "flex", justifyContent: "center", gap: "30px", py: 3 }}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handlePreviousPanel(1)}
                        >
                            Previous
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNextPanel(1)}
                        >
                            Next
                        </Button>

                    </AccordionActions>
                </Accordion>


                {/* Section 3 - Itinerary*/}
                <Accordion expanded={accordionPanel === 2}>
                    <AccordionSummary
                        expandIcon={
                            accordionPanel < 2 ? (
                                <Lock />
                            ) : (
                                accordionPanel !== 2 && <CheckCircle />
                            )
                        }
                        aria-controls='panel3bh-content'
                        id='panel3bh-header'
                    >
                        <Typography>Itinerary</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {/* No of days */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="no_of_days"
                                label="Number Of Days"
                                placeholder="Number Of Days"
                                variant="standard"
                                name="no_of_days"
                                type="number"
                                required
                                value={expDetails.event === "Singleday" ? 1 : expDetails.no_of_days}
                                disabled={expDetails.event === "Singleday" || !!expId}
                                onChange={handleExpDetailsChange}
                                fullWidth={window.innerWidth < 600 ? true : false}
                                error={showErrors && expDetails.no_of_days === ""}
                            />
                        </Grid>

                        {/* Itinerary */}
                        {
                            expId ? (
                                expDetails.itineary?.map((itineary, i) => {
                                    return (
                                        <Grid container key={i} sx={{ my: 4 }}>
                                            {/* Title */}
                                            <Grid item xs={12} sm={6} >
                                                <TextField
                                                    id={`title-${i}`}
                                                    label={`Day ${i + 1} Title`}
                                                    placeholder={`Day ${i + 1} Title`}
                                                    variant="standard"
                                                    value={itineary.title}
                                                    onChange={(e) =>
                                                        handleIninearyDetailsChangeForExp(i, {
                                                            ...itineary,
                                                            title: e.target.value,
                                                        })
                                                    }
                                                    fullWidth
                                                    sx={{ width: { xs: "100%", sm: "95%" } }}
                                                    helperText={`Min 10 chars. ${itineary.title.trim().length}/50 Max`}
                                                    inputProps={
                                                        {
                                                            maxLength: 50,
                                                        }
                                                    }
                                                    required
                                                    error={showErrors && itineary.title.trim().length < 10}
                                                />
                                            </Grid>

                                            {/* Description */}
                                            <Grid item xs={12} sm={6} sx={{ mt: { xs: 3, sm: 0 } }}>
                                                <Editor
                                                    name={`description-${i}`}
                                                    value={itineary.description}
                                                    placeholder={`Enter Day ${i + 1} Description`}
                                                    lable={`Day ${i + 1} Description`}
                                                    onChange={(data) =>
                                                        handleIninearyDetailsChangeForExp(i, {
                                                            ...itineary,
                                                            description: data,
                                                        })
                                                    }
                                                    max={1500}
                                                    required={true}
                                                    error={showErrors && itineary.description.length > 1500}
                                                />
                                            </Grid>

                                        </Grid>
                                    )
                                })
                            ) : (
                                itinearyDetails?.map((itineary, i) => {
                                    return (
                                        <Grid container key={i} sx={{ my: 4 }}>
                                            {/* Title */}
                                            <Grid item xs={12} sm={6} >
                                                <TextField
                                                    id={`title-${i}`}
                                                    label={`Day ${i + 1} Title`}
                                                    placeholder={`Day ${i + 1} Title`}
                                                    variant="standard"
                                                    value={itineary.title}
                                                    onChange={(e) =>
                                                        handleItinearyDetailsChange(i, {
                                                            ...itineary,
                                                            title: e.target.value,
                                                        })
                                                    }
                                                    fullWidth
                                                    sx={{ width: { xs: "100%", sm: "95%" } }}
                                                    helperText={`Min 10 chars. ${itineary.title.trim().length}/50 Max`}
                                                    inputProps={
                                                        {
                                                            maxLength: 50,
                                                        }
                                                    }
                                                    required
                                                    error={showErrors && itineary.title.trim().length < 10}
                                                />
                                            </Grid>

                                            {/* Description */}
                                            <Grid item xs={12} sm={6} sx={{ mt: { xs: 3, sm: 0 } }}>
                                                <Editor
                                                    name={`description-${i}`}
                                                    value={itineary.description}
                                                    placeholder={`Enter Day ${i + 1} Description`}
                                                    lable={`Day ${i + 1} Description`}
                                                    onChange={(data) =>
                                                        handleItinearyDetailsChange(i, {
                                                            ...itineary,
                                                            description: data,
                                                        })
                                                    }
                                                    max={1500}
                                                    required={true}
                                                    error={showErrors && itineary.description.length > 1500}
                                                />
                                            </Grid>

                                        </Grid>
                                    )
                                })
                            )
                        }
                    </AccordionDetails>
                    <AccordionActions sx={{ display: "flex", justifyContent: "center", gap: "30px", py: 3 }}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handlePreviousPanel(2)}
                        >
                            Previous
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNextPanel(2)}
                        >
                            Next
                        </Button>

                    </AccordionActions>
                </Accordion>


                {/* Section 4 - Logistics*/}
                <Accordion expanded={accordionPanel === 3}>
                    <AccordionSummary
                        expandIcon={
                            accordionPanel < 3 ? (
                                <Lock />
                            ) : (
                                accordionPanel !== 3 && <CheckCircle />
                            )
                        }
                        aria-controls='panel4bh-content'
                        id='panel4bh-header'
                    >
                        <Typography>Logistics</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            {/* Starting/Pickup Point */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="pickup_point"
                                    label="Starting/Pickup Point"
                                    placeholder="Starting/Pickup Point"
                                    variant="standard"
                                    name="pickup_point"
                                    value={expDetails.pickup_point}
                                    onChange={handleExpDetailsChange}
                                    fullWidth
                                />
                            </Grid>

                            {/* Ending/Drop Point*/}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="drop_point"
                                    label="Ending/Drop Point"
                                    placeholder="Ending/Drop Point"
                                    variant="standard"
                                    name="drop_point"
                                    value={expDetails.drop_point}
                                    onChange={handleExpDetailsChange}
                                    fullWidth
                                />
                            </Grid>

                            {/* Inclusions */}
                            <Grid item xs={12} sm={6}>
                                <Editor
                                    name="inclusions"
                                    value={expDetails.inclusions}
                                    placeholder="Inclusions"
                                    lable="Inclusions"
                                    onChange={(data) => {
                                        setExpDetails({
                                            ...expDetails,
                                            inclusions: data,
                                        })
                                    }}
                                    error={expDetails.inclusions?.length > 1000}
                                    max={1000}
                                    required={false}
                                />
                            </Grid>

                            {/* Exclusions */}
                            <Grid item xs={12} sm={6}>
                                <Editor
                                    name="exclusions"
                                    value={expDetails.exclusions}
                                    placeholder="Exclusions"
                                    lable="Exclusions"
                                    onChange={(data) => {
                                        setExpDetails({
                                            ...expDetails,
                                            exclusions: data,
                                        })
                                    }}
                                    error={expDetails.exclusions?.length > 1000}
                                    max={1000}
                                    required={false}
                                />
                            </Grid>

                            {/* Notes */}
                            <Grid container item xs={12} sm={6}>
                                <Grid item style={{ width: '85%' }}>
                                    <TextField
                                        value={note}
                                        onChange={(e) => setNote(e.target.value)}
                                        label="Things to note"
                                        placeholder="Things to note"
                                        fullWidth
                                        size='medium'
                                        variant='standard'
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13 && !!note.trim()) {
                                                setExpDetails({
                                                    ...expDetails,
                                                    notes: [...expDetails.notes, { notes: note }],
                                                })
                                                setNote('')
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item >
                                    <IconButton
                                        color='primary'
                                        aria-label='add'
                                        size='medium'
                                        onClick={() => {
                                            if (note) {
                                                setExpDetails({
                                                    ...expDetails,
                                                    notes: [...expDetails.notes, { notes: note }],
                                                })
                                                setNote('')
                                            }
                                        }}
                                    >
                                        <AddBox fontSize='inherit' />
                                    </IconButton>
                                </Grid>

                            </Grid>

                            {/* Notes List */}
                            <Grid item xs={12} sm={6}>
                                <List>
                                    {expDetails.notes && expDetails.notes.map((note, index) => (
                                        <ListItem key={index} sx={{ my: 0, py: 0.2 }}>
                                            <ListItemText primary={note.notes} />
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    edge='end'
                                                    aria-label='delete'
                                                    color='error'
                                                    onClick={() => {
                                                        setExpDetails({
                                                            ...expDetails,
                                                            notes: expDetails.notes.filter(
                                                                (item) => item !== note
                                                            ),
                                                        })
                                                    }}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                    <AccordionActions sx={{ display: "flex", justifyContent: "center", gap: "30px", py: 3 }}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handlePreviousPanel(3)}
                        >
                            Previous
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNextPanel(3)}
                        >
                            Next
                        </Button>

                    </AccordionActions>
                </Accordion>


                {/* Section 5 - Media*/}
                <Accordion expanded={accordionPanel === 4}>
                    <AccordionSummary
                        expandIcon={
                            accordionPanel < 4 ? (
                                <Lock />
                            ) : (
                                accordionPanel !== 4 && <CheckCircle />
                            )
                        }
                        aria-controls='panel5bh-content'
                        id='panel5bh-header'
                    >
                        <Typography>Media</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            {/* Cover Photo */}
                            <Grid item xs={12} sm={6}>
                                <Box>
                                    <Typography>
                                        Cover Photo
                                    </Typography>
                                    <FormHelperText sx={{ mb: 2 }}>
                                        This image will be displayed on the listings page & as the first image in details page
                                    </FormHelperText>
                                    <ImageCropper
                                        text="Upload Cover"
                                        btnWidth={180}
                                        handleSubmit={handleCoverPhotoUpload}
                                        loading={imageUploading}
                                    />
                                    {
                                        showErrors && !expDetails.cover_photo &&
                                        <Typography color="error" sx={{ mt: 2 }}>
                                            Cover Photo is required
                                        </Typography>
                                    }
                                </Box>
                                {
                                    expDetails.cover_photo && (
                                        <Box mt={1} sx={{ width: "180px", aspectRatio: "1.5/1" }}>
                                            <img
                                                src={expDetails.cover_photo}
                                                alt={expDetails.title}
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                    borderRadius: "10px",
                                                }}
                                            />
                                        </Box>
                                    )
                                }
                            </Grid>

                            {/* Gallery Photos */}
                            <Grid item xs={12} sm={6}>
                                <Box>
                                    <Typography>
                                        Gallery Photos
                                    </Typography>
                                    <FormHelperText sx={{ mb: 2 }}>
                                        These images will be displayed in the details page only (9 images max)
                                    </FormHelperText>
                                    <ImageCropper
                                        text="Upload Gallery"
                                        btnWidth={200}
                                        handleSubmit={handleGalleryPhotoUpload}
                                        loading={imageUploading}
                                    />
                                </Box>
                                {
                                    expDetails.images?.length > 0 && (
                                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }} mt={2}>
                                            {
                                                expDetails.images.map((image, index) => (
                                                    expId ? (
                                                        <Box key={index} sx={{ width: "180px", aspectRatio: "1.5/1", position: "relative" }}>
                                                            <img
                                                                src={image.image_url}
                                                                alt={expDetails.title}
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    objectFit: "cover",
                                                                    borderRadius: "10px",
                                                                }}
                                                            />
                                                            <IconButton
                                                                color="error"
                                                                aria-label="delete"
                                                                onClick={() => handleDeleteGalleryPhoto(index)}
                                                                sx={{ position: "absolute", top: "5px", right: "5px" }}
                                                            >
                                                                <Delete />
                                                            </IconButton>
                                                        </Box>
                                                    ) : (
                                                        <Box key={index} sx={{ width: "180px", aspectRatio: "1.5/1", position: "relative" }}>
                                                            <img
                                                                src={image}
                                                                alt={expDetails.title}
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    objectFit: "cover",
                                                                    borderRadius: "10px",
                                                                }}
                                                            />
                                                            <IconButton
                                                                color="error"
                                                                aria-label="delete"
                                                                onClick={() => handleDeleteGalleryPhoto(index)}
                                                                sx={{ position: "absolute", top: "5px", right: "5px" }}
                                                            >
                                                                <Delete />
                                                            </IconButton>
                                                        </Box>
                                                    )
                                                ))
                                            }

                                        </Box>
                                    )
                                }
                                {
                                    imageUploadingError &&
                                    <Typography color="error" sx={{ mt: 2 }}>
                                        {imageUploadingError}
                                    </Typography>
                                }
                                {
                                    showErrors && expDetails.images.length === 0 &&
                                    <Typography color="error" sx={{ mt: 2 }}>
                                        Photos are required
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                    <AccordionActions sx={{ display: "flex", justifyContent: "center", gap: "30px", py: 3 }}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handlePreviousPanel(4)}
                        >
                            Previous
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNextPanel(4)}
                        >
                            Next
                        </Button>

                    </AccordionActions>
                </Accordion>


                {/* Section 6 - Costing*/}
                <Accordion expanded={accordionPanel === 5}>
                    <AccordionSummary
                        expandIcon={
                            accordionPanel < 5 ? (
                                <Lock />
                            ) : (
                                accordionPanel !== 5 && <CheckCircle />
                            )
                        }
                        aria-controls='panel6bh-content'
                        id='panel6bh-header'
                    >
                        <Typography>Costing</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            {/* Price */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="fee"
                                    label="Price Per Child"
                                    placeholder="Enter Price Per Child"
                                    variant="standard"
                                    name="fee"
                                    required
                                    value={expDetails.fee}
                                    onChange={handleExpDetailsChange}
                                    helperText="Min 99, Max 500000"
                                    fullWidth
                                    type="number"
                                    error={showErrors && (
                                        expDetails.fee < 99 || expDetails.fee > 500000
                                    )}
                                />

                            </Grid>

                            {/* School Trip */}
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    fullWidth
                                    required
                                    variant="standard"
                                >
                                    <InputLabel>Open for school trips ?</InputLabel>
                                    <Select
                                        value={expDetails.school_trips}
                                        label="Open for school trips ?"
                                        onChange={handleExpDetailsChange}
                                        name="school_trips"
                                    >
                                        <MenuItem value={false}>
                                            No
                                        </MenuItem>
                                        <MenuItem value={true}>
                                            Yes
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                    <AccordionActions sx={{ display: "flex", justifyContent: "center", gap: "30px", py: 3 }}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handlePreviousPanel(5)}
                        >
                            Previous
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNextPanel(5)}
                        >
                            Next
                        </Button>

                    </AccordionActions>
                </Accordion>


                {/* Section 7 - Policies & Terms*/}
                <Accordion expanded={accordionPanel === 6}>
                    <AccordionSummary
                        expandIcon={
                            accordionPanel < 6 ? (
                                <Lock />
                            ) : (
                                accordionPanel !== 6 && <CheckCircle />
                            )
                        }
                        aria-controls='panel7bh-content'
                        id='panel7bh-header'
                    >
                        <Typography>Policies & Terms</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            {/* What To Know */}
                            <Grid item xs={12} sm={6}>
                                <Editor
                                    name="attending_info"
                                    value={expDetails.attending_info}
                                    placeholder="What to know before attending"
                                    lable="What to know before attending"
                                    onChange={(data) => {
                                        setExpDetails({
                                            ...expDetails,
                                            attending_info: data,
                                        })
                                    }}
                                    error={showErrors && expDetails.attending_info.length > 500}
                                    max={500}
                                    required={false}
                                />
                            </Grid>

                            {/* Cancellation Policy*/}
                            <Grid item xs={12} sm={6}>
                                <Editor
                                    name="cancellation_policy"
                                    value={expDetails.cancellation_policy}
                                    placeholder="Cancellation Policy"
                                    lable="Cancellation Policy"
                                    onChange={(data) => {
                                        setExpDetails({
                                            ...expDetails,
                                            cancellation_policy: data,
                                        })
                                    }}
                                    error={showErrors && (
                                        expDetails.cancellation_policy.length < 10 ||
                                        expDetails.cancellation_policy.length > 500
                                    )}
                                    min={10}
                                    max={500}
                                    required={true}
                                />
                            </Grid>

                            {/* Terms & Conditions*/}
                            <Grid item xs={12} sm={6}>
                                <Editor
                                    name="terms_condition"
                                    value={expDetails.terms_condition}
                                    placeholder="Terms & Conditions"
                                    lable="Terms & Conditions"
                                    onChange={(data) => {
                                        setExpDetails({
                                            ...expDetails,
                                            terms_condition: data,
                                        })
                                    }}
                                    error={showErrors && expDetails.terms_condition.length > 500}
                                    max={500}
                                    required={false}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                    <AccordionActions sx={{ display: "flex", justifyContent: "center", gap: "30px", py: 3 }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handlePreviousPanel(6)}
                        >
                            Previous
                        </Button>
                        {
                            isAdmin && expId && (
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={handleReject}
                                    disabled={loading || expDetails.cancellation_policy.length < 10}
                                >
                                    {
                                        loading ? <CircularProgress size={20} /> : "Reject"
                                    }
                                </Button>
                            )
                        }
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setDialogBox(true)}
                            disabled={loading || expDetails.cancellation_policy.length < 10}
                        >
                            {
                                loading ? <CircularProgress size={20} /> : isAdmin ? "Approve" : "Submit"
                            }
                        </Button>

                    </AccordionActions>
                </Accordion>

            </Box>

            {/* Confirm Dialog Boxes */}
            <DialogBox
                open={dialogBox}
                title="Confirmation"
                description="Are you sure you want to submit this experience ?"
                handleClose={() => setDialogBox(false)}
                onProceedClick={() => {
                    handleSubmit()
                }}
            />
            <ConfirmBox
                {...confirmBox}
                handleClose={() => {
                    setConfirmBox({
                        open: false,
                        type: "",
                        message: "",
                    })
                }}
                onOkClick={() => {
                    setConfirmBox({
                        open: false,
                        type: "",
                        message: "",
                    })
                    navigate("/dashboard/experiences")
                }}
            />
        </Box>
    );
};

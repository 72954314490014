import { Box, Typography, Grid, Button } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCities } from "../../services/actions/cities"
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { AddNewModal, DeleteConfirmBox, BackdropLoading, Table } from "../../components"
import { citiesApi } from "../../services/api"

export const City = () => {
    const dispatch = useDispatch()
    const { cities, loading, error } = useSelector(state => state.cities)

    const [openAddNew, setOpenAddNew] = useState(false)
    const [city, setCity] = useState("")
    const [addNewLoading, setAddNewLoading] = useState(false)
    const [action, setAction] = useState("add")
    const [cityId, setCityId] = useState(null)
    const [openDeleteConf, setOpenDeleteConf] = useState(false)

    const handleOpenModal = () => {
        setOpenAddNew(true)
    }

    const handleCloseModal = () => {
        setOpenAddNew(false)
        setCity("")
        setAction("add")
        setCityId(null)
    }

    const handleOpenDeleteConf = () => {
        setOpenDeleteConf(true)
    }

    const handleCloseDeleteConf = () => {
        setOpenDeleteConf(false)
        setCityId(null)
    }

    const handleSubmit = async () => {
        if (city.trim() === "") return
        setAddNewLoading(true)
        try {
            const response = await citiesApi.createCity({
                name: city,
                state_id: 1
            })
            if (response.data.success) {
                dispatch(getCities())
                handleCloseModal()
            }
            return
        } catch (error) {
            console.error(error)
        } finally {
            setAddNewLoading(false)
        }
    }

    const handleEditModal = (id) => {
        setAction("edit")
        handleOpenModal()
        setCity(cities.filter(city => city.id === id)[0].name)
        setCityId(id)
    }

    const handleEditSubmit = async (cityId) => {
        if (city.trim() === "") return
        if (!cityId) return
        setAddNewLoading(true)
        try {
            const response = await citiesApi.updateCity({
                id: cityId,
                name: city,
                state_id: 1
            })
            if (response.data.success) {
                dispatch(getCities())
                handleCloseModal()
                setAction("add")
                setCityId(null)
            }
            return
        } catch (error) {
            console.error(error)
        } finally {
            setAddNewLoading(false)
        }

    }

    const handleDelete = async (id) => {
        if (!id) return
        try {
            const response = await citiesApi.deleteCity({
                id: id,
            })
            if (response.data.success) {
                dispatch(getCities())
            }
        } finally {
            handleCloseDeleteConf()
        }
    }

    const handleDeleteConfrim = (id) => {
        handleOpenDeleteConf()
        setCityId(id)
    }

    useEffect(() => {
        dispatch(getCities())
    }, [dispatch])

    if (loading) return <BackdropLoading open={loading} />
    if (error) return <div>Error</div>

    return (
        <Box>
            <Grid container sx={{ justifyContent: "space-between", py: 2, px: 5 }}>
                <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <LocationCityIcon sx={{ fontSize: 40 }} />
                        <Typography variant='h5' component='h5' ml={1}>
                            Cities
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <Button
                            variant='contained'
                            onClick={handleOpenModal}
                        >
                            Add New
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ px: 2, py: 3 }}>
                {
                    cities && cities.length > 0 ? (
                        <Table
                            data={cities}
                            id="city"
                            handleEdit={handleEditModal}
                            handleDelete={handleDeleteConfrim}
                        />
                    ) : (
                        <Typography variant="h5" component="h5" align="center">
                            No cities found
                        </Typography>
                    )
                }
            </Box>

            <AddNewModal
                open={openAddNew}
                handleClose={handleCloseModal}
                label="City Name"
                placeholder="Enter city name"
                handleSubmit={
                    action === "add" ? handleSubmit : () => handleEditSubmit(cityId)
                }
                value={city}
                setValue={setCity}
                loading={addNewLoading}
                action={action}
            />

            <DeleteConfirmBox
                open={openDeleteConf}
                handleClose={handleCloseDeleteConf}
                onProceedClick={() => handleDelete(cityId)}
            />
        </Box>
    )
}
import { GET_RUQUESTED_CALLBACKS, GET_RUQUESTED_CALLBACKS_SUCCESS, GET_RUQUESTED_CALLBACKS_FAILURE } from '../actions/callbacks';
import { callbacksApi } from '../api';
import { call, put, takeEvery } from 'redux-saga/effects';

function* getCallbacks() {
    try {
        const response = yield call(callbacksApi.getAllCallbacks);
        yield put({ type: GET_RUQUESTED_CALLBACKS_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_RUQUESTED_CALLBACKS_FAILURE, payload: error });
    }
}

export default function* CallbacksSaga() {
    yield takeEvery(GET_RUQUESTED_CALLBACKS, getCallbacks);
}
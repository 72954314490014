import {
    GET_PARTNERS,
    GET_PARTNERS_SUCCESS,
    GET_PARTNERS_FAILURE,
} from '../actions/partner';

import { partnersApi } from '../api';

import { call, put, takeEvery } from 'redux-saga/effects';

function* getPartners() {
    try {
        const response = yield call(partnersApi.getAllPartners);
        yield put({ type: GET_PARTNERS_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_PARTNERS_FAILURE, payload: error });
    }
}

export default function* partnersSaga() {
    yield takeEvery(GET_PARTNERS, getPartners);
}

import {
    GET_ROLES,
    GET_ROLES_SUCCESS,
    GET_ROLES_FAILURE,
} from '../actions/roles';

const initialState = {
    roles: [],
    loading: false,
    error: null
}

export const roles = (data = initialState, action) => {
    switch (action.type) {
        case GET_ROLES:
            return {
                ...data,
                loading: true,
                error: null
            }

        case GET_ROLES_SUCCESS:
            return {
                ...data,
                loading: false,
                roles: action.payload
            }

        case GET_ROLES_FAILURE:
            return {
                ...data,
                loading: false,
                error: action.payload,
            }

        default:
            return data
    }
}
import { BackdropLoading, Table } from "../../components"
import { Box, Typography, Grid, Button } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDestinationTypes } from "../../services/actions/destinationTypes";
import TypeSpecimenIcon from '@mui/icons-material/TypeSpecimen';
import { AddNewModal, DeleteConfirmBox } from "../../components"
import { destinationTypesApi } from "../../services/api"

export const DestinationTypes = () => {
    const dispatch = useDispatch()
    const { destinationTypes, loading, error } = useSelector(state => state.destinationTypes)

    const [openAddNew, setOpenAddNew] = useState(false)
    const [destinationType, setDestinationType] = useState("")
    const [addNewLoading, setAddNewLoading] = useState(false)
    const [action, setAction] = useState("add")
    const [destinationTypeId, setDestinationTypeId] = useState(null)
    const [openDeleteConf, setOpenDeleteConf] = useState(false)

    const handleOpenModal = () => {
        setOpenAddNew(true)
    }

    const handleCloseModal = () => {
        setOpenAddNew(false)
        setDestinationType("")
        setAction("add")
        setDestinationTypeId(null)
    }

    const handleOpenDeleteConf = () => {
        setOpenDeleteConf(true)
    }

    const handleCloseDeleteConf = () => {
        setOpenDeleteConf(false)
        setDestinationTypeId(null)
    }

    const handleSubmit = async () => {
        if (!destinationType || destinationType.trim() === "") return
        setAddNewLoading(true)
        try {
            const response = await destinationTypesApi.createDestinationType({
                name: destinationType
            })
            if (response.data.success) {
                dispatch(getDestinationTypes())
                handleCloseModal()
            }
            return
        } catch (error) {
            console.error(error)
        } finally {
            setAddNewLoading(false)
        }
    }

    const handleEditModal = (id) => {
        setAction("edit")
        handleOpenModal()
        setDestinationType(destinationTypes.filter(item => item.id === id)[0].name)
        setDestinationTypeId(id)
    }

    const handleEditSubmit = async (destinationTypeId) => {
        if (!destinationType || destinationType.trim() === "") return
        setAddNewLoading(true)
        try {
            const response = await destinationTypesApi.updateDestinationType({
                id: destinationTypeId,
                name: destinationType
            })
            if (response.data.success) {
                dispatch(getDestinationTypes())
                handleCloseModal()
                setAction("add")
                setDestinationTypeId(null)
            }
            return
        } catch (error) {
            console.error(error)
        } finally {
            setAddNewLoading(false)
        }

    }

    const handleDelete = async (id) => {
        if (!id) return
        try {
            const response = await destinationTypesApi.deleteDestinationType({
                id: id,
            })
            if (response.data.success) {
                dispatch(getDestinationTypes())
            }
        } finally {
            handleCloseDeleteConf()
        }
    }

    const handleDeleteConfrim = (id) => {
        handleOpenDeleteConf()
        setDestinationTypeId(id)
    }

    useEffect(() => {
        dispatch(getDestinationTypes())
    }, [dispatch])

    if (loading) return <BackdropLoading open={true} />
    if (error) return <div>Error</div>

    return (
        <Box>
            <Grid container sx={{ justifyContent: "space-between", py: 2, px: 5 }}>
                <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <TypeSpecimenIcon sx={{ fontSize: 40 }} />
                        <Typography variant='h5' component='h5' ml={1}>
                            Destination Types
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <Button
                            variant='contained'
                            onClick={handleOpenModal}
                        >
                            Add New
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ px: 2, py: 3 }}>
                {
                    destinationTypes && destinationTypes.length > 0 ? (
                        <Table
                            data={destinationTypes}
                            id="destinationType"
                            handleEdit={handleEditModal}
                            handleDelete={handleDeleteConfrim}
                        />
                    ) : (
                        <Typography variant="h5" component="h5" align="center">
                            No Destination Types found
                        </Typography>
                    )
                }
            </Box>

            <AddNewModal
                open={openAddNew}
                handleClose={handleCloseModal}
                label="Destination Type"
                placeholder="Enter Destination Type"
                handleSubmit={
                    action === "add" ? handleSubmit : () => handleEditSubmit(destinationTypeId)
                }
                value={destinationType}
                setValue={setDestinationType}
                loading={addNewLoading}
                action={action}
                id="destinationType"
            />

            <DeleteConfirmBox
                open={openDeleteConf}
                handleClose={handleCloseDeleteConf}
                onProceedClick={() => handleDelete(destinationTypeId)}
            />
        </Box>
    )
}
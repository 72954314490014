import {
    GET_GRADES,
    GET_GRADES_SUCCESS,
    GET_GRADES_FAILURE,
} from '../actions/grades';

import { gradesApi } from '../api';

import { call, put, takeEvery } from 'redux-saga/effects';

function* getGrades() {
    try {
        const response = yield call(gradesApi.getAllGrades);
        yield put({ type: GET_GRADES_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_GRADES_FAILURE, payload: error });
    }
}

export default function* gadesSaga() {
    yield takeEvery(GET_GRADES, getGrades);
}

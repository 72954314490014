import { BackdropLoading, Table } from "../../components"
import { Box, Typography, Grid, Button } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getGrades } from "../../services/actions/grades"
import GradeIcon from '@mui/icons-material/Grade';
import { AddNewModal, DeleteConfirmBox } from "../../components"
import { gradesApi } from "../../services/api"

export const Grades = () => {
    const dispatch = useDispatch()
    const { grades, loading, error } = useSelector(state => state.grades)

    const [openAddNew, setOpenAddNew] = useState(false)
    const [grade, setGrade] = useState("")
    const [addNewLoading, setAddNewLoading] = useState(false)
    const [action, setAction] = useState("add")
    const [gradeId, setGradeId] = useState(null)
    const [openDeleteConf, setOpenDeleteConf] = useState(false)

    const handleOpenModal = () => {
        setOpenAddNew(true)
    }

    const handleCloseModal = () => {
        setOpenAddNew(false)
        setGrade("")
        setAction("add")
        setGradeId(null)
    }

    const handleOpenDeleteConf = () => {
        setOpenDeleteConf(true)
    }

    const handleCloseDeleteConf = () => {
        setOpenDeleteConf(false)
        setGradeId(null)
    }

    const handleSubmit = async () => {
        if (!grade || grade === 0) return
        setAddNewLoading(true)
        try {
            const response = await gradesApi.createGrade({
                grade: parseInt(grade)
            })
            if (response.data.success) {
                dispatch(getGrades())
                handleCloseModal()
            }
            return
        } catch (error) {
            console.error(error)
        } finally {
            setAddNewLoading(false)
        }
    }

    const handleEditModal = (id) => {
        setAction("edit")
        handleOpenModal()
        setGrade(grades.filter(grade => grade.id === id)[0].grade)
        setGradeId(id)
    }

    const handleEditSubmit = async (gradeId) => {
        if (!grade || grade === 0) return
        setAddNewLoading(true)
        try {
            const response = await gradesApi.updateGrade({
                id: gradeId,
                grade: parseInt(grade)
            })
            if (response.data.success) {
                dispatch(getGrades())
                handleCloseModal()
                setAction("add")
                setGradeId(null)
            }
            return
        } catch (error) {
            console.error(error)
        } finally {
            setAddNewLoading(false)
        }

    }

    const handleDelete = async (id) => {
        if (!id) return
        try {
            const response = await gradesApi.deleteGrade({
                id: id,
            })
            if (response.data.success) {
                dispatch(getGrades())
            }
        } catch (error) {
            console.error(error)
        } finally {
            handleCloseDeleteConf()
        }
    }

    const handleDeleteConfrim = (id) => {
        handleOpenDeleteConf()
        setGradeId(id)
    }

    useEffect(() => {
        dispatch(getGrades())
    }, [dispatch])

    if (loading) return <BackdropLoading open={true} />
    if (error) return <div>Error</div>

    return (
        <Box>
            <Grid container sx={{ justifyContent: "space-between", py: 2, px: 5 }}>
                <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <GradeIcon sx={{ fontSize: 40 }} />
                        <Typography variant='h5' component='h5' ml={1}>
                            Grades
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <Button
                            variant='contained'
                            onClick={handleOpenModal}
                        >
                            Add New
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ px: 2, py: 3 }}>
                {
                    grades && grades.length > 0 ? (
                        <Table
                            data={grades}
                            id="grade"
                            handleEdit={handleEditModal}
                            handleDelete={handleDeleteConfrim}
                        />
                    ) : (
                        <Typography variant="h5" component="h5" align="center">
                            No grades found
                        </Typography>
                    )
                }
            </Box>

            <AddNewModal
                open={openAddNew}
                handleClose={handleCloseModal}
                label="Grade"
                placeholder="Enter garde"
                handleSubmit={
                    action === "add" ? handleSubmit : () => handleEditSubmit(gradeId)
                }
                value={grade}
                setValue={setGrade}
                loading={addNewLoading}
                action={action}
                id="grade"
            />

            <DeleteConfirmBox
                open={openDeleteConf}
                handleClose={handleCloseDeleteConf}
                onProceedClick={() => handleDelete(gradeId)}
            />
        </Box>
    )
}
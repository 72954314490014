import { call, put, takeEvery } from 'redux-saga/effects';
import { authApi, partnersApi } from '../api';
import {
    SEND_OTP,
    SEND_OTP_SUCCESS,
    SEND_OTP_FAILURE,
    VERIFY_OTP,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAILURE,
    RESEND_OTP,
    RESEND_OTP_SUCCESS,
    RESEND_OTP_FAILURE,
    GET_USER,
    GET_USER_SUCCESS,
    GET_USER_FAILURE,
    UPDATE_PARTNER,
    UPDATE_PARTNER_SUCCESS,
} from "../actions/auth";

function* sendOtp(payload) {
    try {
        const response = yield call(authApi.sendOtp, payload.mobile);
        if (!response.data.success) {
            throw new Error(response.data.message);
        }
        yield put({ type: SEND_OTP_SUCCESS, payload: response.data });
    } catch (error) {
        yield put({ type: SEND_OTP_FAILURE, payload: error });
    }
}

function* verifyOtp(payload) {
    try {
        const response = yield call(authApi.verifyOtp, payload)
        if (response.data.token) {
            yield put({ type: VERIFY_OTP_SUCCESS, payload: response.data.token });
        } else {
            throw new Error(response.data.message);
        }
    } catch (error) {
        yield put({ type: VERIFY_OTP_FAILURE, payload: error });
    }
}

function* resendOtp(payload) {
    try {
        const response = yield call(authApi.resendOtp, payload.mobile);
        if (!response.data.success) {
            throw new Error(response.data.message);
        }
        yield put({ type: RESEND_OTP_SUCCESS, payload: response });
    } catch (error) {
        yield put({ type: RESEND_OTP_FAILURE, payload: error });
    }
}

function* getUser() {
    try {
        const response = yield call(authApi.getUser);
        if (!response.data.success) {
            throw new Error(response.data.message);
        }
        if (response.data.data.user.role.name === "student") {
            throw new Error("You are not authorized to access this page.");
        }
        yield put({ type: GET_USER_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_USER_FAILURE, payload: error });
        localStorage.removeItem("token");
    }
}

function* updatePartner(payload) {
    try {
        const response = yield call(partnersApi.getPartner, payload.id);
        if (!response.data.success) {
            throw new Error(response.data.message);
        }
        yield put({ type: UPDATE_PARTNER_SUCCESS, payload: response.data.data });
    } catch (error) {
        console.error(error)
    }
}

export default function* authSaga() {
    yield takeEvery(SEND_OTP, sendOtp);
    yield takeEvery(VERIFY_OTP, verifyOtp);
    yield takeEvery(RESEND_OTP, resendOtp);
    yield takeEvery(GET_USER, getUser);
    yield takeEvery(UPDATE_PARTNER, updatePartner);
}
import {
    GET_STATES,
    GET_STATES_SUCCESS,
    GET_STATES_FAILURE
} from '../actions/states';

import { statesApi } from '../api';

import { call, put, takeEvery } from 'redux-saga/effects';

function* getStates() {
    try {
        const response = yield call(statesApi.getAllStates);
        yield put({ type: GET_STATES_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_STATES_FAILURE, payload: error });
    }
}

export default function* statesSaga() {
    yield takeEvery(GET_STATES, getStates);
}

import { BackdropLoading, Table } from "../../components"
import { Box, Typography, Grid, Button } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getStates } from "../../services/actions/states"
import VillaIcon from '@mui/icons-material/Villa';
import { statesApi } from "../../services/api"
import { AddNewModal, DeleteConfirmBox } from "../../components"

export const State = () => {
    const dispatch = useDispatch()
    const { states, loading, error } = useSelector(state => state.states)

    const [openAddNew, setOpenAddNew] = useState(false)
    const [state, setState] = useState("")
    const [addNewLoading, setAddNewLoading] = useState(false)
    const [action, setAction] = useState("add")
    const [stateId, setStateId] = useState(null)
    const [openDeleteConf, setOpenDeleteConf] = useState(false)

    const handleOpenModal = () => {
        setOpenAddNew(true)
    }

    const handleCloseModal = () => {
        setOpenAddNew(false)
        setState("")
        setAction("add")
        setStateId(null)
    }

    const handleOpenDeleteConf = () => {
        setOpenDeleteConf(true)
    }

    const handleCloseDeleteConf = () => {
        setOpenDeleteConf(false)
        setStateId(null)
    }

    const handleSubmit = async () => {
        if (state.trim() === "") return
        setAddNewLoading(true)
        try {
            const response = await statesApi.createState({
                name: state,
                country_id: 1
            })
            if (response.data.success) {
                dispatch(getStates())
                handleCloseModal()
            }
            return
        } catch (error) {
            console.error(error)
        } finally {
            setAddNewLoading(false)
        }
    }

    const handleEditModal = (id) => {
        setAction("edit")
        handleOpenModal()
        setState(states.filter(state => state.id === id)[0].name)
        setStateId(id)
    }

    const handleEditSubmit = async (setStateId) => {
        if (state.trim() === "") return
        if (!state) return
        setAddNewLoading(true)
        try {
            const response = await statesApi.updateState({
                id: setStateId,
                name: state,
                country_id: 1
            })
            if (response.data.success) {
                dispatch(getStates())
                handleCloseModal()
                setAction("add")
                setStateId(null)
            }
            return
        } catch (error) {
            console.error(error)
        } finally {
            setAddNewLoading(false)
        }

    }

    const handleDelete = async (id) => {
        if (!id) return
        try {
            const response = await statesApi.deleteState({
                id
            })
            if (response.data.success) {
                dispatch(getStates())
            }
        } catch (error) {
            console.error(error)
        } finally {
            handleCloseDeleteConf()
        }
    }

    const handleDeleteConfrim = (id) => {
        handleOpenDeleteConf()
        setStateId(id)
    }

    useEffect(() => {
        dispatch(getStates())
    }, [dispatch])

    if (loading) return <BackdropLoading open={true} />
    if (error) return <div>Error</div>

    return (
        <Box>
            <Grid container sx={{ justifyContent: "space-between", py: 2, px: 5 }}>
                <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <VillaIcon sx={{ fontSize: 40 }} />
                        <Typography variant='h5' component='h5' ml={1}>
                            States
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <Button
                            variant='contained'
                            onClick={handleOpenModal}
                        >
                            Add New
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ px: 2, py: 3 }}>
                {
                    states && states.length > 0 ? (
                        <Table
                            data={states}
                            id="state"
                            handleEdit={handleEditModal}
                            handleDelete={handleDeleteConfrim}
                        />
                    ) : (
                        <Typography variant="h5" component="h5" align="center">
                            No states found
                        </Typography>
                    )
                }
            </Box>
            <AddNewModal
                open={openAddNew}
                handleClose={handleCloseModal}
                label="State Name"
                placeholder="Enter state name"
                handleSubmit={
                    action === "add" ? handleSubmit : () => handleEditSubmit(stateId)
                }
                value={state}
                setValue={setState}
                loading={addNewLoading}
                action={action}
            />

            <DeleteConfirmBox
                open={openDeleteConf}
                handleClose={handleCloseDeleteConf}
                onProceedClick={() => handleDelete(stateId)}
            />
        </Box>
    )
}
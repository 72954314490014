import {
    GET_SCHOOLS,
    GET_SCHOOLS_SUCCESS,
    GET_SCHOOLS_FAILURE
} from '../actions/schools';

import { schoolsApi } from '../api';

import { call, put, takeEvery } from 'redux-saga/effects';

function* getSchools() {
    try {
        const response = yield call(schoolsApi.getAllSchools);
        yield put({ type: GET_SCHOOLS_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_SCHOOLS_FAILURE, payload: error });
    }
}

export default function* schoolsSaga() {
    yield takeEvery(GET_SCHOOLS, getSchools);
}

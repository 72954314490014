import {
    GET_ROLES,
    GET_ROLES_SUCCESS,
    GET_ROLES_FAILURE,
} from '../actions/roles';

import { rolesApi } from '../api';

import { call, put, takeEvery } from 'redux-saga/effects';

function* getRoles() {
    try {
        const response = yield call(rolesApi.getAllRoles);
        yield put({ type: GET_ROLES_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_ROLES_FAILURE, payload: error });
    }
}

export default function* rolesSaga() {
    yield takeEvery(GET_ROLES, getRoles);
}

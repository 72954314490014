
import {
    GET_COUNTRIES,
    GET_COUNTRIES_SUCCESS,
    GET_COUNTRIES_FAILURE,
    GET_COUNTRY,
    GET_COUNTRY_SUCCESS,
    GET_COUNTRY_FAILURE,
} from '../actions/countries';

const initialState = {
    countries: [],
    country: {},
    loading: false,
    error: null
};

export const countries = (data = initialState, action) => {
    switch (action.type) {
        case GET_COUNTRIES:
            return {
                ...data,
                loading: true,
                error: null
            };
        case GET_COUNTRIES_SUCCESS:
            return {
                ...data,
                loading: false,
                countries: action.payload
            };
        case GET_COUNTRIES_FAILURE:
            return {
                ...data,
                loading: false,
                error: action.payload,
                countries: []
            };
        case GET_COUNTRY:
            return {
                ...data,
                loading: true,
                error: null
            };
        case GET_COUNTRY_SUCCESS:
            return {
                ...data,
                loading: false,
                country: action.payload
            };
        case GET_COUNTRY_FAILURE:
            return {
                ...data,
                loading: false,
                error: action.payload,
                country: {}
            };
        default:
            return data;
    }
}
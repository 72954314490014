import {
    GET_STATES,
    GET_STATES_SUCCESS,
    GET_STATES_FAILURE
} from '../actions/states';

const initialState = {
    states: [],
    loading: false,
    error: null
}

export const states = (data = initialState, action) => {
    switch (action.type) {
        case GET_STATES:
            return {
                ...data,
                loading: true,
                error: null
            }

        case GET_STATES_SUCCESS:
            return {
                ...data,
                loading: false,
                states: action.payload
            }

        case GET_STATES_FAILURE:
            return {
                ...data,
                loading: false,
                error: action.payload,
            }

        default:
            return data
    }
}
import { BackdropLoading, Table } from "../../components"
import { Box, Typography, Grid, Button } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getSubjects } from "../../services/actions/subjects"
import SubjectIcon from "@mui/icons-material/Subject"
import { subjectsApi } from "../../services/api"
import { AddNewModal, DeleteConfirmBox } from "../../components"

export const Subject = () => {
    const dispatch = useDispatch()
    const { subjects, loading, error } = useSelector(state => state.subjects)

    const [openAddNew, setOpenAddNew] = useState(false)
    const [subject, setSubject] = useState("")
    const [addNewLoading, setAddNewLoading] = useState(false)
    const [action, setAction] = useState("add")
    const [subjectId, setSubjectId] = useState(null)
    const [openDeleteConf, setOpenDeleteConf] = useState(false)

    const handleOpenModal = () => {
        setOpenAddNew(true)
    }

    const handleCloseModal = () => {
        setOpenAddNew(false)
        setSubject("")
        setAction("add")
        setSubjectId(null)
    }

    const handleSubmit = async () => {
        if (subject.trim() === "") return
        setAddNewLoading(true)
        try {
            const response = await subjectsApi.createSubject({
                name: subject
            })
            if (response.data.success) {
                dispatch(getSubjects())
                handleCloseModal()
            }
            return
        } catch (error) {
            console.error(error)
        } finally {
            setAddNewLoading(false)
        }
    }

    const handleOpenDeleteConf = () => {
        setOpenDeleteConf(true)
    }

    const handleCloseDeleteConf = () => {
        setOpenDeleteConf(false)
        setSubjectId(null)
    }

    const handleEditModal = (id) => {
        setAction("edit")
        handleOpenModal()
        setSubject(subjects.filter(subject => subject.id === id)[0].name)
        setSubjectId(id)
    }

    const handleEditSubmit = async (subjectId) => {
        if (subject.trim() === "") return
        if (!subject) return
        setAddNewLoading(true)
        try {
            const response = await subjectsApi.updateSubject({
                id: subjectId,
                name: subject
            })
            if (response.data.success) {
                dispatch(getSubjects())
                handleCloseModal()
                setAction("add")
                setSubjectId(null)
            }
            return
        } catch (error) {
            console.error(error)
        } finally {
            setAddNewLoading(false)
        }

    }

    const handleDelete = async (id) => {
        if (!id) return
        try {
            const response = await subjectsApi.deleteSubject({
                id
            })
            if (response.data.success) {
                dispatch(getSubjects())
            }
        } catch (error) {
            console.error(error)
        } finally {
            handleCloseDeleteConf()
        }
    }

    const handleDeleteConfrim = (id) => {
        handleOpenDeleteConf()
        setSubjectId(id)
    }


    useEffect(() => {
        dispatch(getSubjects())
    }, [dispatch])

    if (loading) return <BackdropLoading open={true} />
    if (error) return <div>Error</div>

    return (
        <Box>
            <Grid container sx={{ justifyContent: "space-between", py: 2, px: 5 }}>
                <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <SubjectIcon sx={{ fontSize: 40 }} />
                        <Typography variant='h5' component='h5' ml={1}>
                            Subjects
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <Button
                            variant='contained'
                            onClick={handleOpenModal}
                        >
                            Add New
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ px: 2, py: 3 }}>
                {
                    subjects && subjects.length > 0 ? (
                        <Table
                            data={subjects}
                            id="subject"
                            handleEdit={handleEditModal}
                            handleDelete={handleDeleteConfrim}
                        />
                    ) : (
                        <Typography variant="h5" component="h5" align="center">
                            No subjects found
                        </Typography>
                    )
                }
            </Box>

            <AddNewModal
                open={openAddNew}
                handleClose={handleCloseModal}
                label="Subject Name"
                placeholder="Enter subject name"
                handleSubmit={
                    action === "add" ? handleSubmit : () => handleEditSubmit(subjectId)
                }
                value={subject}
                setValue={setSubject}
                loading={addNewLoading}
                action={action}
            />

            <DeleteConfirmBox
                open={openDeleteConf}
                handleClose={handleCloseDeleteConf}
                onProceedClick={() => handleDelete(subjectId)}
            />
        </Box>
    )
}
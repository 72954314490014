import {
    GET_EXPERIENCE,
    GET_EXPERIENCE_SUCCESS,
    GET_EXPERIENCE_FAILURE,
    GET_EXPERIENCES,
    GET_EXPERIENCES_SUCCESS,
    GET_EXPERIENCES_FAILURE,
    CREATE_EXPERIENCE,
    CREATE_EXPERIENCE_SUCCESS,
    CREATE_EXPERIENCE_FAILURE,
    EDIT_EXPERIENCE,
    EDIT_EXPERIENCE_SUCCESS,
    EDIT_EXPERIENCE_FAILURE,
    DELETE_EXPERIENCE,
    DELETE_EXPERIENCE_SUCCESS,
    DELETE_EXPERIENCE_FAILURE
} from '../actions/experiences';

const initialState = {
    experiences: [],
    experience: {},
    loading: false,
    error: null,
    isCreated: false,
};

const experiences = (data = initialState, action) => {
    switch (action.type) {
        case GET_EXPERIENCE:
        case GET_EXPERIENCES:
        case CREATE_EXPERIENCE:
        case EDIT_EXPERIENCE:
        case DELETE_EXPERIENCE:
            return {
                ...data,
                loading: true,
                error: null,
            };

        case GET_EXPERIENCE_SUCCESS:
            return {
                ...data,
                loading: false,
                experience: action.payload,
            };

        case GET_EXPERIENCES_SUCCESS:
            return {
                ...data,
                loading: false,
                experiences: action.payload,
            };

        case CREATE_EXPERIENCE_SUCCESS:
        case EDIT_EXPERIENCE_SUCCESS:
        case DELETE_EXPERIENCE_SUCCESS:
            return {
                ...data,
                loading: false,
                isCreated: true,
            };

        case GET_EXPERIENCE_FAILURE:
        case GET_EXPERIENCES_FAILURE:
        case CREATE_EXPERIENCE_FAILURE:
        case EDIT_EXPERIENCE_FAILURE:
        case DELETE_EXPERIENCE_FAILURE:
            return {
                ...data,
                loading: false,
                error: action.payload,
                isCreated: false,
            };

        default:
            return data;
    }
};

export default experiences;


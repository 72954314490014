import { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  Button,
  AppBar,
  Tabs,
  Tab,
} from "@mui/material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { BackdropLoading, TabPanel } from "../../components";
import AccountDetails from "./AccountDetails";
import ProfileDetails from "./ProfileDetails";
import { getCities } from "../../services/actions/cities";
import { useDispatch, useSelector } from "react-redux";
import Media from "./Media";

export const ProfilePage = () => {
  const dispatch = useDispatch();
  const { user, partner, loading, error } = useSelector((state) => state.auth);
  const { cities, loading: cLoading } = useSelector((state) => state.cities);
  const [activeTab, setActiveTab] = useState(0);
  const [profileDetails, setProfileDetails] = useState({
    id: partner?.id,
    name: partner?.name,
    mobile_number: partner?.user?.mobile_number,
    email: partner?.email,
    highlight: partner?.highlight,
    city_id: partner?.city?.id,
    description: partner?.description,
    cover_photo: partner?.cover_photo,
    profile_photo: partner?.profile_photo,
    role_id: user?.role?.id,
    account_holder_name: partner?.account_holder_name,
    account_number: partner?.account_number,
    ifsc_code: partner?.ifsc_code,
    facebook_link: partner?.facebook_link,
    instagram_link: partner?.instagram_link,
    twitter_link: partner?.twitter_link,
  });

  useEffect(() => {
    dispatch(getCities());
  }, [dispatch]);

  useEffect(() => {
    if (partner) {
      setProfileDetails({
        id: partner?.id,
        name: partner?.name,
        mobile_number: partner?.user?.mobile_number,
        email: partner?.email,
        highlight: partner?.highlight,
        city_id: partner?.city?.id,
        description: partner?.description,
        cover_photo: partner?.cover_photo,
        profile_photo: partner?.profile_photo,
        role_id: user?.role?.id,
        account_holder_name: partner?.account_holder_name,
        account_number: partner?.account_number,
        ifsc_code: partner?.ifsc_code,
        facebook_link: partner?.facebook_link,
        instagram_link: partner?.instagram_link,
        twitter_link: partner?.twitter_link,
      })
    }
  }, [partner, user])


  if (loading || cLoading) return <BackdropLoading open={true} />;
  if (error) return <h1>error</h1>;

  return (
    <Box>
      <Grid container sx={{ justifyContent: "space-between", py: 2, px: { xs: 1, sm: 5 } }}>
        <Grid item>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <AccountBoxIcon sx={{ fontSize: 40 }} />
            <Typography variant="h5" component="h5" ml={1}>
              Profile
            </Typography>
          </Box>
        </Grid>

        <Grid item>
          <Box>
            <Button
              variant="contained"
              onClick={() => {
                window.open(`${process.env.REACT_APP_STUDENT_PORTAL_URL}/partner/${partner?.id}`, "_blank")
              }}
            >
              Preview
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Box>
        <AppBar position="static">
          <Tabs
            value={activeTab}
            onChange={(e, value) => setActiveTab(value)}
            sx={{ px: 3 }}
            textColor="inherit"
            indicatorColor="secondary"
          >
            <Tab label={window.innerWidth > 600 ? "Profile Details" : " Profile"} />
            <Tab label={window.innerWidth > 600 ? "Account Details" : "Account"} />
            <Tab label="Media" />
          </Tabs>
        </AppBar>

        {/* Profile Details  Tab*/}
        <TabPanel value={activeTab} index={0}>
          <Box className="profileDetails" sx={{ px: 4, py: 3 }}>
            <ProfileDetails
              cities={cities}
              profileDetails={profileDetails}
              setProfileDetails={setProfileDetails}
            />
          </Box>
        </TabPanel>

        {/* Account Details  Tab*/}
        <TabPanel value={activeTab} index={1}>
          <Box className="accountDetails" sx={{ px: 4, py: 3 }}>
            <AccountDetails
              profileDetails={profileDetails}
              setProfileDetails={setProfileDetails}
            />
          </Box>
        </TabPanel>

        {/* Media  Tab*/}
        <TabPanel value={activeTab} index={2}>
          <Box className="media" sx={{ px: 4, py: 3 }}>
            <Media partner={partner} />
          </Box>
        </TabPanel>

      </Box>
    </Box>
  );
};

import {
    GET_EXPERIENCE_TYPES,
    GET_EXPERIENCE_TYPES_SUCCESS,
    GET_EXPERIENCE_TYPES_FAILURE,
} from '../actions/experienceTypes';

import { experienceTypesApi } from '../api';
import { call, put, takeEvery } from 'redux-saga/effects';

function* getExperienceTypes() {
    try {
        const response = yield call(experienceTypesApi.getAllExperienceTypes);
        yield put({ type: GET_EXPERIENCE_TYPES_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_EXPERIENCE_TYPES_FAILURE, payload: error });
    }
}

export default function* getExperienceTypesSaga() {
    yield takeEvery(GET_EXPERIENCE_TYPES, getExperienceTypes);
}

import { BackdropLoading, Table } from "../../components"
import { Box, Typography, Grid, Button } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getSchools } from "../../services/actions/schools"
import SchoolIcon from '@mui/icons-material/School';
import { DeleteConfirmBox } from "../../components"
import { schoolsApi } from "../../services/api"
import { getCities } from "../../services/actions/cities"
import AddNewSchool from "./AddNewSchool"

export const Schools = () => {
    const dispatch = useDispatch()
    const { schools, loading, error } = useSelector(state => state.schools)
    const { cities, loading : cityLoading, error:cityErr } = useSelector(state => state.cities)

    const [openAddNew, setOpenAddNew] = useState(false)
    const [school, setSchool] = useState("")
    const [addNewLoading, setAddNewLoading] = useState(false)
    const [action, setAction] = useState("add")
    const [schoolId, setSchoolId] = useState(null)
    const [openDeleteConf, setOpenDeleteConf] = useState(false)
    const [cityId, setCityId] = useState(null)

    const handleOpenModal = () => {
        setOpenAddNew(true)
    }

    const handleCloseModal = () => {
        setOpenAddNew(false)
        setSchool("")
        setAction("add")
        setSchoolId(null)
        setCityId(null)
    }

    const handleOpenDeleteConf = () => {
        setOpenDeleteConf(true)
    }

    const handleCloseDeleteConf = () => {
        setOpenDeleteConf(false)
        setSchoolId(null)
    }

    const handleSubmit = async () => {
        if (!school || school.trim() === "") return
        setAddNewLoading(true)
        try {
            const response = await schoolsApi.createSchool({
                name: school,
                city_id: cityId
            })
            if (response.data.success) {
                dispatch(getSchools())
                handleCloseModal()
            }
            return
        } catch (error) {
            console.error(error)
        } finally {
            setAddNewLoading(false)
        }
    }

    const handleEditModal = (id) => {
        setAction("edit")
        handleOpenModal()
        setSchool(schools.filter(school => school.id === id)[0].name)
        setSchoolId(id)
        setCityId(schools.filter(school => school.id === id)[0]?.city?.id || null)
    }

    const handleEditSubmit = async (schoolId) => {
        if (!school || school.trim() === "") return
        setAddNewLoading(true)
        try {
            const response = await schoolsApi.updateSchool({
                id: schoolId,
                name: school,
                city_id: cityId
            })
            if (response.data.success) {
                dispatch(getSchools())
                handleCloseModal()
                setAction("add")
                setSchoolId(null)
                setCityId(null)
            }
            return
        } catch (error) {
            console.error(error)
        } finally {
            setAddNewLoading(false)
        }

    }

    const handleDelete = async (id) => {
        if (!id) return
        try {
            const response = await schoolsApi.deleteSchool({
                id: id,
            })
            if (response.data.success) {
                dispatch(getSchools())
            }
        } finally {
            handleCloseDeleteConf()
        }
    }

    const handleDeleteConfrim = (id) => {
        handleOpenDeleteConf()
        setSchoolId(id)
    }

    useEffect(() => {
        dispatch(getSchools())
        dispatch(getCities())
    }, [dispatch])

    if (loading || cityLoading) return <BackdropLoading open={true} />
    if (error || cityErr) return <div>Error</div>

    return (
        <Box>
            <Grid container sx={{ justifyContent: "space-between", py: 2, px: 5 }}>
                <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <SchoolIcon sx={{ fontSize: 40 }} />
                        <Typography variant='h5' component='h5' ml={1}>
                            Schools
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <Button
                            variant='contained'
                            onClick={handleOpenModal}
                        >
                            Add New
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ px: 2, py: 3 }}>
                {
                    schools && schools.length > 0 ? (
                        <Table
                            data={schools}
                            id="school"
                            handleEdit={handleEditModal}
                            handleDelete={handleDeleteConfrim}
                        />
                    ) : (
                        <Typography variant="h5" component="h5" align="center">
                            No schools found
                        </Typography>
                    )
                }
            </Box>
            <AddNewSchool
                open={openAddNew}
                handleClose={handleCloseModal}
                label="School Name"
                placeholder="Enter school name"
                handleSubmit={
                    action === "add" ? handleSubmit : () => handleEditSubmit(schoolId)
                }
                value={school}
                setValue={setSchool}
                loading={addNewLoading}
                action={action}
                cities={cities}
                cityId={cityId}
                setCityId={setCityId}
            />

            <DeleteConfirmBox
                open={openDeleteConf}
                handleClose={handleCloseDeleteConf}
                onProceedClick={() => handleDelete(schoolId)}
            />
        </Box>
    )
}
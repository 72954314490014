import {
    GET_REQUESTED_SCHOOL_TRIPS,
    GET_REQUESTED_SCHOOL_TRIPS_SUCCESS,
    GET_REQUESTED_SCHOOL_TRIPS_FAILURE,
} from '../actions/schoolTrip';

import { schoolTripsApi } from '../api';

import { call, put, takeEvery } from 'redux-saga/effects';

function* getSchoolTrips() {
    try {
        const response = yield call(schoolTripsApi.getAllSchoolTrips);
        yield put({ type: GET_REQUESTED_SCHOOL_TRIPS_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_REQUESTED_SCHOOL_TRIPS_FAILURE, payload: error });
    }
}

export default function* schoolTripsSaga() {
    yield takeEvery(GET_REQUESTED_SCHOOL_TRIPS, getSchoolTrips);
}

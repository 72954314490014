import { BackdropLoading, Table } from "../../components"
import { Box, Typography, Grid, Button } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getRoles } from "../../services/actions/roles"
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { AddNewModal, DeleteConfirmBox } from "../../components"
import { rolesApi } from "../../services/api"

export const Roles = () => {
    const dispatch = useDispatch()
    const { roles, loading, error } = useSelector(state => state.roles)

    const [openAddNew, setOpenAddNew] = useState(false)
    const [role, setRole] = useState("")
    const [addNewLoading, setAddNewLoading] = useState(false)
    const [action, setAction] = useState("add")
    const [roleId, setRoleId] = useState(null)
    const [openDeleteConf, setOpenDeleteConf] = useState(false)

    const handleOpenModal = () => {
        setOpenAddNew(true)
    }

    const handleCloseModal = () => {
        setOpenAddNew(false)
        setRole("")
        setAction("add")
        setRoleId(null)
    }

    const handleOpenDeleteConf = () => {
        setOpenDeleteConf(true)
    }

    const handleCloseDeleteConf = () => {
        setOpenDeleteConf(false)
        setRoleId(null)
    }

    const handleSubmit = async () => {
        if (role.trim() === "") return
        setAddNewLoading(true)
        try {
            const response = await rolesApi.createRole({
                name: role,
            })
            if (response.data.success) {
                dispatch(getRoles())
                handleCloseModal()
            }
            return
        } catch (error) {
            console.error(error)
        } finally {
            setAddNewLoading(false)
        }
    }

    const handleEditModal = (id) => {
        setAction("edit")
        handleOpenModal()
        setRole(roles.filter(role => role.id === id)[0].name)
        setRoleId(id)
    }

    const handleEditSubmit = async (roleId) => {
        if (role.trim() === "") return
        if (!roleId) return
        setAddNewLoading(true)
        try {
            const response = await rolesApi.updateRole({
                id: roleId,
                name: role,
            })
            if (response.data.success) {
                dispatch(getRoles())
                handleCloseModal()
                setAction("add")
                setRoleId(null)
            }
            return
        } catch (error) {
            console.error(error)
        } finally {
            setAddNewLoading(false)
        }

    }

    const handleDelete = async (id) => {
        if (!id) return
        try {
            const response = await rolesApi.deleteRole({
                id: id,
            })
            if (response.data.success) {
                dispatch(getRoles())
            }
        } finally {
            handleCloseDeleteConf()
        }
    }

    const handleDeleteConfrim = (id) => {
        handleOpenDeleteConf()
        setRoleId(id)
    }

    useEffect(() => {
        dispatch(getRoles())
    }, [dispatch])

    if (loading) return <BackdropLoading open={true} />
    if (error) return <div>Error</div>

    return (
        <Box>
            <Grid container sx={{ justifyContent: "space-between", py: 2, px: 5 }}>
                <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <LockOpenIcon sx={{ fontSize: 40 }} />
                        <Typography variant='h5' component='h5' ml={1}>
                            Roles
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <Button
                            variant='contained'
                            onClick={handleOpenModal}
                        >
                            Add New
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ px: 2, py: 3 }}>
                {
                    roles && roles.length > 0 ? (
                        <Table
                            data={roles}
                            id="role"
                            handleEdit={handleEditModal}
                            handleDelete={handleDeleteConfrim}
                        />
                    ) : (
                        <Typography variant="h5" component="h5" align="center">
                            No roles found
                        </Typography>
                    )
                }
            </Box>

            {
                openAddNew && (
                    <AddNewModal
                        open={openAddNew}
                        handleClose={handleCloseModal}
                        label="Role Name"
                        placeholder="Enter role name"
                        handleSubmit={
                            action === "add" ? handleSubmit : () => handleEditSubmit(roleId)
                        }
                        value={role}
                        setValue={setRole}
                        loading={addNewLoading}
                        action={action}
                    />
                )
            }

            {
                openDeleteConf && (
                    <DeleteConfirmBox
                        open={openDeleteConf}
                        handleClose={handleCloseDeleteConf}
                        onProceedClick={() => handleDelete(roleId)}
                    />
                )
            }
        </Box>
    )
}
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAILURE = 'GET_COUNTRIES_FAILURE';

export const getCountries = () => ({
    type: GET_COUNTRIES,
});

export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_FAILURE = 'GET_COUNTRY_FAILURE';

export const getCountry = (payload) => ({
    type: GET_COUNTRY,
    payload,
});

export const EDIT_COUNTRY = 'EDIT_COUNTRY';
export const EDIT_COUNTRY_SUCCESS = 'EDIT_COUNTRY_SUCCESS';
export const EDIT_COUNTRY_FAILURE = 'EDIT_COUNTRY_FAILURE';

export const editCountry = (payload) => ({
    type: EDIT_COUNTRY,
    payload,
});

export const CREATE_COUNTRY = 'CREATE_COUNTRY';
export const CREATE_COUNTRY_SUCCESS = 'CREATE_COUNTRY_SUCCESS';
export const CREATE_COUNTRY_FAILURE = 'CREATE_COUNTRY_FAILURE';

export const createCountry = (payload) => ({
    type: CREATE_COUNTRY,
    payload,
});

export const DELETE_COUNTRY = 'DELETE_COUNTRY';
export const DELETE_COUNTRY_SUCCESS = 'DELETE_COUNTRY_SUCCESS';
export const DELETE_COUNTRY_FAILURE = 'DELETE_COUNTRY_FAILURE';

export const deleteCountry = (payload) => ({
    type: DELETE_COUNTRY,
    payload,
});
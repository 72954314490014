import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { Button, Modal, Typography, CircularProgress, Grid, IconButton, Slider } from '@mui/material';
import PhotoCamera from "@mui/icons-material/PhotoCamera"
import CloseIcon from "@mui/icons-material/Close"


// Generate a random unique string for file name
const generateRandomString = (length) => {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
};

const getCroppedImg = async (imageSrc, croppedAreaPixels) => {
    return new Promise(async (resolve, reject) => {
        const image = new Image();
        image.src = imageSrc;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;

        image.onload = () => {
            ctx.drawImage(
                image,
                croppedAreaPixels.x,
                croppedAreaPixels.y,
                croppedAreaPixels.width,
                croppedAreaPixels.height,
                0,
                0,
                croppedAreaPixels.width,
                croppedAreaPixels.height
            );

            canvas.toBlob((blob) => {
                if (!blob) {
                    reject(new Error('Canvas is empty'));
                    return;
                }
                const fileName = generateRandomString(10) + '.jpeg';
                const file = new File([blob], fileName, { type: 'image/jpeg' });
                resolve(file);
            }, 'image/jpeg');
        };

        image.onerror = () => {
            reject(new Error('Failed to load image'));
        };
    });
};

const styles = {
    container: {
        position: 'relative',
        width: '100%',
        height: '90%',
        background: 'transparent',
    },
    modal: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        background: '#fff',
        padding: 20,
        outline: 0,
        borderRadius: 4,
        width: 600,
        height: 500,
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
};

const ImageCropper = ({ text, loading, handleSubmit, btnWidth, aspect }) => {
    const [open, setOpen] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [error, setError] = useState(null);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setImageSrc(null);
        setCrop({ x: 0, y: 0 });
        setZoom(1);
        setCroppedAreaPixels(null);
        setError(null);
    };

    const handleFileChange = (event) => {
        try {
            const selectedFile = event.target.files[0];
            if (!selectedFile.type.match(/(jpg|jpeg|png)$/)) {
                throw new Error("Invalid image type. Only JPEG, PNG, and JPG images are allowed.");
            }
            if (selectedFile.size > 1 * 1024 * 1024) { // 1MB maximum size
                throw new Error("Image size is too large. Maximum allowed size is 1MB.");
            }
            setError(null);
            if (selectedFile) {
                const reader = new FileReader();
                reader.onload = () => {
                    setImageSrc(reader.result);
                    handleOpen();
                };
                reader.readAsDataURL(selectedFile);
            }
        } catch (error) {
            setError(error.message);
        }
    };

    const handleCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const handleZoomChange = (event, zoomValue) => {
        setZoom(zoomValue);
    };

    const handleCropImage = async () => {
        const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
        if (croppedImage) {
            handleSubmit(croppedImage);
        }
        handleClose();
    };

    return (
        <>
            <Button
                variant="contained"
                component="label"
                sx={{ width: btnWidth ? btnWidth : 150 }}
                disabled={loading}
            >
                {
                    loading ? (
                        <CircularProgress size={24} color="error" />
                    ) : (
                        <>
                            {text ? text : "Add Image"} <PhotoCamera sx={{ ml: 1 }} />
                            <input
                                hidden
                                accept="image/png image/jpeg image/jpg"
                                type="file"
                                onChange={handleFileChange}
                            />
                        </>
                    )
                }
            </Button>
            {error && <Typography color="error" sx={{ my: 2 }}>{error}</Typography>}
            <Modal open={open}>
                <div style={styles.modal}>
                    <Grid container sx={{ justifyContent: "space-between", }}>
                        <Grid item>
                            <Typography variant='h5' component='h5' ml={1}>
                                Crop Image
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <div style={styles.container}>
                        <Cropper
                            image={imageSrc}
                            crop={crop}
                            zoom={zoom}
                            aspect={aspect === 1 ? 1 / 1 : aspect === 16 ? 16 / 9 : aspect === 21 ? 21 / 9 : 1.5 / 1}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={handleCropComplete}
                            showGrid={false}
                        />
                    </div>
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            onChange={handleZoomChange}
                            sx={{ width: 300 }}
                        />
                        <Typography variant="body2">
                            Zoom
                        </Typography>
                    </div>
                    <Button onClick={handleCropImage} variant="contained">
                        Submit
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default ImageCropper;

import {
    GET_DESTINATION_TYPES,
    GET_DESTINATION_TYPES_SUCCESS,
    GET_DESTINATION_TYPES_FAILURE,
} from '../actions/destinationTypes';

import { destinationTypesApi } from '../api';
import { call, put, takeEvery } from 'redux-saga/effects';

function* getDestinationTypes() {
    try {
        const response = yield call(destinationTypesApi.getAllDestinationTypes);
        yield put({ type: GET_DESTINATION_TYPES_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_DESTINATION_TYPES_FAILURE, payload: error });
    }
}

export default function* getDestinationTypesSaga() {
    yield takeEvery(GET_DESTINATION_TYPES, getDestinationTypes);
}

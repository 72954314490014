import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    useTheme,
} from "@mui/material";

const ConfirmBox = ({ open, type, message, handleClose, onOkClick }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        type === "error" ? (
            <Dialog
                fullScreen={fullScreen}
                open={open}
            >
                <DialogTitle>
                    Error
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Okay! Try Again
                    </Button>
                </DialogActions>
            </Dialog>
        ) : type === "success" ? (
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={onOkClick}
            >
                <DialogTitle>
                    Success
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onOkClick} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        ) : null

    );
}

export default ConfirmBox;
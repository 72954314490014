import {
    GET_BANNERS,
    GET_BANNERS_SUCCESS,
    GET_BANNERS_FAILURE,
} from '../actions/banners';

import { bannersApi } from '../api';

import { call, put, takeEvery } from 'redux-saga/effects';

function* getAllBanners() {
    try {
        const response = yield call(bannersApi.getAllBanners);
        yield put({ type: GET_BANNERS_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_BANNERS_FAILURE, payload: error });
    }
}

export default function* bannersSaga() {
    yield takeEvery(GET_BANNERS, getAllBanners);
}
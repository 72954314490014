import { BackdropLoading } from "../../components"
import { Box, Typography, Grid } from "@mui/material"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getRequestedSchoolTrips } from "../../services/actions/schoolTrip"
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import Table from "./Table"

export const SchoolBookings = () => {
    const dispatch = useDispatch()
    const { schoolTrips, loading, error } = useSelector(state => state.schoolTrips)

    useEffect(() => {
        dispatch(getRequestedSchoolTrips())
    }, [dispatch])

    if (loading) return <BackdropLoading open={loading} />
    if (error) return <div>Error</div>

    return (
        <Box>
            <Grid container sx={{ justifyContent: "space-between", py: 2, px: 5 }}>
                <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <FlagCircleIcon sx={{ fontSize: 40 }} />
                        <Typography variant='h5' component='h5' ml={1}>
                            School Bookings
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ px: 2, py: 3 }}>
                {
                    schoolTrips && schoolTrips.length > 0 ? (
                        <Table data={schoolTrips} />
                    ) : (
                        <Typography variant="h5" component="h5" align="center">
                            No School Bookings found
                        </Typography>
                    )
                }
            </Box>
        </Box>
    )
}
import {
    Typography,
    Box,
    Modal,
    Container,
    Button,
    TextField,
    Grid,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ListItemText,
    Input,
    CircularProgress,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bannersApi } from "../../services/api";
import { getBanners } from "../../services/actions/banners"
import { mediaApi } from "../../services/api";
import { ImageCropper } from "../../components";

const AddNew = ({ open, handleClose, action, id }) => {
    const dispatch = useDispatch()
    const { experiences } = useSelector(state => state.experiences)
    const [loading, setLoading] = useState(false)
    const [imageUploading, setImageUploading] = useState(false);

    const [data, setData] = useState({
        name: "",
        image_url: "",
        experience_id: null,
        external_url: "",
        priority: 1,
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSave = async () => {
        setLoading(true)
        try {
            const response = action === "edit" ? await bannersApi.updateBanner({ ...data, priority: data.priority.toString() }) : await bannersApi.createBanner({ ...data, priority: data.priority.toString() })
            if (response.data.success) {
                dispatch(getBanners())
                handleClose();
            } else {
                throw new Error(response.data.message)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }
    }

    const getData = async () => {
        try {
            const response = await bannersApi.getBanner(id)
            if (response.data.success) {
                setData({
                    id,
                    name: response?.data?.data?.name,
                    experience_id: response?.data?.data?.experience?.id,
                    priority: response?.data?.data?.priority,
                    image_url: response?.data?.data?.image_url,
                    external_url: response?.data?.data?.external_url,
                    status: response?.data?.data?.status
                })
            } else {
                throw new Error(response.data.message)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleCoverPhotoUpload = useCallback(async (image) => {
        try {
            setImageUploading(true);
            const formData = new FormData();
            formData.append("media", image);
            const response = await mediaApi.uploadImage(formData);

            if (response.data.success) {
                if (response.data.success) {
                    setData({ ...data, image_url: response.data.data.url })
                }
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setImageUploading(false);
        }
    }, [data]);

    useEffect(() => {
        if (action === "edit") {
            getData();
        } else {
            setData({
                name: "",
                image_url: "",
                experience_id: null,
                external_url: "",
                priority: 1,
            })
        }
        // eslint-disable-next-line
    }, [open])

    useEffect(() => {
        if (data.experience_id && data.external_url !== "") {
          setData((prev) => ({ ...prev, external_url: "" }));
        }
        // eslint-disable-next-line
      }, [data.experience_id]);
      
      useEffect(() => {
        if (data.external_url && data.experience_id !== null) {
          setData((prev) => ({ ...prev, experience_id: null }));
        }
        // eslint-disable-next-line
      }, [data.external_url]);
      
      


    return (
        <Modal open={open}>
            <Box sx={{
                p: 2,
                pb: 4,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "100%",
                maxWidth: "600px",
                bgcolor: "background.paper",
                boxShadow: 24,
                borderRadius: "5px",
            }}
            >
                <Container>
                    <Grid container sx={{ justifyContent: "space-between", }}>
                        <Grid item>
                            <Typography variant='h5' component='h5' ml={1}>
                                {
                                    action === "edit" ? "Edit Banner" : "Add New Banner"
                                }
                            </Typography>

                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Box sx={{ px: 2 }}>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label="Priority"
                                name="priority"
                                value={data.priority || ""}
                                required
                                variant="standard"
                                onChange={handleChange}
                                inputProps={{
                                    type: "number",
                                }}
                            />
                        </Box>

                        <Box sx={{ mt: 3 }}>
                            <TextField
                                fullWidth
                                label="Banner Name"
                                name="name"
                                value={data.name}
                                required
                                variant="standard"
                                onChange={handleChange}
                            />
                        </Box>

                        <Box sx={{ mt: 3 }}>
                            <TextField
                                fullWidth
                                label="External Url"
                                name="external_url"
                                value={data.external_url || ""}
                                variant="standard"
                                onChange={handleChange}
                            />
                        </Box>

                        <Box sx={{ mt: 3 }}>
                            <Typography variant="body1" color="text.secondary" align="center">
                                OR
                            </Typography>
                        </Box>

                        <Box sx={{ mt: 1 }}>
                            <FormControl fullWidth>
                                <InputLabel>Tagged Experience</InputLabel>
                                <Select
                                    name="experience_id"
                                    value={data.experience_id || ""}
                                    onChange={handleChange}
                                    input={<Input />}
                                    renderValue={(selected) => (
                                        experiences.find((exp) => exp.id === selected)?.title
                                    )}
                                >
                                    {experiences?.filter(item => item?.status)?.map((exp) => (
                                        <MenuItem key={exp.id} value={exp.id}>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                    <img
                                                        src={exp.cover_photo}
                                                        style={{
                                                            width: "25px",
                                                            height: "25px",
                                                            borderRadius: "50%",
                                                            objectFit: "cover"
                                                        }}
                                                        alt={exp.title}
                                                    />
                                                    <ListItemText primary={exp.title} />
                                                    {
                                                        exp.partner.name && (
                                                            <ListItemText secondary={`By ${exp.partner.name}`} />
                                                        )
                                                    }
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <Box sx={{ mt: 3, display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                            <ImageCropper
                                text="Upload Banner Image"
                                btnWidth={300}
                                handleSubmit={handleCoverPhotoUpload}
                                loading={imageUploading}
                                maxSize={1}
                                aspect={21}
                            />
                        </Box>

                        {
                            data.image_url && (
                                <Box sx={{ mt: 3, height: "200px", display: "flex", justifyContent: "center" }}>
                                    <img
                                        src={data.image_url}
                                        alt="EdOutdoors Banner"
                                        style={{
                                            height: "100%",
                                            aspectRatio: "21/9"
                                        }}
                                    />
                                </Box>
                            )
                        }

                        <Box sx={{ mt: 3 }}>
                            <Button
                                fullWidth
                                size="large"
                                variant="contained"
                                onClick={handleSave}
                                disabled={data.name === "" || data.image_url === "" || loading}
                            >
                                {
                                    loading ? (
                                        <CircularProgress size={24} />
                                    ) : (
                                        action === "edit" ? "Update" : "Add"
                                    )
                                }
                            </Button>
                        </Box>

                    </Box>
                </Container >

            </Box >
        </Modal >
    )
}

export default AddNew
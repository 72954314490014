import {
    GET_UPCOMMING_TOURS,
    GET_UPCOMMING_TOURS_SUCCESS,
    GET_UPCOMMING_TOURS_FAILURE,
    GET_PAST_TOURS,
    GET_PAST_TOURS_SUCCESS,
    GET_PAST_TOURS_FAILURE,
} from "../actions/dashboardData";

const initialState = {
    loading: false,
    error: null,
    upcommingTours: null,
    pastTours: null,
};

export const dashboardData = (state = initialState, action) => {
    switch (action.type) {
        case GET_UPCOMMING_TOURS:
        case GET_PAST_TOURS:
            return { ...state, loading: true, error: null };

        case GET_UPCOMMING_TOURS_SUCCESS:
            return { ...state, upcommingTours: action.payload, loading: false };

        case GET_PAST_TOURS_SUCCESS:
            return { ...state, pastTours: action.payload, loading: false };

        case GET_UPCOMMING_TOURS_FAILURE:
        case GET_PAST_TOURS_FAILURE:
            return { ...state, loading: false, error: action.payload };

        default:
            return state;
    }
};

export const SEND_OTP = 'SEND_OTP';
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS';
export const SEND_OTP_FAILURE = 'SEND_OTP_FAILURE';

export const sendOtp = (mobile) => ({
    type: SEND_OTP,
    mobile
});


export const VERIFY_OTP = 'VERIFY_OTP';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE';

export const verifyOtp = (mobile, otp) => ({
    type: VERIFY_OTP,
    mobile,
    otp
});


export const RESEND_OTP = 'RESEND_OTP';
export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS';
export const RESEND_OTP_FAILURE = 'RESEND_OTP_FAILURE';

export const resendOtp = (mobile) => ({
    type: RESEND_OTP,
    mobile
});

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_FAILURE';

export const getUser = () => ({
    type: GET_USER
});

export const UPDATE_PARTNER = 'UPDATE_PARTNER';
export const UPDATE_PARTNER_SUCCESS = 'UPDATE_PARTNER_SUCCESS';
export const UPDATE_PARTNER_FAILURE = 'UPDATE_PARTNER_FAILURE';

export const updatePartner = (id) => ({
    type: UPDATE_PARTNER,
    id
});

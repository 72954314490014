import {
    GET_EXPERIENCE_TYPES,
    GET_EXPERIENCE_TYPES_SUCCESS,
    GET_EXPERIENCE_TYPES_FAILURE,
} from '../actions/experienceTypes';

const initialState = {
    experienceTypes: [],
    loading: false,
    error: null
}

export const experienceTypes = (data = initialState, action) => {
    switch (action.type) {
        case GET_EXPERIENCE_TYPES:
            return {
                ...data,
                loading: true,
                error: null
            }

        case GET_EXPERIENCE_TYPES_SUCCESS:
            return {
                ...data,
                loading: false,
                experienceTypes: action.payload
            }

        case GET_EXPERIENCE_TYPES_FAILURE:
            return {
                ...data,
                loading: false,
                error: action.payload,
            }

        default:
            return data
    }
}
export const GET_SUBJECTS = 'GET_SUBJECTS';
export const GET_SUBJECTS_SUCCESS = 'GET_SUBJECTS_SUCCESS';
export const GET_SUBJECTS_FAILURE = 'GET_SUBJECTS_FAILURE';

export const getSubjects = () => ({
    type: GET_SUBJECTS,
});

export const GET_SUBJECT = 'GET_SUBJECT';
export const GET_SUBJECT_SUCCESS = 'GET_SUBJECT_SUCCESS';
export const GET_SUBJECT_FAILURE = 'GET_SUBJECT_FAILURE';

export const getSubject = (id) => ({
    type: GET_SUBJECT,
    id,
});

export const CREATE_SUBJECT = 'CREATE_SUBJECT';
export const CREATE_SUBJECT_SUCCESS = 'CREATE_SUBJECT_SUCCESS';
export const CREATE_SUBJECT_FAILURE = 'CREATE_SUBJECT_FAILURE';

export const createSubject = (payload) => ({
    type: CREATE_SUBJECT,
    payload,
});

export const EDIT_SUBJECT = 'EDIT_SUBJECT';
export const EDIT_SUBJECT_SUCCESS = 'EDIT_SUBJECT_SUCCESS';
export const EDIT_SUBJECT_FAILURE = 'EDIT_SUBJECT_FAILURE';

export const updateSubject = (id, payload) => ({
    type: EDIT_SUBJECT,
    id,
    payload,
});

export const DELETE_SUBJECT = 'DELETE_SUBJECT';
export const DELETE_SUBJECT_SUCCESS = 'DELETE_SUBJECT_SUCCESS';
export const DELETE_SUBJECT_FAILURE = 'DELETE_SUBJECT_FAILURE';

export const deleteSubject = (id) => ({
    type: DELETE_SUBJECT,
    id,
});
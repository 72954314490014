import { Box, Typography } from "@mui/material"
import { useState, useEffect, useCallback } from "react"
import styles from "./Media.module.css"
import DeleteIcon from "@mui/icons-material/Delete"
import { partnerImagesApi, mediaApi } from "../../services/api"
import { DeleteConfirmBox } from "../../components"
import { ImageCropper } from "../../components"

const Media = ({ partner }) => {
    const [images, setImages] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const getPartnerImages = useCallback(async () => {
        try {
            const response = await partnerImagesApi.getPartnerImages(partner?.id)
            if (response.data.success) {
                setImages(response.data.data)
            } else {
                throw new Error(response.data.message)
            }
        } catch (error) {
            console.error(error)
        }
    }, [partner?.id])

    const handleImageUpload = useCallback(async (image) => {
        try {
            if (images.length >= 50) {
                throw new Error("Maximum number of images is 50.")
            }
            setLoading(true);
            const formData = new FormData();
            formData.append("media", image);
            const response = await mediaApi.uploadImage(formData);

            if (response.data.success) {
                const res = await partnerImagesApi.createPartnerImages({
                    partner_id: partner?.id,
                    images: [response.data.data.url],
                });
                if (res.data.success) {
                    getPartnerImages();
                } else {
                    throw new Error(res.data.message);
                }
            } else {
                throw new Error(response.data.message);
            }
            setError(null);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    },[getPartnerImages, images.length, partner?.id]);

    const handleOpenDelete = useCallback((id) => {
        setDeleteId(id);
        setDeleteConfirm(true);
    }, []);

    const handleCloseDelete = useCallback(() => {
        setDeleteId(null);
        setDeleteConfirm(false);
    }, []);

    const handleDeleteImage = useCallback(async (id) => {
        if (!id) return;
        try {
            setLoading(true)
            const response = await partnerImagesApi.deletePartnerImages({ id: id })
            if (response.data.success) {
                setImages(images.filter(image => image.id !== id))
            } else {
                throw new Error(response.data.message)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false);
            handleCloseDelete();
        }
    }, [ handleCloseDelete, images]);

    useEffect(() => {
        getPartnerImages()
    }, [getPartnerImages])

    return (
        <Box sx={{ pt: 0 }}>
            <Box sx={{ mb: 3 }}>
                <ImageCropper handleSubmit={handleImageUpload} loading={loading}/>
                {
                    error && (
                        <Typography variant="caption" color="error" sx={{ ml: 1 }}>
                            {error}
                        </Typography>
                    )
                }
            </Box>

            <Box className={styles.mediaBox}>
                {
                    images.length > 0 ? (
                        images.map(image => (
                            <Box className={styles.mediaBoxImage} key={image.id}>
                                <img
                                    src={image.image_url}
                                    alt="partner"
                                />
                                <div className={styles.delete}>
                                    <DeleteIcon
                                        className={styles.deleteIcon}
                                        color="error"
                                        fontSize="large"
                                        onClick={() => handleOpenDelete(image.id)}
                                    />
                                </div>
                            </Box>
                        ))
                    ) : (
                        <Typography variant="body2" color="textSecondary">
                            No images found
                        </Typography>
                    )
                }
            </Box>

            {
                deleteConfirm && (
                    <DeleteConfirmBox
                        open={deleteConfirm}
                        handleClose={handleCloseDelete}
                        onProceedClick={() => handleDeleteImage(deleteId)}
                    />
                )
            }

        </Box>
    )
}

export default Media
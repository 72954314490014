import {
    Typography,
    Box,
    Button,
} from "@mui/material";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useState } from "react";
import { AddTour } from "./AddTour";
import { useNavigate } from "react-router-dom"
import styles from "./styles.module.css"
import { Popup } from "../../components";

export const ExpCard = ({ exp }) => {

    const navigate = useNavigate();
    const [openAddTour, setOpenAddTour] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);

    return (
        <Box sx={{ my: 2 }}>
            <Box className={styles.mainExpCard}>
                <Box className={styles.expCardDetails}>
                    <Typography variant="h4" component="h4">
                        {
                            exp.title.length > 20 ? exp.title.slice(0, 50) + "..." : exp.title
                        }
                    </Typography>
                    <Typography variant="h5" component="h5">
                        {
                            exp.duration
                        }
                    </Typography>
                    <Typography variant="h6" component="h6" color={exp.status ? "green" : "red"}>
                        {
                            exp.status ? "Approved" : "In Review"
                        }
                    </Typography>
                </Box>

                <Box className={styles.expCardImageBox}>
                    <img src={exp.cover_photo} alt={exp.title} />
                </Box>
            </Box>

            <Box className={styles.btnBox}>
                <Button
                    variant="outlined"
                    fullWidth
                    size="medium"
                    sx={{
                        height: "50px",
                        color: exp.status ? "#000" : "#A6A6A6",
                        border: "1px solid #A6A6A6",
                        fontWeight: "600",
                        textTransform: "none",
                        borderTopRightRadius: "0",
                        borderBottomRightRadius: "0",
                    }}
                    onClick={() => {
                        if (exp.status) {
                            setOpenAddTour(true)
                        } else {
                            setOpenPopup(true)
                        }
                    }}
                >
                    Add a Tour
                </Button>
                <Button
                    variant="outlined"
                    fullWidth
                    size="medium"
                    sx={{
                        height: "50px",
                        color: "#000",
                        border: "1px solid #A6A6A6",
                        fontWeight: "600",
                        textTransform: "none",
                        borderRadius: "0",
                    }}
                    onClick={() => navigate(`/dashboard/experiences/${exp.id}`)}
                >
                    Edit
                </Button>
                {
                    window.innerWidth > 600 && (
                        <Button
                            variant="outlined"
                            fullWidth
                            size="medium"
                            sx={{
                                height: "50px",
                                color: exp.status ? "#000" : "#A6A6A6",
                                border: "1px solid #A6A6A6",
                                fontWeight: "600",
                                textTransform: "none",
                                borderTopLeftRadius: "0",
                                borderBottomLeftRadius: "0",
                            }}
                            startIcon={<RemoveRedEyeIcon />}
                            onClick={() => {
                                if (exp.status) {
                                    window.open(`${process.env.REACT_APP_STUDENT_PORTAL_URL}/experience/${exp.id}`, "_blank")
                                } else {
                                    setOpenPopup(true)
                                }
                            }}
                        >
                            View Event
                        </Button>
                    )
                }
            </Box>

            {
                openAddTour && (
                    <AddTour
                        open={openAddTour}
                        handleClose={() => setOpenAddTour(false)}
                        expId={exp.id}
                        title={exp.title}
                        duration={exp.duration}
                    />
                )

            }

            {
                openPopup && (
                    <Popup
                        open={openPopup}
                        handleClose={() => setOpenPopup(false)}
                        message="Button will be enabled once this experience is approved"
                    />
                )
            }
        </Box>
    );
};

import {
    GET_PARTNERS,
    GET_PARTNERS_SUCCESS,
    GET_PARTNERS_FAILURE,
} from '../actions/partner';

const initialState = {
    partners: [],
    loading: false,
    error: null
}

export const partners = (data = initialState, action) => {
    switch (action.type) {
        case GET_PARTNERS:
            return {
                ...data,
                loading: true,
                error: null
            }

        case GET_PARTNERS_SUCCESS:
            return {
                ...data,
                loading: false,
                partners: action.payload
            }

        case GET_PARTNERS_FAILURE:
            return {
                ...data,
                loading: false,
                error: action.payload,
            }

        default:
            return data
    }
}
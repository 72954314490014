import {
    GET_TRANSACTIONS,
    GET_TRANSACTIONS_SUCCESS,
    GETTRANSACTIONSS_FAILURE
} from '../actions/transaction';
import { transactionApi } from '../api';

import { call, put, takeEvery } from 'redux-saga/effects';

function* getTransactions() {
    try {
        const response = yield call(transactionApi.getAllTransactions);
        yield put({ type: GET_TRANSACTIONS_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GETTRANSACTIONSS_FAILURE, payload: error });
    }
}

export default function* transactionSaga() {
    yield takeEvery(GET_TRANSACTIONS, getTransactions);
}
import {
  Box,
  Container,
  TextField,
  FormControl,
  Typography,
  Button
} from "@mui/material";

import {
  Facebook,
  Instagram,
  LinkedIn
} from "@mui/icons-material";
import OTPInput from "otp-input-react";
import styles from "./style.module.css"
import { useState, useEffect } from "react"
import { authApi } from "../../services/api"
import { useNavigate } from "react-router-dom"

export const Login = ({ user }) => {
  const navigate = useNavigate()
  const [phoneNumber, setPhoneNumber] = useState("")
  const [otp, setOtp] = useState("")
  const [showOtp, setShowOtp] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const handleOtpChange = (otp) => {
    setOtp(otp)
  }

  // setting the max length of the input field to 10
  const handleNumberChange = (object) => {
    if (object.target.value.length > 10) {
      object.target.value = object.target.value.slice(0, 10)
      setPhoneNumber(object.target.value)
    } else {
      setPhoneNumber(object.target.value)
    }
  }

  const handleSendOtp = async () => {
    setLoading(true)
    setError(null)
    try {
      const response = await authApi.sendOtp(phoneNumber)
      if (!response.data.success) {
        throw new Error(response.data.message)
      }
      setShowOtp(true)
      setOtp("")
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const handleEditNumber = () => {
    setShowOtp(false)
    setOtp("")
    setError(null)
  }

  const handleResendOtp = async () => {
    setOtp("")
    setLoading(true)
    setError(null)
    try {
      const response = await authApi.resendOtp(phoneNumber)
      if (!response.data.success) {
        throw new Error(response.data.message)
      }
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }

  }

  const handleLogin = async (event) => {
    event.preventDefault()
    setLoading(true)
    try {
      const response = await authApi.verifyOtp({ mobile: phoneNumber, otp })
      if (response.data.token) {
        localStorage.setItem("token", response.data.token)
        window.location.href = "/dashboard"
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (user) {
      navigate("/dashboard", { replace: true })
    }
    //eslint-disable-next-line
  }, [user])

  return (
    <Box
      className={styles.box}
      sx={{
        backgroundImage: `linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.6)),url("/images/login.webp")`
      }}
    >
      <Container className={styles.logo}>
        <img src="/logo.webp" alt="edoutdoors" />
      </Container>

      <Container className={styles.form}>
        <Typography variant="h4" component="h4">
          Login
        </Typography>

        <Box className={styles.formInner}>
          <FormControl fullWidth>
            <TextField
              name="number"
              type="number"
              label="Phone Number"
              variant="standard"
              value={phoneNumber}
              onChange={handleNumberChange}
              disabled={showOtp}
              inputRef={input => input && input.focus()}
              onKeyDown={(e) => {
                if (e.keyCode === 13 && phoneNumber.length === 10 && !loading) {
                  handleSendOtp()
                }
              }}
            />
          </FormControl>

          <Box className={styles.sendOtpButton}>
            {
              showOtp ? (
                <Box className={styles.edit}>
                  <Typography onClick={handleEditNumber} variant="h6" component="h6">
                    Edit
                  </Typography>
                </Box>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={phoneNumber.length < 10 || loading}
                  onClick={handleSendOtp}
                >
                  Request OTP
                </Button>
              )
            }

          </Box>

          {
            error && !showOtp && (
              <Typography color="error" sx={{ textAlign: "center", mt: 2 }}>
                {error.message}
              </Typography>
            )
          }

          {
            showOtp && (
              <Box className={styles.otpInput}>
                <Typography variant="h5" component="h5">
                  OTP Code
                </Typography>
                <form onSubmit={handleLogin}>
                  <OTPInput
                    value={otp}
                    onChange={handleOtpChange}
                    otpType="number"
                    OTPLength={6}
                    autoFocus
                    className={styles.otpInputBox}
                  />


                  <Box className={styles.resendOtp}>
                    <Typography onClick={handleResendOtp} variant="h6" component="h6">
                      Resend OTP
                    </Typography>
                  </Box>

                  <Box className={styles.loginButton}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={otp.length < 6 || loading}
                      type="submit"
                      onClick={handleLogin}
                    >
                      Login
                    </Button>
                  </Box>
                </form>
              </Box>
            )
          }

          {
            error && showOtp && (
              <Typography color="error" sx={{ textAlign: "center", mt: 2 }}>
                {error.message}
              </Typography>
            )
          }


        </Box>

        <Box className={styles.terms}>
          <Typography variant="p" component="p">
            By using this platform, you agree to the {" "}
            <a href="/">terms and conditions</a> {" "}
            and {" "}
            <a href="/">code of conduct</a>   {" "}
            set by EdOutdoors
          </Typography>
        </Box>
      </Container>

      <Box className={styles.footer}>
        <Container className={styles.footerContainer} sx={{ display: "flex" }}>
          <Box className={styles.footerLeft} >
            <Typography variant="h6" component="h6">
              {
                new Date().getFullYear() + " © Edoutdoors"
              }
            </Typography>
          </Box>
          <Box className={styles.footerRight}>
            <Typography href='https://www.facebook.com/edoutdoorsin' target="_blank" variant="a" component="a">
              <Facebook />
            </Typography>
            <Typography href='https://www.linkedin.com/company/edoutdoors' target="_blank" variant="a" component="a">
              <LinkedIn />
            </Typography>
            <Typography href='https://www.instagram.com/edoutdoors_official' target="_blank" variant="a" component="a">
              <Instagram />
            </Typography>
          </Box>
        </Container>
      </Box>

    </Box >
  )
}
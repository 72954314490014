import React, { useState } from "react";
import {
    Table as MUITable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Collapse,
} from "@mui/material";
import moment from "moment";

const Table = ({ data }) => {
    const [expandedRow, setExpandedRow] = useState(null);

    const handleRowClick = (index) => {
        setExpandedRow(index === expandedRow ? null : index);
    };

    return (
        <TableContainer component={Paper}>
            <MUITable>
                <TableHead sx={{ background: "#f8f8f8" }}>
                    <TableRow>
                        <TableCell align="left">Number</TableCell>
                        <TableCell align="left">Tour Date</TableCell>
                        <TableCell align="left">Experience Name</TableCell>
                        <TableCell align="left">Number Of Registrations</TableCell>
                        <TableCell align="left">Total Payment</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((row, index) => (
                        <React.Fragment key={index}>
                            <TableRow
                                sx={{ cursor: "pointer" }}
                                onClick={() => handleRowClick(index)}
                            >
                                <TableCell align="left">{index + 1}</TableCell>
                                <TableCell align="left">{moment(row?.start_date).format("DD/MM/YYYY")}</TableCell>
                                <TableCell align="left">{row?.experience?.title}</TableCell>
                                <TableCell align="left">{row?.registration?.length}</TableCell>
                                <TableCell align="left">{row?.totalRegistrationAmount}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={6}>
                                    <Collapse
                                        in={index === expandedRow}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <TableContainer component={Paper}>
                                            <MUITable>
                                                <TableHead sx={{ background: "#f8f8f8" }}>
                                                    <TableRow>
                                                        <TableCell align="left">Child Name</TableCell>
                                                        <TableCell align="left">Grade</TableCell>
                                                        <TableCell align="left">Payment Date and Time</TableCell>
                                                        <TableCell align="left">Amount Received</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        row?.registration?.map((item, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell align="left">{item?.children?.first_name}</TableCell>
                                                                <TableCell align="left">{item?.children?.grade?.grade}</TableCell>
                                                                <TableCell align="left">{moment(item?.created_at).format("DD/MM/YYYY HH:mm:ss")}</TableCell>
                                                                <TableCell align="left">{item?.amount}</TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                            </MUITable>
                                        </TableContainer>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </MUITable>
        </TableContainer>
    );
};

export default Table;

import {
    SEND_OTP,
    SEND_OTP_SUCCESS,
    SEND_OTP_FAILURE,
    VERIFY_OTP,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAILURE,
    RESEND_OTP,
    RESEND_OTP_SUCCESS,
    RESEND_OTP_FAILURE,
    GET_USER,
    GET_USER_SUCCESS,
    GET_USER_FAILURE,
    UPDATE_PARTNER,
    UPDATE_PARTNER_FAILURE,
    UPDATE_PARTNER_SUCCESS
} from "../actions/auth";

const initialState = {
    user: null,
    partner: null,
    loading: false,
    error: null,
    isAdmin: false
};

const auth = (data = initialState, action) => {
    switch (action.type) {
        case SEND_OTP:
        case VERIFY_OTP:
        case RESEND_OTP:
        case GET_USER:
        case UPDATE_PARTNER:
            return {
                ...data,
                loading: true,
                error: null
            };

        case SEND_OTP_SUCCESS:
        case RESEND_OTP_SUCCESS:
            return {
                ...data,
                loading: false,
            };

        case VERIFY_OTP_SUCCESS:
            return {
                ...data,
                loading: false,
            };

        case GET_USER_SUCCESS:
            return {
                ...data,
                loading: false,
                user: action.payload.user,
                partner: action.payload.partner,
                isAdmin: action.payload.user.role.name.toLowerCase() === "admin"
            };

        case UPDATE_PARTNER_SUCCESS:
            return {
                ...data,
                loading: false,
                partner: action.payload
            };

        case SEND_OTP_FAILURE:
        case VERIFY_OTP_FAILURE:
        case RESEND_OTP_FAILURE:
        case GET_USER_FAILURE:
        case UPDATE_PARTNER_FAILURE:
            return {
                ...data,
                loading: false,
                error: action.payload
            };

        default:
            return data
    }
}

export default auth;
import {
    Table as MUITable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";

const Table = ({ data }) => {
    const navigate = useNavigate()
    const handleEdit = (id) => {
        navigate(`/dashboard/experiences/${id}`)
    }

    return (
        <TableContainer component={Paper}>
            <MUITable>
                <TableHead sx={{ background: "#f8f8f8" }}>
                    <TableRow>
                        <TableCell align="left">Number</TableCell>
                        <TableCell align="left">Title</TableCell>
                        <TableCell align="left">Primary Subject</TableCell>
                        <TableCell align="left">Duration</TableCell>
                        <TableCell align="left">Price</TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, index) => (
                        <TableRow
                            key={row.id}
                            sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                                backgroundColor: index % 2 === 0 ? "#fff" : "#f8f8f8",
                            }}
                        >
                            <TableCell align="left">
                                {index + 1}
                            </TableCell>

                            <TableCell align="left">
                                {row.title}
                            </TableCell>

                            <TableCell align="left">
                                {row.primary_subject?.name}
                            </TableCell>

                            <TableCell align="left">
                                {row.duration}
                            </TableCell>

                            <TableCell align="left">
                                {row.fee}
                            </TableCell>

                            <TableCell align="left">
                                {row.status ? "Approved" : "In Review"}
                            </TableCell>

                            <TableCell align="left">
                                <Button
                                    variant="outlined"
                                    endIcon={<EditIcon />}
                                    onClick={() => handleEdit(row.id)}
                                >
                                    Edit
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </MUITable>
        </TableContainer>
    )
}

export default Table
import {
    Box,
    AppBar,
    Tabs,
    Tab,
    Grid,
    Typography
} from "@mui/material"
import { useState } from "react"
import { TabPanel } from "../../components"
import SubjectIcon from '@mui/icons-material/Subject';
import { ExperienceTypes } from "./ExpTypes"
import { DestinationTypes } from "./DestinationTypes"
import { Subject } from "./Subject"
import { Hobby } from "./Hobby"

export const TypesAndSubjects = () => {
    const [activeTab, setActiveTab] = useState(0)

    return (
        <Box>
            <Grid container sx={{ justifyContent: "space-between", py: 2, px: 5 }}>
                <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <SubjectIcon sx={{ fontSize: 40 }} />
                        <Typography variant='h5' component='h5' ml={1}>
                            Types & Subjects
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Box>
                <AppBar position="static">
                    <Tabs
                        value={activeTab}
                        onChange={(e, value) => setActiveTab(value)}
                        sx={{ px: 3 }}
                        textColor="inherit"
                        indicatorColor="secondary"
                    >
                        <Tab label="Experience Types" />
                        <Tab label="Destination Types" />
                        <Tab label="Subjects" />
                        <Tab label="Hobby" />

                    </Tabs>
                </AppBar>

                <TabPanel value={activeTab} index={0}>
                    <ExperienceTypes />
                </TabPanel>

                <TabPanel value={activeTab} index={1}>
                    <DestinationTypes />
                </TabPanel>

                <TabPanel value={activeTab} index={2}>
                    <Subject />
                </TabPanel>

                <TabPanel value={activeTab} index={3}>
                    <Hobby />
                </TabPanel>
            </Box>
        </Box>
    )
}
import {
    GET_SUBJECTS,
    GET_SUBJECTS_SUCCESS,
    GET_SUBJECTS_FAILURE,
    GET_SUBJECT,
    GET_SUBJECT_SUCCESS,
    GET_SUBJECT_FAILURE,
} from '../actions/subjects';

const initialState = {
    subjects: [],
    subject: {},
    loading: false,
    error: null
};

const subjects = (data = initialState, action) => {
    switch (action.type) {
        case GET_SUBJECTS:
            return {
                ...data,
                loading: true,
                error: null
            };
        case GET_SUBJECTS_SUCCESS:
            return {
                ...data,
                loading: false,
                subjects: action.payload
            };
        case GET_SUBJECTS_FAILURE:
            return {
                ...data,
                loading: false,
                error: action.payload,
                subjects: []
            };
        case GET_SUBJECT:
            return {
                ...data,
                loading: true,
                error: null
            };
        case GET_SUBJECT_SUCCESS:
            return {
                ...data,
                loading: false,
                subject: action.payload
            };
        case GET_SUBJECT_FAILURE:
            return {
                ...data,
                loading: false,
                error: action.payload,
                subject: {}
            };
        default:
            return data;
    }
}

export default subjects;
import {
    Table as MUITable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export const PartnerTable = ({ data, handleEdit, handleDelete }) => {

    return (
        <TableContainer component={Paper}>
            <MUITable>
                <TableHead sx={{ background: "#f8f8f8" }}>
                    <TableRow>
                        <TableCell align="left">Number</TableCell>
                        <TableCell align="left">ID</TableCell>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">Role</TableCell>
                        <TableCell align="left">Email</TableCell>
                        <TableCell align="left">Phone Number</TableCell>
                        <TableCell align="left">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, index) => (
                        <TableRow
                            key={row.id}
                            sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                                backgroundColor: index % 2 === 0 ? "#fff" : "#f8f8f8",
                            }}
                        >
                            <TableCell align="left">
                                {index + 1}
                            </TableCell>

                            <TableCell align="left">
                                {row.id}
                            </TableCell>

                            <TableCell align="left">
                                {row.name}
                            </TableCell>

                            <TableCell align="left">
                                {row?.role?.role?.name}
                            </TableCell>

                            <TableCell align="left">
                                {row.email}
                            </TableCell>

                            <TableCell align="left">
                                {row.user.mobile_number}
                            </TableCell>

                            <TableCell align="left">
                                <Button
                                    variant="outlined"
                                    endIcon={<EditIcon />}
                                    sx={{ marginRight: "10px", width: "100px" }}
                                    onClick={() => handleEdit(row.id)}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    endIcon={<DeleteIcon />}
                                    sx={{ width: "100px" }}
                                    onClick={() => handleDelete(row.id)}
                                >
                                    Delete
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </MUITable>
        </TableContainer>
    )
}

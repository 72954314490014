import {
    Box,
    Typography,
    Grid,
    Button
} from "@mui/material"
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import AddNew from "./AddNew";
import { useSelector, useDispatch } from "react-redux"
import { useState, useEffect } from "react"
import { getExperiences } from "../../services/actions/experiences"
import { getBanners } from "../../services/actions/banners"
import Table from "./Table"
import { BackdropLoading } from "../../components";

export const Banners = () => {
    const dispatch = useDispatch()
    const { loading, error } = useSelector(state => state.experiences)
    const { banners, loading: cLoading, error: cError } = useSelector(state => state.banners)
    const [openAddNew, setOpenAddNew] = useState(false);
    const [action, setAction] = useState("add")
    const [editId, setEditId] = useState(null)

    const handleEdit = (id) => {
        setEditId(id)
        setAction("edit")
        setOpenAddNew(true)
    }

    const handleCloseModal = () => {
        setOpenAddNew(false)
        setAction("add")
        setEditId(null)
    }

    const sortByPriority = (a, b) => {
        return a.priority - b.priority
    }

    useEffect(() => {
        dispatch(getExperiences())
        dispatch(getBanners())
    }, [dispatch])

    if (loading || cLoading) return <BackdropLoading open={true} />
    if (error || cError) return <div>Error</div>

    return (
        <Box>
            <Grid container sx={{ justifyContent: "space-between", py: 2, px: 5 }}>
                <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ViewCarouselIcon sx={{ fontSize: 40 }} />
                        <Typography variant='h5' component='h5' ml={1}>
                            Banners
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <Button variant='contained' onClick={() => setOpenAddNew(true)} >
                            Add New
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            <Box sx={{ px: 5, py: 3 }}>
                {
                    banners.length > 0 ? (
                        <Table
                            data={banners.sort(sortByPriority)}
                            handleEdit={handleEdit}
                        />
                    ) : (
                        <Typography variant='h5' component='h5'>
                            No Banners
                        </Typography>
                    )
                }
            </Box>

            {
                openAddNew && (
                    <AddNew
                        open={openAddNew}
                        handleClose={handleCloseModal}
                        action={action}
                        id={editId}
                    />
                )
            }
        </Box>
    )
}
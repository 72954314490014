
import {
    Typography,
    Box,
    Grid,
} from "@mui/material";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Table from "./Table"
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { BackdropLoading } from "../../components";
import { getTransactions } from "../../services/actions/transaction"

// 1  Newest first accroding to tourDate 
const sortByDate = (a, b) => {
    return new Date(b?.start_date) - new Date(a?.start_date);
};

export const Transactions = () => {
    const dispatch = useDispatch();
    const { transactions, loading, error } = useSelector((state) => state.transaction)

    useEffect(() => {
        dispatch(getTransactions())
    }, [dispatch]);

    if (loading) return <BackdropLoading open={true} />;
    if (error) return <h1>error</h1>;

    return (
        <Box>
            <Grid container sx={{ justifyContent: "space-between", py: 2, px: { xs: 1, sm: 5 } }}>
                <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ReceiptLongIcon sx={{ fontSize: 40 }} />
                        <Typography variant="h5" component="h5" ml={1}>
                            Transactions
                        </Typography>
                    </Box>
                </Grid>
            </Grid>


            {
                transactions?.tour?.length ? (
                    <Box sx={{ px: 2, py: 3 }}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "50px",
                                flexWrap: "wrap",
                                px: 2,
                                mb: 5,
                            }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    minHeight: "100px",
                                    minWidth: "190px",
                                    p: 2,
                                    borderRadius: "10px",
                                    border: "1px solid #e0e0e0",
                                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    background: "#f8f8f8",

                                }}
                            >
                                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                    Total Tours Conducted
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: 600 }} align="left">
                                    {transactions.totalTourConducted}
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    minHeight: "100px",
                                    minWidth: "190px",
                                    p: 2,
                                    borderRadius: "10px",
                                    border: "1px solid #e0e0e0",
                                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    background: "#f8f8f8",

                                }}
                            >
                                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                    Total Earnings
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: 600 }} align="left">
                                    ₹{transactions.totalEarning}
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    minHeight: "100px",
                                    minWidth: "190px",
                                    p: 2,
                                    borderRadius: "10px",
                                    border: "1px solid #e0e0e0",
                                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    background: "#f8f8f8",

                                }}
                            >
                                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                    Earnings This Month
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: 600 }} align="left">
                                    ₹{transactions.monthylEarning}
                                </Typography>
                            </Box>
                        </Box>

                        <Table data={transactions?.tour?.sort(sortByDate)} />
                    </Box>
                ) : (
                    <Typography variant="h5" component="h5" ml={3}>
                        No Transactions
                    </Typography>
                )
            }

        </Box>
    );
};



export const formValidator = {
    name: (value) => {
        const regex = /^[a-zA-Z ]{1,25}$/
        return regex.test(value.trim())
    },

    email: (value) => {
        const emailRegx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return emailRegx.test(String(value).toLowerCase())
    },

    stringLength: (value, min, max) => {
        return value.length >= min && value.length <= max
    },

    // validate string length after removing html tags (for editor)
    stringLengthWithoutHtml: (value, min, max) => {
        const regex = /(<([^>]+)>)/ig
        const strippedString = value.replace(regex, '')
        return strippedString.length >= min && strippedString.length <= max
    },

}
import React from 'react'
import { Routes, Route } from 'react-router-dom'
import ScrollToTop from "./ScrollToTop";
import {
    DashboardHome,
    Experiences,
    ProfilePage,
    Support,
    Transactions,
    CreateExperience,
    AdminExperiences,
    Grades,
    Schools,
    Partners,
    Collections,
    Roles,
    DashboardHomeAdmin,
    Faq,
    Locations,
    TypesAndSubjects,
    SchoolBookings,
    Banners
} from "./containers";
import { getUser } from "./services/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Login, Dashboard } from './containers';

const Router = () => {
    const dispatch = useDispatch();
    const { isAdmin, user } = useSelector(state => state.auth);

    useEffect(() => {
        dispatch(getUser())
        //eslint-disable-next-line
    }, [dispatch])

    return (
        <>
            <ScrollToTop />
            <Routes>
                {
                    !user ? <Route path="*" element={<Login />} /> : (
                        <>
                            {
                                isAdmin ? (
                                    <Route path="/dashboard" element={<Dashboard isAdmin={isAdmin} />} >
                                        <Route path="home" element={<DashboardHomeAdmin />} />
                                        <Route path="experiences" element={<AdminExperiences />} />
                                        <Route path="experiences/:expId" element={<CreateExperience />} />
                                        <Route path="locations" element={<Locations />} />
                                        <Route path="types-subjects" element={<TypesAndSubjects />} />
                                        <Route path="grade" element={<Grades />} />
                                        <Route path="school" element={<Schools />} />
                                        <Route path="partner" element={<Partners />} />
                                        <Route path="role" element={<Roles />} />
                                        <Route path="collections" element={<Collections />} />
                                        <Route path="school-bookings" element={<SchoolBookings />} />
                                        <Route path="banner" element={<Banners />} />
                                        <Route path="*" element={<h2>Page Not Found</h2>} />
                                    </Route>
                                ) : (
                                    <Route path="/dashboard" element={<Dashboard />} >
                                        <Route path="home" element={<DashboardHome />} />
                                        <Route path="experiences" element={<Experiences />} />
                                        <Route path="experiences/create" element={<CreateExperience />} />
                                        <Route path="experiences/:expId" element={<CreateExperience />} />
                                        <Route path="profile" element={<ProfilePage />} />
                                        <Route path="support" element={<Support />} />
                                        <Route path="transactions" element={<Transactions />} />
                                        <Route path="faq" element={<Faq />} />
                                        <Route path="*" element={<h2>Page Not Found</h2>} />
                                    </Route>
                                )
                            }
                        </>
                    )
                }
                <Route path="/login" element={<Login user={user}/>} />
                <Route path="/" element={<Login user={user}/>} />
            </Routes>
        </>
    )
}

export default Router
import { post, get, put, del } from './api'
import {
    authEndpoints,
    subjectsEndpoints,
    countiresEndpoints,
    expEndpoints,
    mediaEndpoints,
    hobbiesEndpoints,
    statesEndpoints,
    citiesEndpoints,
    rolesEndpoints,
    gradesEndpoints,
    schoolsEndpoints,
    partnerEndpoints,
    collectionsEndpoints,
    callbacksEndpoints,
    partnerImagesEndpoints,
    dashboardEndpoints,
    experienceTypesEndpoints,
    destinationTypesEndpoints,
    schoolTripsEndpoints,
    bannerEdnpoints,
    transactionEndpoints
} from './endpoints'
import axios from 'axios'

// Auth API
export const authApi = {
    sendOtp: async (payload) => {
        const response = await post(authEndpoints.sendOtp, { mobile: payload })
        return response
    },

    verifyOtp: async (payload) => {
        const response = await post(authEndpoints.verifyOtp, payload)
        return response
    },

    resendOtp: async (payload) => {
        const response = await post(authEndpoints.resendOtp, { mobile: payload })
        return response
    },

    getUser: async () => {
        const response = await get(authEndpoints.getUser)
        return response
    },

}

// Subjects API
export const subjectsApi = {
    getSubjects: async () => {
        const response = await get(subjectsEndpoints.getAllSubjects)
        return response
    },

    getSubject: async (payload) => {
        const response = await get(subjectsEndpoints.getSubject, payload)
        return response
    },

    createSubject: async (payload) => {
        const response = await post(subjectsEndpoints.createSubject, payload)
        return response
    },

    updateSubject: async (payload) => {
        const response = await put(subjectsEndpoints.updateSubject, payload)
        return response
    },

    deleteSubject: async (payload) => {
        const response = await del(subjectsEndpoints.deleteSubject, { data: payload })
        return response
    },

}

// countries 
export const countriesApi = {
    getCountries: async () => {
        const response = await get(countiresEndpoints.getAllCountries)
        return response
    },

    getCountry: async (payload) => {
        const response = await get(countiresEndpoints.getCountry, payload)
        return response
    },

    createCountry: async (payload) => {
        const response = await post(countiresEndpoints.createCountry, payload)
        return response
    },

    updateCountry: async (payload) => {
        const response = await put(countiresEndpoints.updateCountry, payload)
        return response
    },

    deleteCountry: async (payload) => {
        const response = await del(countiresEndpoints.deleteCountry, { data: payload })
        return response
    },
}

// Experiences API
export const expApi = {
    getAllExperiences: async () => {
        const response = await get(expEndpoints.getAllExperiences)
        return response
    },

    getExperience: async (payload) => {
        const response = await get(`${expEndpoints.getExperience}${payload}`)
        return response
    },

    createExperience: async (payload) => {
        const response = await post(expEndpoints.createExperience, payload)
        return response
    },

    updateExperience: async (payload) => {
        const response = await put(expEndpoints.updateExperience, payload)
        return response
    },

    deleteExperience: async (payload) => {
        const response = await del(expEndpoints.deleteExperience, { data: payload })
        return response
    }

}

//hobbies API
export const hobbiesApi = {
    getAllHobbies: async () => {
        const response = await get(hobbiesEndpoints.getAllHobbies)
        return response
    },

    getHobby: async (payload) => {
        const response = await get(`${hobbiesEndpoints.getHobby}${payload}`)
        return response
    },

    createHobby: async (payload) => {
        const response = await post(hobbiesEndpoints.createHobby, payload)
        return response
    },

    updateHobby: async (payload) => {
        const response = await put(hobbiesEndpoints.updateHobby, payload)
        return response
    },

    deleteHobby: async (payload) => {
        const response = await del(hobbiesEndpoints.deleteHobby, { data: payload })
        return response
    }

}

//states API
export const statesApi = {
    getAllStates: async () => {
        const response = await get(statesEndpoints.getAllStates)
        return response
    },

    getState: async (payload) => {
        const response = await get(`${statesEndpoints.getState}${payload}`)
        return response
    },

    createState: async (payload) => {
        const response = await post(statesEndpoints.createState, payload)
        return response
    },

    updateState: async (payload) => {
        const response = await put(statesEndpoints.updateState, payload)
        return response
    },

    deleteState: async (payload) => {
        const response = await del(statesEndpoints.deleteState, { data: payload })

        return response
    }

}

//cities API
export const citiesApi = {
    getAllCities: async () => {
        const response = await get(citiesEndpoints.getAllCities)
        return response
    },

    getCity: async (payload) => {
        const response = await get(`${citiesEndpoints.getCity}${payload}`)
        return response
    },

    createCity: async (payload) => {
        const response = await post(citiesEndpoints.createCity, payload)
        return response
    },

    updateCity: async (payload) => {
        const response = await put(citiesEndpoints.updateCity, payload)
        return response
    },

    deleteCity: async (payload) => {
        const response = await del(citiesEndpoints.deleteCity, { data: payload })
        return response
    }

}

//roles API
export const rolesApi = {
    getAllRoles: async () => {
        const response = await get(rolesEndpoints.getAllRoles)
        return response
    },

    getRole: async (payload) => {
        const response = await get(`${rolesEndpoints.getRole}${payload}`)
        return response
    },

    createRole: async (payload) => {
        const response = await post(rolesEndpoints.createRole, payload)
        return response

    },

    updateRole: async (payload) => {
        const response = await put(rolesEndpoints.updateRole, payload)
        return response
    },

    deleteRole: async (payload) => {
        const response = await del(rolesEndpoints.deleteRole, { data: payload })
        return response
    }

}

//grades API
export const gradesApi = {
    getAllGrades: async () => {
        const response = await get(gradesEndpoints.getAllGrades)
        return response
    },

    getGrade: async (payload) => {
        const response = await get(`${gradesEndpoints.getGrade}${payload}`)
        return response
    },

    createGrade: async (payload) => {
        const response = await post(gradesEndpoints.createGrade, payload)
        return response
    },

    updateGrade: async (payload) => {
        const response = await put(gradesEndpoints.updateGrade, payload)
        return response
    },

    deleteGrade: async (payload) => {
        const response = await del(gradesEndpoints.deleteGrade, { data: payload })
        return response
    }

}

// Schools API
export const schoolsApi = {
    getAllSchools: async () => {
        const response = await get(schoolsEndpoints.getAllSchools)
        return response
    },

    getSchool: async (payload) => {
        const response = await get(`${schoolsEndpoints.getSchool}${payload}`)
        return response
    },

    createSchool: async (payload) => {
        const response = await post(schoolsEndpoints.createSchool, payload)
        return response
    },

    updateSchool: async (payload) => {
        const response = await put(schoolsEndpoints.updateSchool, payload)
        return response
    },

    deleteSchool: async (payload) => {
        const response = await del(schoolsEndpoints.deleteSchool, { data: payload })
        return response
    }

}

// Partners API
export const partnersApi = {
    getAllPartners: async () => {
        const response = await get(partnerEndpoints.getAllPartners)
        return response
    },

    getPartner: async (payload) => {
        const response = await get(`${partnerEndpoints.getPartner}${payload}`)
        return response
    },

    createPartner: async (payload) => {
        const response = await post(partnerEndpoints.createPartner, payload)
        return response
    },

    updatePartner: async (payload) => {
        const response = await put(partnerEndpoints.updatePartner, payload)
        return response
    },

    deletePartner: async (payload) => {
        const response = await del(partnerEndpoints.deletePartner, { data: payload })
        return response
    }

}

//Media API
export const mediaApi = {
    uploadMedia: async (payload) => {
        const response = await post(mediaEndpoints.uploadMedia, payload)
        return response
    },
    uploadImage: async (payload) => {
        const response = await axios.post(process.env.REACT_APP_IMAGE_UPLOAD_URL, payload, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${localStorage.getItem("token")}`,
            },
        })
        return response
    }
}

// Tour API
export const tourApi = {
    getAllTours: async (payload) => {
        const response = await get(`${expEndpoints.getExperience}${payload}/tour`)
        return response
    },

    getTour: async (payload) => {
        const response = await get(`${expEndpoints.getExperience}${payload.expId}/${payload.tourId}`)
        return response
    },

    createTour: async (payload) => {
        const response = await post(`${expEndpoints.getExperience}${payload.expId}/tour`, payload.data)
        return response
    },

    updateTour: async (payload) => {
        const response = await put(`${expEndpoints.getExperience}${payload.expId}/tour`, payload.data)
        return response
    },

    deleteTour: async (payload) => {
        const response = await del(`${expEndpoints.getExperience}${payload.expId}/tour`, { data: payload.data })
        return response
    }

}

// Collections API
export const collectionsApi = {
    getAllCollections: async () => {
        const response = await get(collectionsEndpoints.getAllCollections)
        return response
    },

    getCollection: async (payload) => {
        const response = await get(`${collectionsEndpoints.getCollection}${payload}`)
        return response
    },

    createCollection: async (payload) => {
        const response = await post(collectionsEndpoints.createCollection, payload)
        return response
    },

    updateCollection: async (payload) => {
        const response = await put(collectionsEndpoints.updateCollection, payload)
        return response
    },

    deleteCollection: async (payload) => {
        const response = await del(collectionsEndpoints.deleteCollection, { data: payload })
        return response
    }

}

// Callbacks API
export const callbacksApi = {
    getAllCallbacks: async () => {
        const response = await get(callbacksEndpoints.getAllCallbacks)
        return response
    }
}

// partnerImages
export const partnerImagesApi = {
    getAllPartnersImages: async () => {
        const response = await get(partnerImagesEndpoints.getAllPartnersImages)
        return response
    },

    getPartnerImages: async (payload) => {
        const response = await get(`${partnerImagesEndpoints.getPartnerImages}${payload}/image`)
        return response
    },

    createPartnerImages: async (payload) => {
        const response = await post(partnerImagesEndpoints.createPartnerImage, payload)
        return response
    },

    deletePartnerImages: async (payload) => {
        const response = await del(partnerImagesEndpoints.deletePartnerImage, { data: payload })
        return response
    }

}

// dashboard Data API
export const dashboardDataApi = {
    getUpcommingTours: async () => {
        const response = await get(dashboardEndpoints.getUpcommingTours)
        return response
    },

    getPastTours: async () => {
        const response = await get(dashboardEndpoints.getPastTours)
        return response
    },

}

// Experiecne Types API     
export const experienceTypesApi = {
    getAllExperienceTypes: async () => {
        const response = await get(experienceTypesEndpoints.getAllExperienceTypes)
        return response
    },

    getExperienceType: async (payload) => {
        const response = await get(`${experienceTypesEndpoints.getExperienceType}${payload}`)
        return response
    },

    createExperienceType: async (payload) => {
        const response = await post(experienceTypesEndpoints.createExperienceType, payload)
        return response
    },

    updateExperienceType: async (payload) => {
        const response = await put(experienceTypesEndpoints.updateExperienceType, payload)
        return response
    },

    deleteExperienceType: async (payload) => {
        const response = await del(experienceTypesEndpoints.deleteExperienceType, { data: payload })
        return response
    }
}

// Destination Types API
export const destinationTypesApi = {
    getAllDestinationTypes: async () => {
        const response = await get(destinationTypesEndpoints.getAllDestinationTypes)
        return response
    },

    getDestinationType: async (payload) => {
        const response = await get(`${destinationTypesEndpoints.getDestinationType}${payload}`)
        return response
    },

    createDestinationType: async (payload) => {
        const response = await post(destinationTypesEndpoints.createDestinationType, payload)
        return response
    },

    updateDestinationType: async (payload) => {
        const response = await put(destinationTypesEndpoints.updateDestinationType, payload)
        return response
    },

    deleteDestinationType: async (payload) => {
        const response = await del(destinationTypesEndpoints.deleteDestinationType, { data: payload })
        return response
    }
}

// School Trips API
export const schoolTripsApi = {
    getAllSchoolTrips: async () => {
        const response = await get(schoolTripsEndpoints.getAllSchoolTrips)
        return response
    }
}

// Banner API
export const bannersApi = {
    getAllBanners: async () => {
        const response = await get(bannerEdnpoints.getAllBanners)
        return response
    },

    getBanner: async (payload) => {
        const response = await get(`${bannerEdnpoints.getBanner}${payload}`)
        return response
    },

    createBanner: async (payload) => {
        const response = await post(bannerEdnpoints.createBanner, payload)
        return response
    },

    updateBanner: async (payload) => {
        const response = await put(bannerEdnpoints.updateBanner, payload)
        return response
    },

    deleteBanner: async (payload) => {
        const response = await del(bannerEdnpoints.deleteBanner, { data: payload })
        return response
    }

}

export const transactionApi = {
    getAllTransactions: async () => {
        const response = await get(transactionEndpoints.getAllTransactions)
        return response
    }
}

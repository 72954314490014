import {
    Typography,
    Box,
    Modal,
    Container,
    Button,
    TextField,
    Grid,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from "@mui/material";

const AddNewSchool = ({ open, handleClose, value, setValue, loading, handleSubmit, action, cities, cityId, setCityId }) => {

    return (
        <Modal open={open}>
            <Box sx={{
                p: 2,
                pb: 4,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "100%",
                maxWidth: "600px",
                bgcolor: "background.paper",
                boxShadow: 24,
                borderRadius: "5px",
            }}
            >

                <Container>
                    <Grid container sx={{ justifyContent: "space-between", }}>
                        <Grid item>
                            <Typography variant='h5' component='h5' ml={1}>
                                {action === "edit" ? "Update" : "Add new "} School
                            </Typography>

                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Box sx={{ px: 2 }}>
                        <TextField
                            fullWidth
                            label="School Name"
                            placeholder="Enter school name"
                            type="text"
                            variant="standard"
                            autoComplete="off"
                            sx={{ my: 4 }}
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSubmit()
                                }
                            }}
                        />
                        <FormControl
                            fullWidth
                            variant="standard"
                            sx={{ mb: 4 }}
                        >
                            <InputLabel>City</InputLabel>
                            <Select
                                value={cityId}
                                label="City"
                                onChange={(e) => setCityId(e.target.value)}
                                name="city_id"
                            >
                                {
                                    cities?.map(city => (
                                        <MenuItem key={city.id} value={city.id}>{city.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <Button
                            variant="contained"
                            fullWidth size="large"
                            disabled={loading || value.trim() === ""}
                            onClick={handleSubmit}
                        >
                            {
                                loading ? (
                                    <CircularProgress />
                                ) : (
                                    action === "edit" ? "Update" : "Add"
                                )
                            }
                        </Button>
                    </Box>
                </Container>

            </Box>
        </Modal>
    )
}

export default AddNewSchool
import {
    Table as MUITable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    useTheme,
    useMediaQuery,
    Typography
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import Callbacks from "./Callbacks";
import Registrations from "./Registrations";

const UpcommingTours = ({ data }) => {
    const [openCallbacks, setOpenCallbacks] = useState(false);
    const [callbacksData, setCallbacksData] = useState([])
    const [openRegistrations, setOpenRegistrations] = useState(false);
    const [registrationsData, setRegistrationsData] = useState([])

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleOpenCallbacks = () => {
        setOpenCallbacks(true);
    }

    const handleCloseCallbacks = () => {
        setOpenCallbacks(false);
    }

    const handleOpenRegistrations = () => {
        setOpenRegistrations(true);
    }

    const handleCloseRegistrations = () => {
        setOpenRegistrations(false);
    }

    return (
        <>
            <TableContainer component={Paper}>
                <MUITable sx={{ minWidth: isMobile ? "100%" : 650, overflow: "hidden" }}>
                    <TableHead sx={{ background: "#f8f8f8" }}>
                        <TableRow>
                            <TableCell align="left">Number</TableCell>
                            <TableCell align="left">Date of tour</TableCell>
                            <TableCell align="left">Experience name</TableCell>
                            <TableCell align="left">Registrations</TableCell>
                            <TableCell align="left">Requested Callbacks</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{
                                    "&:last-child td, &:last-child th": { border: 0 },
                                    backgroundColor: index % 2 === 0 ? "#fff" : "#f8f8f8",
                                }}
                            >
                                <TableCell align="left">{index + 1}</TableCell>
                                <TableCell align="left">{moment(row?.start_date).format("DD/MM/YYYY")}</TableCell>
                                <TableCell align="left">{row?.experience?.title}</TableCell>
                                <TableCell align="left">
                                    <Typography
                                        sx={{
                                            cursor: "pointer",
                                            display: "inline",
                                            color: "#3f51b5",
                                            textDecoration: "underline"
                                        }}
                                        onClick={() => {
                                            setRegistrationsData(row?.registration)
                                            handleOpenRegistrations()
                                        }}
                                    >
                                        {row?.registration?.length}
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography
                                        sx={{
                                            cursor: "pointer",
                                            display: "inline",
                                            color: "#3f51b5",
                                            textDecoration: "underline"
                                        }}
                                        onClick={() => {
                                            setCallbacksData(row?.callback)
                                            handleOpenCallbacks()
                                        }}
                                    >
                                        {row?.callback?.length}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </MUITable>
            </TableContainer>

            {
                openCallbacks && (
                    <Callbacks
                        open={openCallbacks}
                        handleClose={handleCloseCallbacks}
                        data={callbacksData}
                    />
                )
            }

            {
                openRegistrations && (
                    <Registrations
                        open={openRegistrations}
                        handleClose={handleCloseRegistrations}
                        data={registrationsData}
                    />
                )
            }
        </>
    )
}

export default UpcommingTours
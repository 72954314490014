export const GET_UPCOMMING_TOURS = 'GET_UPCOMMING_TOURS';
export const GET_UPCOMMING_TOURS_SUCCESS = 'GET_UPCOMMING_TOURS_SUCCESS';
export const GET_UPCOMMING_TOURS_FAILURE = 'GET_UPCOMMING_TOURS_FAILURE';

export const getUpcommingTours = () => ({
    type: GET_UPCOMMING_TOURS,
});

export const GET_PAST_TOURS = 'GET_PAST_TOURS';
export const GET_PAST_TOURS_SUCCESS = 'GET_PAST_TOURS_SUCCESS';
export const GET_PAST_TOURS_FAILURE = 'GET_PAST_TOURS_FAILURE';

export const getPastTours = () => ({
    type: GET_PAST_TOURS,
});
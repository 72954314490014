import {
    Box,
    Grid,
    Modal,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import moment from "moment"

const Registrations = ({ open, handleClose, data }) => {
    return (
        <Modal
            open={open}
            hideBackdrop
            sx={{
                backgroundColor: "rgba(0,0,0,0.8)",
            }}
        >
            <Box sx={{
                p: 2,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "100%",
                maxWidth: "1000px",
                bgcolor: "background.paper",
                boxShadow: 24,
                borderRadius: "5px",
            }}>
                <Box>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="h5" component="h2">
                                Registrations
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleClose}>
                                <CloseIcon fontSize="large" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead sx={{ background: "#f8f8f8" }}>
                            <TableRow>
                                <TableCell align="left">Order ID</TableCell>
                                <TableCell align="left">Child Name</TableCell>
                                <TableCell align="left">Parent Name</TableCell>
                                <TableCell align="left">Mobile Number</TableCell>
                                <TableCell align="left">Date Registered</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                        backgroundColor: index % 2 === 0 ? "#fff" : "#f8f8f8",
                                    }}
                                >

                                    <TableCell align="left">
                                        {row?.cf_order_id}
                                    </TableCell>

                                    <TableCell align="left">
                                        {row?.children?.first_name}
                                    </TableCell>

                                    <TableCell align="left">
                                        {row?.children?.father_full_name}
                                    </TableCell>

                                    <TableCell align="left">
                                        {row?.children?.user?.mobile_number}
                                    </TableCell>

                                    <TableCell align="left">
                                        {moment(row?.created_at).format("DD/MM/YYYY")}
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Modal>
    )
}

export default Registrations
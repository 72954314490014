import {
    Typography,
    Box,
    Grid,
    Button,
    TextField,
    IconButton,
    Tooltip,
    Modal,
    CircularProgress,
} from "@mui/material";
import { LocalizationProvider, DesktopDatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment/moment";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useState, useEffect, useMemo } from "react";
import { tourApi } from "../../services/api";
import { DeleteConfirmBox, DialogBox } from "../../components";


//  Oldest first accroding to tourDate 
const sortByDate = (a, b) => {
    return new Date(a?.start_date) - new Date(b?.start_date);
};

// Filtering past tours
const filterToursByStartDate = (tours) => {
    const today = moment().startOf('day'); // Get today's date without the time

    const filteredTours = tours.filter(tour => {
        const startDate = moment(tour.start_date);
        return startDate.isAfter(today);
    });

    return filteredTours?.sort(sortByDate);
}

export const AddTour = ({ open, handleClose, expId, title, duration }) => {

    // Nearest 5 minutes gap
    const initialTime = useMemo(() => {
        const now = new Date();
        const roundedMinutes = Math.round(now.getMinutes() / 5) * 5;
        return new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), roundedMinutes, 0);
    }, []);

    const [selectedStartDate, setSelectedStartDate] = useState(new Date(Date.now() + 24 * 60 * 60 * 1000));
    const [selectedStartTime, setSelectedStartTime] = useState(initialTime);
    const [selectedEndDate, setSelectedEndDate] = useState(new Date(Date.now() + 48 * 60 * 60 * 1000));
    const [selectedEndTime, setSelectedEndTime] = useState(initialTime);
    const [additionalNotes, setAdditionalNotes] = useState("");
    const [loading, setLoading] = useState(false);
    const [openDeleteConfirmBox, setOpenDeleteConfirmBox] = useState(false);
    const [tourId, setTourId] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [tours, setTours] = useState([]);

    const maxDate = useMemo(() => {
        const durationValue = parseInt(duration);
        const selectedStartTime = moment(selectedStartDate).valueOf();

        const now = moment().valueOf();
        const durationMilliseconds = durationValue * 24 * 60 * 60 * 1000;

        const endTime = selectedStartTime + durationMilliseconds;
        const maxDateTime = endTime > now ? endTime : now;
        const maxDateTimePlusOneDay = maxDateTime + 24 * 60 * 60 * 1000;

        const maxDate = moment(maxDateTimePlusOneDay);

        const roundedMinutes = Math.round(maxDate.minute() / 5) * 5;
        maxDate.minutes(roundedMinutes);

        return maxDate.toDate();
    }, [selectedStartDate, duration]);


    const handleSubmit = async () => {
        setLoading(true);
        setOpenConfirm(false);
        try {
            const response = await tourApi.createTour({
                expId,
                data: {
                    start_date: moment(selectedStartDate).format("YYYY-MM-DD") + " " + moment(selectedStartTime).format("HH:mm:ss"),
                    end_date: moment(selectedEndDate).format("YYYY-MM-DD") + " " + moment(selectedEndTime).format("HH:mm:ss"),
                    additional_note: additionalNotes
                }
            });
            if (response.data.success) {
                tourApi.getAllTours(expId).then(res => {
                    setTours(filterToursByStartDate(res?.data?.data));
                })
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    const handleDelete = async (tourId) => {
        if (!tourId) return;
        setLoading(true);
        try {
            const response = await tourApi.deleteTour({
                expId,
                data: {
                    id: tourId
                }
            });
            if (response.data.success) {
                tourApi.getAllTours(expId).then(res => {
                    setTours(filterToursByStartDate(res?.data?.data));
                })
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
            handleCloseConfirmBox();
        }
    }

    const handleCancel = () => {
        setSelectedStartDate(new Date(Date.now() + 24 * 60 * 60 * 1000));
        setSelectedStartTime(initialTime);
        setSelectedEndDate(new Date(Date.now() + 24 * 60 * 60 * 1000));
        setSelectedEndTime(initialTime);
        handleClose();
    }

    // Delete Confirm Box
    const handlOpenDeleteConfirmbox = (id) => {
        setTourId(id);
        setOpenDeleteConfirmBox(true);
    }

    const handleCloseConfirmBox = () => {
        setTourId(null);
        setOpenDeleteConfirmBox(false);
    }

    useEffect(() => {
        const durationValue = parseInt(duration);
        const startDate = moment(selectedStartDate);
        const endDate = startDate.clone().add(durationValue, 'days');
        setSelectedEndDate(endDate.toDate());
    }, [selectedStartDate, duration]);


    useEffect(() => {
        tourApi.getAllTours(expId).then(res => {
            setTours(filterToursByStartDate(res?.data?.data));
        }).catch(err => {
            console.error(err);
        })
    }, [expId])

    return (
        <>
            <Modal
                open={open}
                hideBackdrop
                sx={{
                    backgroundColor: "rgba(0,0,0,0.8)",
                }}
            >
                <Box sx={{
                    p: 2,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "100%",
                    maxWidth: "1000px",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    borderRadius: "5px",
                    maxHeight: "100%",
                    overflowY: "auto",
                }}
                >
                    <Grid container
                        sx={{
                            border: "1px solid #A6A6A6",
                            borderRadius: "3px",
                        }}
                    >
                        <Grid item md={8} xs={12}>
                            <Box
                                sx={{
                                    borderRight: "1px solid #A6A6A6",
                                    padding: "20px",
                                }}
                            >
                                <Box>
                                    <Typography variant="h5" sx={{ fontWeight: "600" }}>
                                        Add a Tour
                                    </Typography>
                                    <Typography variant="h6" align="left" sx={{ fontWeight: "600", mt: 1 }}>
                                        {title}
                                    </Typography>
                                </Box>

                                <Box sx={{ mt: 4 }}>
                                    <Grid container spacing={2}>
                                        <Grid item md={3} sm={4} xs={12} sx={{ mb: { xs: 3, sm: 0 } }}>
                                            <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
                                                <Typography variant="body">
                                                    Start Time
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item md={9} sm={8} xs={12}>
                                            <Box sx={{ display: "flex", flexWrap: { xs: "wrap", sm: "noWrap" }, gap: "10px" }}>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DesktopDatePicker
                                                        label="Select Date"
                                                        inputFormat="MMM DD,yyyy"
                                                        value={selectedStartDate}
                                                        onChange={(date) => setSelectedStartDate(date)}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        minDate={new Date(Date.now() + 24 * 60 * 60 * 1000)}
                                                        disablePast
                                                        disableMaskedInput

                                                    />
                                                    <TimePicker
                                                        label="Time"
                                                        value={selectedStartTime}
                                                        onChange={(time) => setSelectedStartTime(time)}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        minutesStep={5}
                                                    />
                                                </LocalizationProvider>
                                            </Box>
                                        </Grid>

                                        <Grid item md={3} sm={4} xs={12} sx={{ mb: { xs: 3, sm: 0 } }}>
                                            <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
                                                <Typography variant="body">
                                                    End Time
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item md={9} sm={8} xs={12}>
                                            <Box sx={{ display: "flex", flexWrap: { xs: "wrap", sm: "noWrap" }, gap: "10px" }}>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DesktopDatePicker
                                                        label="Select Date"
                                                        inputFormat="MMM DD,yyyy"
                                                        value={selectedEndDate}
                                                        onChange={(date) => setSelectedEndDate(date)}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        minDate={selectedStartDate}
                                                        maxDate={maxDate}
                                                        disablePast
                                                        disableMaskedInput

                                                    />
                                                    <TimePicker
                                                        label="Time"
                                                        value={selectedEndTime}
                                                        onChange={(time) => setSelectedEndTime(time)}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        minutesStep={5}
                                                    />
                                                </LocalizationProvider>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid container sx={{ mt: 5 }}>
                                        <Grid item md={3} sm={4} xs={12} sx={{ mb: { xs: 3, sm: 0 } }}>
                                            <Box>
                                                <Typography variant="body">
                                                    Additional Notes
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item md={9} sm={8} xs={12}>
                                            <TextField
                                                id="outlined-multiline-static"
                                                multiline
                                                rows={4}
                                                fullWidth
                                                variant="outlined"
                                                placeholder="Visible only to you"
                                                value={additionalNotes}
                                                onChange={(e) => setAdditionalNotes(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Box sx={{ display: "flex", gap: "20px", mt: 3, py: 3 }}>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: "#1ea300",
                                                color: "#fff",
                                                width: { xs: "120px", sm: "150px" },
                                                height: "40px",
                                                borderRadius: "20px",
                                                "&:hover": {
                                                    backgroundColor: "#1ea300",
                                                    boxShadow: "0 0 5px #1ea300",
                                                }

                                            }}
                                            onClick={() => setOpenConfirm(true)}
                                            disabled={loading}
                                        >
                                            {
                                                loading ? <CircularProgress size="25px" /> : "Add Tour"
                                            }
                                        </Button>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: "#787878",
                                                color: "#fff",
                                                width: { xs: "120px", sm: "150px" },
                                                height: "40px",
                                                borderRadius: "20px",
                                                "&:hover": {
                                                    backgroundColor: "#787878",
                                                    boxShadow: "0 0 5px #787878",
                                                }

                                            }}
                                            onClick={handleCancel}
                                        >
                                            Close
                                        </Button>

                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <Box
                                sx={{
                                    borderRight: "1px solid #A6A6A6",
                                    padding: "20px",
                                    maxHeight: "460px",
                                    overflowY: "scroll",
                                    "&::-webkit-scrollbar": {
                                        display: "none",
                                    },
                                }}
                            >
                                <Box>
                                    <Typography variant="h5" sx={{ fontWeight: "600" }}>
                                        Live Tours
                                    </Typography>
                                </Box>

                                <Box sx={{ py: 3 }}>
                                    {
                                        tours.length > 0 ? (tours.map((tour) => (
                                            <Box
                                                key={tour.id}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    backgroundColor: "#f2f2f2",
                                                    borderBottom: "1px solid #A6A6A6",
                                                    p: 1,
                                                    mb: 1.5,
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <Typography variant="body1">
                                                    {
                                                        moment(tour.start_date).format("MMM DD,yyyy") + " " + moment(tour.start_date).format("hh:mm A")
                                                    }
                                                </Typography>
                                                <Tooltip title="Delete">
                                                    <IconButton
                                                        color="error"
                                                        onClick={() => handlOpenDeleteConfirmbox(tour.id)}
                                                        disabled={loading}
                                                    >
                                                        <DeleteForeverOutlinedIcon />
                                                    </IconButton>
                                                </Tooltip>

                                            </Box>
                                        ))) : (
                                            <Box>
                                                <Typography variant="body1">
                                                    No tours created
                                                </Typography>
                                            </Box>
                                        )
                                    }
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            {
                openDeleteConfirmBox && (
                    <DeleteConfirmBox
                        open={openDeleteConfirmBox}
                        handleClose={handleCloseConfirmBox}
                        onProceedClick={() => handleDelete(tourId)}
                        loading={loading}
                    />
                )
            }
            {
                openConfirm && (
                    <DialogBox
                        open={openConfirm}
                        handleClose={() => setOpenConfirm(false)}
                        onProceedClick={handleSubmit}
                        title="Add Tour"
                        description={`Adding Tour on ${moment(selectedStartDate).format("MMM DD,yyyy")} ${moment(selectedStartTime).format("hh:mm A")}`}
                    />
                )
            }
        </>
    );
};

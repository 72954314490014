import {
    Box,
    AppBar,
    Tabs,
    Tab,
    Grid,
    Typography
} from "@mui/material"
import { useState } from "react"
import { TabPanel } from "../../components"
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { City } from "./City"
import { State } from "./State"
import { Country } from "./Country"

export const Locations = () => {
    const [activeTab, setActiveTab] = useState(0)

    return (
        <Box>
            <Grid container sx={{ justifyContent: "space-between", py: 2, px: 5 }}>
                <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <LocationCityIcon sx={{ fontSize: 40 }} />
                        <Typography variant='h5' component='h5' ml={1}>
                            Locations
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Box>
                <AppBar position="static">
                    <Tabs
                        value={activeTab}
                        onChange={(e, value) => setActiveTab(value)}
                        sx={{ px: 3 }}
                        textColor="inherit"
                        indicatorColor="secondary"
                    >
                        <Tab label="Country" />
                        <Tab label="State" />
                        <Tab label="City" />

                    </Tabs>
                </AppBar>

                <TabPanel value={activeTab} index={0}>
                    <Country />
                </TabPanel>

                <TabPanel value={activeTab} index={1}>
                    <State />
                </TabPanel>

                <TabPanel value={activeTab} index={2}>
                    <City />
                </TabPanel>
            </Box>
        </Box>
    )
}
import {
  Box,
  Typography,
  AppBar,
  Tabs,
  Tab,
} from "@mui/material"
import UpcommingTours from "./UpcommingTours"
import PastTours from "./PastTours"
import { useDispatch, useSelector } from "react-redux"
import { getUpcommingTours, getPastTours } from "../../services/actions/dashboardData"
import { useEffect, useState, useMemo } from "react"
import { BackdropLoading, TabPanel } from "../../components"

// 1  Newest first accroding to tourDate 
const sortByDate = (a, b) => {
  return new Date(b?.start_date) - new Date(a?.start_date);
};

export const DashboardHome = () => {
  const dispatch = useDispatch()
  const { upcommingTours, pastTours, loading, error } = useSelector((state) => state.dashboardData)
  const { user } = useSelector((state) => state.auth)

  const [activeTab, setActiveTab] = useState(0)

  const upcommingToursData = useMemo(() => {
    if (upcommingTours && upcommingTours.length > 0) {
      return upcommingTours.sort(sortByDate).filter((item) => item.experience.partner.user.id === user.user.id)
    }
    return null
  }, [upcommingTours, user])

  const pastToursData = useMemo(() => {
    if (pastTours && pastTours.length > 0) {
      return pastTours.sort(sortByDate).filter((item) => item.experience.partner.user.id === user.user.id)
    }
    return null
  }, [pastTours, user])

  useEffect(() => {
    dispatch(getUpcommingTours())
    dispatch(getPastTours())
  }, [dispatch])

  if (loading) return <BackdropLoading open={true} />
  if (error) return <Typography variant="h5" component="h5" align="center">Something went wrong</Typography>

  return (
    <Box>
      <Box sx={{ height: 72 }}>
      </Box>

      <Box>
        <AppBar position="static">
          <Tabs
            value={activeTab}
            onChange={(e, value) => setActiveTab(value)}
            sx={{ px: 3 }}
            textColor="inherit"
            indicatorColor="secondary"
          >
            <Tab label={window.innerWidth > 600 ? "Upcoming Tours" : "Upcoming"} />
            <Tab label={window.innerWidth > 600 ? "Past Tours" : "Past"} />
          </Tabs>
        </AppBar>

        {/* Upcomming Tours Tab*/}
        <TabPanel value={activeTab} index={0}>
          <Box className="upcoming" sx={{ px: 4, py: 3 }}>
            {
              upcommingToursData && upcommingToursData.length > 0 ? (
                <UpcommingTours data={upcommingToursData} />
              ) : (
                <Typography variant="h5" component="h5" align="center">
                  No Upcoming Tours
                </Typography>
              )
            }
          </Box>
        </TabPanel>

        {/* Past Tours   Tab*/}
        <TabPanel value={activeTab} index={1}>
          <Box className="past" sx={{ px: 4, py: 3 }}>
            {
              pastToursData && pastToursData.length > 0 ? (
                <PastTours data={pastToursData} />
              ) : (
                <Typography variant="h5" component="h5" align="center">
                  No Past Tours
                </Typography>
              )
            }
          </Box>
        </TabPanel>
      </Box>
    </Box>
  )
}
import React, { useState } from "react";
import {
    Table as MUITable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Collapse,
} from "@mui/material";
import moment from "moment";

const Table = ({ data }) => {
    const [expandedRow, setExpandedRow] = useState(null);

    const handleRowClick = (rowId) => {
        setExpandedRow(rowId === expandedRow ? null : rowId);
    };

    return (
        <TableContainer component={Paper}>
            <MUITable>
                <TableHead sx={{ background: "#f8f8f8" }}>
                    <TableRow>
                        <TableCell align="left">School Name</TableCell>
                        <TableCell align="left">Contact Name</TableCell>
                        <TableCell align="left">Email</TableCell>
                        <TableCell align="left">Contact Number</TableCell>
                        <TableCell align="left">Alternate Number</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <React.Fragment key={row.id}>
                            <TableRow
                                sx={{ cursor: "pointer" }}
                                onClick={() => handleRowClick(row.id)}
                            >
                                <TableCell align="left">{row.school_name}</TableCell>
                                <TableCell align="left">{row.contact_name}</TableCell>
                                <TableCell align="left">{row.email}</TableCell>
                                <TableCell align="left">{row.contact_number}</TableCell>
                                <TableCell align="left">{row.alternate_number}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={6}>
                                    <Collapse
                                        in={row.id === expandedRow}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <TableContainer component={Paper}>
                                            <MUITable>
                                                <TableHead sx={{ background: "#f8f8f8" }}>
                                                    <TableRow>
                                                        <TableCell align="left">Batch Size</TableCell>
                                                        <TableCell align="left">Start Date</TableCell>
                                                        <TableCell align="left">End Date</TableCell>
                                                        <TableCell align="left">Experience Name</TableCell>
                                                        <TableCell align="left">Partner</TableCell>
                                                        <TableCell align="left">Created at</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell align="left">{row.batch_size}</TableCell>
                                                        <TableCell align="left">
                                                            {moment(row.start_date).format("DD/MM/YYYY")}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {moment(row.end_date).format("DD/MM/YYYY")}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {row.experience.title}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {row.experience.partner.name}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {moment(row.created_at).format("DD/MM/YYYY")}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </MUITable>
                                        </TableContainer>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </MUITable>
        </TableContainer>
    );
};

export default Table;

import {
    Table as MUITable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Switch,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useState, useEffect } from "react";
import { getBanners } from "../../services/actions/banners"
import { useDispatch } from "react-redux";
import { bannersApi } from "../../services/api";

const Table = ({ data, handleEdit }) => {
    const dispatch = useDispatch()
    const [tableData, setTableData] = useState([]);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const data = Array.from(tableData);
        const [reorderedRow] = data.splice(result.source.index, 1);
        data.splice(result.destination.index, 0, reorderedRow);
        const updatedData = data.map((row, index) => ({ ...row, index }));
        setTableData(updatedData);
    };

    const handleStatusChange = async (data) => {
        if (!data || !data.id) return
        try {
            const response = await bannersApi.getBanner(data.id)
            if (response.data.success) {
                const updateResponse = await bannersApi.updateBanner({
                    id: data.id,
                    name: response?.data?.data?.name,
                    experience_id: response?.data?.data?.experience?.id,
                    priority: response?.data?.data?.priority,
                    image_url: response?.data?.data?.image_url,
                    external_url: response?.data?.data?.external_url,
                    status: !data.status
                })
                if (updateResponse.data.success) {
                    dispatch(getBanners())
                } else {
                    throw new Error(response.data.message)
                }
            } else {
                throw new Error(response.data.message)
            }
        } catch (error) {
            console.error(error);
        }
    }


    useEffect(() => {
        setTableData(data.map((row, index) => ({ ...row, index })));
    }, [data])

    return (
        <TableContainer component={Paper}>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <MUITable>
                    <TableHead sx={{ background: "#f8f8f8" }}>
                        <TableRow>
                            <TableCell align="left">Priority</TableCell>
                            <TableCell align="left">Banner Name</TableCell>
                            <TableCell align="left">Tagged experience title</TableCell>
                            <TableCell align="left">Uploaded on</TableCell>
                            <TableCell align="left">Banner link</TableCell>
                            <TableCell align="left">Status</TableCell>
                            <TableCell align="left">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <Droppable droppableId="tableData">
                        {(provided) => (
                            <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                                {tableData.map((row, index) => (
                                    <Draggable key={index} draggableId={index.toString()} index={index}>
                                        {(provided) => (
                                            <TableRow
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                sx={{
                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                    backgroundColor: index % 2 === 0 ? "#fff" : "#f8f8f8",
                                                }}
                                            >
                                                <TableCell align="left">
                                                    {row?.priority}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row?.name}
                                                </TableCell>

                                                <TableCell align="left">
                                                    {row?.experience?.title}
                                                </TableCell>

                                                <TableCell align="left">
                                                    {moment(row?.created_at).format("DD/MM/YYYY")}
                                                </TableCell>

                                                <TableCell
                                                    align="left"
                                                    onClick={() => {
                                                        window.open(row?.image_url, "_blank")
                                                    }}
                                                    sx={{color:"blue", cursor:"pointer"}}
                                                >
                                                    View Banner
                                                </TableCell>

                                                <TableCell align="left">
                                                    {row?.status ? "Active" : "Inactive"}
                                                    <Switch checked={row?.status} onChange={() => handleStatusChange(row)} />
                                                </TableCell>

                                                <TableCell align="left">
                                                    <Button
                                                        variant="outlined"
                                                        endIcon={<EditIcon />}
                                                        sx={{ width: "100px" }}
                                                        onClick={() => handleEdit(row?.id)}
                                                    >
                                                        Edit
                                                    </Button>

                                                </TableCell>

                                            </TableRow>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </TableBody>
                        )}
                    </Droppable>

                </MUITable>
            </DragDropContext>
        </TableContainer>
    )
}


export default Table
import {
    Typography,
    Box,
    Modal,
    Container,
    Button,
    TextField,
    Grid,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    Input,
    Chip,
    CircularProgress,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { collectionsApi } from "../../services/api";
import { getCollections } from "../../services/actions/collections"

const AddNew = ({ open, handleClose, action, id }) => {
    const dispatch = useDispatch()
    const { experiences } = useSelector(state => state.experiences)
    const [loading, setLoading] = useState(false)

    const [data, setData] = useState({
        name: "",
        experiences: [],
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSave = async () => {
        setLoading(true)
        try {
            const response = action === "edit" ? await collectionsApi.updateCollection({ ...data, priority: parseInt(data.priority) }) : await collectionsApi.createCollection({ ...data, priority: parseInt(data.priority) })
            if (response.data.success) {
                dispatch(getCollections())
                handleClose();
            } else {
                throw new Error(response.data.message)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }
    }

    const getData = async () => {
        try {
            const response = await collectionsApi.getCollection(id)
            if (response.data.success) {
                setData({
                    id,
                    name: response.data.data[0].collection.name,
                    experiences: response.data.data.map((data) => {
                        return data.experience.id
                    }),
                    priority: response.data.data[0].collection.priority,
                    status: response.data.data[0].collection.status
                })
            } else {
                throw new Error(response.data.message)
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (action === "edit") {
            getData();
        } else {
            setData({
                name: "",
                experiences: [],
                priority: 1
            })
        }
        // eslint-disable-next-line
    }, [open])

    return (
        <Modal open={open}>
            <Box sx={{
                p: 2,
                pb: 4,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "100%",
                maxWidth: "600px",
                bgcolor: "background.paper",
                boxShadow: 24,
                borderRadius: "5px",
            }}
            >
                <Container>
                    <Grid container sx={{ justifyContent: "space-between", }}>
                        <Grid item>
                            <Typography variant='h5' component='h5' ml={1}>
                                {
                                    action === "edit" ? "Edit Collection" : "Add New Collection"
                                }
                            </Typography>

                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Box sx={{ px: 2 }}>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label="Priority"
                                name="priority"
                                value={data.priority || ""}
                                required
                                variant="standard"
                                onChange={handleChange}
                                inputProps={{
                                    type: "number",
                                }}
                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label="Collection Name"
                                name="name"
                                value={data.name}
                                required
                                variant="standard"
                                onChange={handleChange}
                            />
                        </Box>

                        <Box sx={{ mt: 4 }}>
                            <FormControl fullWidth required>
                                <InputLabel>Select Experiences</InputLabel>
                                <Select
                                    name="experiences"
                                    multiple
                                    value={data.experiences}
                                    onChange={handleChange}
                                    input={<Input />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((id) => (
                                                <Chip
                                                    key={id}
                                                    label={experiences.find((exp) => exp.id === id)?.title}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {experiences?.filter(item=>item?.status)?.map((exp) => (
                                        <MenuItem key={exp.id} value={exp.id}>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <Checkbox checked={data.experiences?.indexOf(exp.id) > -1} />
                                                <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                    <img
                                                        src={exp.cover_photo}
                                                        style={{
                                                            width: "25px",
                                                            height: "25px",
                                                            borderRadius: "50%",
                                                            objectFit: "cover"
                                                        }}
                                                        alt={exp.title}
                                                    />
                                                    <ListItemText primary={exp.title} />
                                                    {
                                                        exp.partner.name && (
                                                            <ListItemText secondary={`By ${exp.partner.name}`} />
                                                        )
                                                    }
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <Box sx={{ mt: 4 }}>
                            <Button
                                fullWidth
                                size="large"
                                variant="contained"
                                onClick={handleSave}
                                disabled={data.name === "" || data.experiences?.length === 0 || loading}
                            >
                                {
                                    loading ? (
                                        <CircularProgress size={24} />
                                    ) : (
                                        action === "edit" ? "Update" : "Add"
                                    )
                                }
                            </Button>
                        </Box>

                    </Box>
                </Container>

            </Box>
        </Modal>
    )
}

export default AddNew
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    Box,
    Typography,
    FormHelperText
} from "@mui/material";

const Editor = ({ onChange, value, placeholder, error, lable, min, max, required, disabled }) => {

    //remove html tags from string
    const stripHtml = () => {
        const regex = /(<([^>]+)>)/ig
        const strippedString = value?.replace(regex, '')
        return strippedString?.length
    }

    return (
        <Box>
            <Typography paragraph>
                {required ? `${lable} *` : lable}
            </Typography>
            <CKEditor
                editor={ClassicEditor}
                data={value}
                config={{
                    height: 200,
                    removePlugins: ['MediaEmbed'],
                    placeholder: required ? `${placeholder} *` : placeholder,
                    toolbar: [
                        '|',
                        'bold',
                        'italic',
                        'link',
                        'bulletedList',
                        'numberedList',
                        'blockQuote',
                        'insertTable',
                        'undo',
                        'redo'
                    ],
                }}
                disabled={disabled}
                onChange={(event, editor) => {
                    const data = editor.getData()
                    onChange(data)
                }}
            />
            {error ? (
                <Typography color="error">
                    Length should be between {min} - {max} Characters
                </Typography>
            ) : (
                min ? (
                    <FormHelperText>Min {min} Chars. - {stripHtml()}/{max} Max *</FormHelperText>
                ) : (
                    <FormHelperText>{stripHtml()}/{max} Max</FormHelperText>
                )
            )}
        </Box>
    );
}

export default Editor;

import {
    GET_TRANSACTIONS,
    GET_TRANSACTIONS_SUCCESS,
    GETTRANSACTIONSS_FAILURE
} from '../actions/transaction';

const initialState = {
    transactions: null,
    loading: false,
    error: null
}

export const transaction = (data = initialState, action) => {
    switch (action.type) {
        case GET_TRANSACTIONS:
            return {
                ...data,
                loading: true,
                error: null
            }

        case GET_TRANSACTIONS_SUCCESS:
            return {
                ...data,
                loading: false,
                transactions: action.payload
            }

        case GETTRANSACTIONSS_FAILURE:
            return {
                ...data,
                loading: false,
                error: action.payload,
            }

        default:
            return data
    }
}
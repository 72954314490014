import {
    Table as MUITable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    useTheme,
    useMediaQuery
} from "@mui/material";
import moment from "moment";


const PastTours = ({ data }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <TableContainer component={Paper}>
            <MUITable sx={{ minWidth: isMobile ? "100%" : 650, overflow: "hidden" }}>
                <TableHead sx={{ background: "#f8f8f8" }}>
                    <TableRow>
                        <TableCell align="left">Number</TableCell>
                        <TableCell align="left">Date of tour</TableCell>
                        <TableCell align="left">Experience name</TableCell>
                        <TableCell align="left">Registrations</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((row, index) => (
                        <TableRow
                            key={index}
                            sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                                backgroundColor: index % 2 === 0 ? "#fff" : "#f8f8f8",
                            }}
                        >
                            <TableCell align="left">{index + 1}</TableCell>
                            <TableCell align="left">{moment(row?.start_date).format("DD/MM/YYYY")}</TableCell>
                            <TableCell align="left">{row?.experience?.title}</TableCell>
                            <TableCell align="left">{row?.registration?.length}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </MUITable>
        </TableContainer>
    )
}

export default PastTours
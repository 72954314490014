import {
    GET_CITIES,
    GET_CITIES_SUCCESS,
    GET_CITIES_FAILURE
} from '../actions/cities';

const initialState = {
    cities: [],
    loading: false,
    error: null
}

export const cities = (data = initialState, action) => {
    switch (action.type) {
        case GET_CITIES:
            return {
                ...data,
                loading: true,
                error: null
            }

        case GET_CITIES_SUCCESS:
            return {
                ...data,
                loading: false,
                cities: action.payload
            }

        case GET_CITIES_FAILURE:
            return {
                ...data,
                loading: false,
                error: action.payload,
            }

        default:
            return data
    }
}
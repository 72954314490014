import { BackdropLoading, Table } from "../../components"
import { Box, Typography, Grid, Button } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getHobbies } from "../../services/actions/hobby"
import DownhillSkiingIcon from '@mui/icons-material/DownhillSkiing';
import { hobbiesApi } from "../../services/api"
import { AddNewModal, DeleteConfirmBox } from "../../components"

export const Hobby = () => {
    const dispatch = useDispatch()
    const { hobbies, loading, error } = useSelector(state => state.hobbies)

    const [openAddNew, setOpenAddNew] = useState(false)
    const [hobby, setHobby] = useState("")
    const [addNewLoading, setAddNewLoading] = useState(false)
    const [action, setAction] = useState("add")
    const [hobbyId, setHobbyId] = useState(null)
    const [openDeleteConf, setOpenDeleteConf] = useState(false)

    const handleOpenModal = () => {
        setOpenAddNew(true)
    }

    const handleCloseModal = () => {
        setOpenAddNew(false)
        setHobby("")
        setAction("add")
        setHobbyId(null)
    }

    const handleOpenDeleteConf = () => {
        setOpenDeleteConf(true)
    }

    const handleCloseDeleteConf = () => {
        setOpenDeleteConf(false)
        setHobbyId(null)
    }

    const handleSubmit = async () => {
        if (hobby.trim() === "") return
        setAddNewLoading(true)
        try {
            const response = await hobbiesApi.createHobby({
                name: hobby
            })
            if (response.data.success) {
                dispatch(getHobbies())
                handleCloseModal()
            }
            return
        } catch (error) {
            console.error(error)
        } finally {
            setAddNewLoading(false)
        }
    }

    const handleEditModal = (id) => {
        setAction("edit")
        handleOpenModal()
        setHobby(hobbies.filter(hobby => hobby.id === id)[0].name)
        setHobbyId(id)
    }

    const handleEditSubmit = async (hobbyId) => {
        if (hobby.trim() === "") return
        if (!hobby) return
        setAddNewLoading(true)
        try {
            const response = await hobbiesApi.updateHobby({
                id: hobbyId,
                name: hobby
            })
            if (response.data.success) {
                dispatch(getHobbies())
                handleCloseModal()
                setAction("add")
                setHobbyId(null)
            }
            return
        } catch (error) {
            console.error(error)
        } finally {
            setAddNewLoading(false)
        }

    }

    const handleDelete = async (id) => {
        if (!id) return
        try {
            const response = await hobbiesApi.deleteHobby({
                id
            })
            if (response.data.success) {
                dispatch(getHobbies())
            }
        } catch (error) {
            console.error(error)
        } finally {
            handleCloseDeleteConf()
        }
    }

    const handleDeleteConfrim = (id) => {
        handleOpenDeleteConf()
        setHobbyId(id)
    }

    useEffect(() => {
        dispatch(getHobbies())
    }, [dispatch])

    if (loading) return <BackdropLoading open={true} />
    if (error) return <div>Error</div>

    return (
        <Box>
            <Grid container sx={{ justifyContent: "space-between", py: 2, px: 5 }}>
                <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <DownhillSkiingIcon sx={{ fontSize: 40 }} />
                        <Typography variant='h5' component='h5' ml={1}>
                            Hobbies
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <Button
                            variant='contained'
                            onClick={handleOpenModal}
                        >
                            Add New
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ px: 2, py: 3 }}>
                {
                    hobbies && hobbies.length > 0 ? (
                        <Table
                            data={hobbies}
                            id="hobby"
                            handleEdit={handleEditModal}
                            handleDelete={handleDeleteConfrim}
                        />
                    ) : (
                        <Typography variant="h5" component="h5" align="center">
                            No hobbies found
                        </Typography>
                    )
                }
            </Box>

            <AddNewModal
                open={openAddNew}
                handleClose={handleCloseModal}
                label="Hobby Name"
                placeholder="Enter hobby name"
                handleSubmit={
                    action === "add" ? handleSubmit : () => handleEditSubmit(hobbyId)
                }
                value={hobby}
                setValue={setHobby}
                loading={addNewLoading}
                action={action}
            />

            <DeleteConfirmBox
                open={openDeleteConf}
                handleClose={handleCloseDeleteConf}
                onProceedClick={() => handleDelete(hobbyId)}
            />
        </Box>
    )
}
import { useEffect } from "react";

import {
  Typography,
  Box,
  Grid,
  Button,
  Container
} from "@mui/material";
import TourIcon from "@mui/icons-material/Tour";
import { useNavigate } from "react-router-dom";
import { ExpCard } from "./expCard";
import { useDispatch, useSelector } from "react-redux";
import { getExperiences } from "../../services/actions/experiences"
import { BackdropLoading } from "../../components";

export const Experiences = () => {

  const dispatch = useDispatch();
  const { experiences, error, loading } = useSelector(state => state.experiences);
  const { partner } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getExperiences())
  }, [dispatch])

  const navigate = useNavigate();

  const sortByDate = (a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  };

  if (loading) return <BackdropLoading open={true} />
  if (error) return <h1>Error</h1>

  return (
    <Box>
      <Grid container sx={{ justifyContent: "space-between", py: 2, px: { md: 5, sm: 1 } }}>
        <Grid item>
          <Box sx={{ display: "flex" }}>
            <Typography variant='h5' component='h5' mr={1}>
              <TourIcon sx={{ fontSize: 30 }} />
            </Typography>
            <Typography variant='h5' component='h5'>
              Experiences
            </Typography>
          </Box>
        </Grid>

        <Grid item>
          <Box>
            <Button
              variant='contained'
              onClick={() => navigate("/dashboard/experiences/create")}
            >
              Create
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Container>
        {
          experiences && experiences.length > 0 ? (
            experiences.sort(sortByDate).filter(item => item?.partner?.id === partner?.id).map((exp) => (
              <ExpCard key={exp.id} exp={exp} />
            ))
          ) : (
            <Typography variant='h5' component='h5'>
              No Experiences created
            </Typography>
          )
        }
      </Container>
    </Box>
  );
};

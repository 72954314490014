import { combineReducers } from 'redux';
import auth from './auth';
import experiences from './experiences';
import subjects from './subjects';
import { countries } from './countries';
import { hobbies } from './hobby';
import { cities } from './cities';
import { states } from './states';
import { grades } from './grades';
import { roles } from './roles';
import { schools } from './schools';
import { partners } from './partner';
import { collections } from './collections';
import { callbacks } from './callbacks';
import { dashboardData } from './dashboardData';
import { experienceTypes } from './experienceTypes';
import { destinationTypes } from './destinationTypes';
import { schoolTrips } from './schoolTrip';
import { banners } from './banners';
import { transaction } from './transaction';

export const rootReducer = combineReducers({
    auth,
    experiences,
    subjects,
    countries,
    hobbies,
    cities,
    states,
    grades,
    roles,
    schools,
    partners,
    collections,
    callbacks,
    dashboardData,
    experienceTypes,
    destinationTypes,
    schoolTrips,
    banners,
    transaction
});
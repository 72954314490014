// ALL EXPERIENCES
export const GET_EXPERIENCES = 'GET_EXPERIENCES';
export const GET_EXPERIENCES_SUCCESS = 'GET_EXPERIENCES_SUCCESS';
export const GET_EXPERIENCES_FAILURE = 'GET_EXPERIENCES_FAILURE';

export const getExperiences = () => ({
    type: GET_EXPERIENCES,
});

// ONE EXPERIENCE
export const GET_EXPERIENCE = 'GET_EXPERIENCE';
export const GET_EXPERIENCE_SUCCESS = 'GET_EXPERIENCE_SUCCESS';
export const GET_EXPERIENCE_FAILURE = 'GET_EXPERIENCE_FAILURE';

// payload id
export const getExperience = (id) => ({
    type: GET_EXPERIENCE,
    id,
});

// ONE EXPERIENCE
export const CREATE_EXPERIENCE = 'CREATE_EXPERIENCE';
export const CREATE_EXPERIENCE_SUCCESS = 'CREATE_EXPERIENCE_SUCCESS';
export const CREATE_EXPERIENCE_FAILURE = 'CREATE_EXPERIENCE_FAILURE';

export const createExperience = (data) => ({
    type: CREATE_EXPERIENCE,
    data
});

// EDIT EXPERIENCE
export const EDIT_EXPERIENCE = 'EDIT_EXPERIENCE';
export const EDIT_EXPERIENCE_SUCCESS = 'EDIT_EXPERIENCE_SUCCESS';
export const EDIT_EXPERIENCE_FAILURE = 'EDIT_EXPERIENCE_FAILURE';

// payload id
export const editExperience = (id, data) => ({
    type: EDIT_EXPERIENCE,
    id,
    data
});

// EXPERIENCE
export const DELETE_EXPERIENCE = 'DELETE_EXPERIENCE';
export const DELETE_EXPERIENCE_SUCCESS = 'DELETE_EXPERIENCE_SUCCESS';
export const DELETE_EXPERIENCE_FAILURE = 'DELETE_EXPERIENCE_FAILURE';

// payload id
export const deleteExperience = (id) => ({
    type: DELETE_EXPERIENCE,
    id,
});
import { BackdropLoading, Table } from "../../components"
import { Box, Typography, Grid, Button } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getExperienceTypes } from "../../services/actions/experienceTypes";
import TypeSpecimenIcon from '@mui/icons-material/TypeSpecimen';
import { AddNewModal, DeleteConfirmBox } from "../../components"
import { experienceTypesApi } from "../../services/api"

export const ExperienceTypes = () => {
    const dispatch = useDispatch()
    const { experienceTypes, loading, error } = useSelector(state => state.experienceTypes)

    const [openAddNew, setOpenAddNew] = useState(false)
    const [expType, setExpType] = useState("")
    const [addNewLoading, setAddNewLoading] = useState(false)
    const [action, setAction] = useState("add")
    const [expTypeId, setExpTypeId] = useState(null)
    const [openDeleteConf, setOpenDeleteConf] = useState(false)

    const handleOpenModal = () => {
        setOpenAddNew(true)
    }

    const handleCloseModal = () => {
        setOpenAddNew(false)
        setExpType("")
        setAction("add")
        setExpTypeId(null)
    }

    const handleOpenDeleteConf = () => {
        setOpenDeleteConf(true)
    }

    const handleCloseDeleteConf = () => {
        setOpenDeleteConf(false)
        setExpTypeId(null)
    }

    const handleSubmit = async () => {
        if (!expType || expType.trim() === "") return
        setAddNewLoading(true)
        try {
            const response = await experienceTypesApi.createExperienceType({
                name: expType
            })
            if (response.data.success) {
                dispatch(getExperienceTypes())
                handleCloseModal()
            }
            return
        } catch (error) {
            console.error(error)
        } finally {
            setAddNewLoading(false)
        }
    }

    const handleEditModal = (id) => {
        setAction("edit")
        handleOpenModal()
        setExpType(experienceTypes.filter(item => item.id === id)[0].name)
        setExpTypeId(id)
    }

    const handleEditSubmit = async (expTypeId) => {
        if (!expType || expType.trim() === "") return
        setAddNewLoading(true)
        try {
            const response = await experienceTypesApi.updateExperienceType({
                id: expTypeId,
                name: expType
            })
            if (response.data.success) {
                dispatch(getExperienceTypes())
                handleCloseModal()
                setAction("add")
                setExpTypeId(null)
            }
            return
        } catch (error) {
            console.error(error)
        } finally {
            setAddNewLoading(false)
        }

    }

    const handleDelete = async (id) => {
        if (!id) return
        try {
            const response = await experienceTypesApi.deleteExperienceType({
                id: id,
            })
            if (response.data.success) {
                dispatch(getExperienceTypes())
            }
        } finally {
            handleCloseDeleteConf()
        }
    }

    const handleDeleteConfrim = (id) => {
        handleOpenDeleteConf()
        setExpTypeId(id)
    }

    useEffect(() => {
        dispatch(getExperienceTypes())
    }, [dispatch])

    if (loading) return <BackdropLoading open={true} />
    if (error) return <div>Error</div>

    return (
        <Box>
            <Grid container sx={{ justifyContent: "space-between", py: 2, px: 5 }}>
                <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <TypeSpecimenIcon sx={{ fontSize: 40 }} />
                        <Typography variant='h5' component='h5' ml={1}>
                            Experience Types
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <Button
                            variant='contained'
                            onClick={handleOpenModal}
                        >
                            Add New
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ px: 2, py: 3 }}>
                {
                    experienceTypes && experienceTypes.length > 0 ? (
                        <Table
                            data={experienceTypes}
                            id="expType"
                            handleEdit={handleEditModal}
                            handleDelete={handleDeleteConfrim}
                        />
                    ) : (
                        <Typography variant="h5" component="h5" align="center">
                            No Experience Types found
                        </Typography>
                    )
                }
            </Box>

            <AddNewModal
                open={openAddNew}
                handleClose={handleCloseModal}
                label="Experience Type"
                placeholder="Enter Experience Type"
                handleSubmit={
                    action === "add" ? handleSubmit : () => handleEditSubmit(expTypeId)
                }
                value={expType}
                setValue={setExpType}
                loading={addNewLoading}
                action={action}
                id="expType"
            />

            <DeleteConfirmBox
                open={openDeleteConf}
                handleClose={handleCloseDeleteConf}
                onProceedClick={() => handleDelete(expTypeId)}
            />
        </Box>
    )
}
import {
    GET_CITIES,
    GET_CITIES_SUCCESS,
    GET_CITIES_FAILURE,
} from '../actions/cities';

import { citiesApi } from '../api';

import { call, put, takeEvery } from 'redux-saga/effects';

function* getCities() {
    try {
        const response = yield call(citiesApi.getAllCities);
        yield put({ type: GET_CITIES_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_CITIES_FAILURE, payload: error });
    }
}

export default function* citiesSaga() {
    yield takeEvery(GET_CITIES, getCities);
}

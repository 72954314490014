import { all } from 'redux-saga/effects';
import authSaga from "./auth"
import subjectsSaga from './subjects';
import experienceSaga from './experience';
import countriesSaga from './countries';
import hobbiesSaga from './hobby';
import citiesSaga from './cities';
import gradesSaga from './grades';
import partnersSaga from './partner';
import rolesSaga from './roles';
import schoolsSaga from './schools';
import statesSaga from './states';
import collectionsSaga from './collections';
import callbacksSaga from './callbacks';
import dashboardDataSaga from './dashboardData';
import experienceTypesSaga from './experienceTypes';
import destinationTypesSaga from './destinationTypes';
import schoolTripsSaga from './schoolTrips'
import bannersSaga from './banners'
import transactionSaga from './transaction'

export function* rootSaga() {
    yield all([
        authSaga(),
        subjectsSaga(),
        experienceSaga(),
        countriesSaga(),
        hobbiesSaga(),
        citiesSaga(),
        gradesSaga(),
        partnersSaga(),
        rolesSaga(),
        schoolsSaga(),
        statesSaga(),
        collectionsSaga(),
        callbacksSaga(),
        dashboardDataSaga(),
        experienceTypesSaga(),
        destinationTypesSaga(),
        schoolTripsSaga(),
        bannersSaga(),
        transactionSaga()
    ]);
}
 const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tab-pannel-${index}`}
            aria-labelledby={`tab-pannel-${index}`}
            {...other}
        >
            {value === index && (
                children
            )}
        </div>
    );
}

export default TabPanel;
import { useState } from "react";
import {
    Box,
    TextField,
    Grid,
    Button,
    Typography,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    InputAdornment
} from "@mui/material";

import { mediaApi, partnersApi } from "../../services/api";
import { ImageCropper, DialogBox, ConfirmBox, Editor } from "../../components";
import { useDispatch } from "react-redux";
import { updatePartner } from "../../services/actions/auth";
import { Twitter, Facebook, Instagram } from "@mui/icons-material";

const ProfileDetails = ({ cities, profileDetails, setProfileDetails }) => {
    const [edit, setEdit] = useState(false);
    const dispatch = useDispatch();
    const [imageUploading, setImageUploading] = useState(false);
    const [dialogBox, setDialogBox] = useState(false)
    const [confirmBox, setConfirmBox] = useState({
        open: false,
        type: "",
        message: "",
    })
    const [error, setError] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileDetails({ ...profileDetails, [name]: value });
    };

    const handleEdit = () => {
        setEdit(true);
    };

    const handleCancel = () => {
        setEdit(false);
    };

    const validateUrl = (url) => {
        const regex = /https:\/\/(www\.)?(instagram|facebook|twitter)\.com\/[\w.-]+\/?/;
        return regex.test(url);
    };


    const handleSave = async () => {
        setDialogBox(false)
        if (
            !profileDetails.name ||
            !profileDetails.highlight ||
            !profileDetails.email ||
            !profileDetails.city_id ||
            profileDetails.description.length < 100 ||
            profileDetails.description.length > 1500
        ) {
            setError(true);
            return;
        }

        if (profileDetails.facebook_link && !validateUrl(profileDetails.facebook_link)) {
            setError(true);
            return;
        }

        if (profileDetails.instagram_link && !validateUrl(profileDetails.instagram_link)) {
            setError(true);
            return;
        }

        if (profileDetails.twitter_link && !validateUrl(profileDetails.twitter_link)) {
            setError(true);
            return;
        }

        setError(false);
        setEdit(false);
        setDialogBox(false)
        const { data } = await partnersApi.updatePartner(profileDetails);
        if (data.success) {
            setConfirmBox({
                open: true,
                type: "success",
                message: "Profile edited successfully",
            })
            dispatch(updatePartner(profileDetails.id));
        } else {
            setConfirmBox({
                open: true,
                type: "error",
                message: "Something went wrong. Try again!",
            })
        }
    };

    const handleProfileImgUpload = async (image) => {
        try {
            setImageUploading(true);
            const formData = new FormData();
            formData.append("media", image);
            const response = await mediaApi.uploadImage(formData);

            if (response.data.success) {
                setProfileDetails({
                    ...profileDetails,
                    profile_photo: response.data.data.url,
                });
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setImageUploading(false);
        }
    };

    const handleCoverImgUpload = async (image) => {
        try {
            setImageUploading(true);
            const formData = new FormData();
            formData.append("media", image);
            const response = await mediaApi.uploadImage(formData);

            if (response.data.success) {
                setProfileDetails({
                    ...profileDetails,
                    cover_photo: response.data.data.url,
                });
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setImageUploading(false);
        }
    };

    return (
        <Box sx={{ pt: 1 }}>
            <Grid container spacing={5} sx={{ flexDirection: "column" }}>
                <Grid item>
                    <Grid container spacing={20}>
                        <Grid item>
                            <Typography paragraph>Profile Photo</Typography>

                            <Box
                                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                            >
                                <img
                                    src={profileDetails.profile_photo}
                                    alt={profileDetails.name}
                                    style={{
                                        width: "120px",
                                        aspectRatio: "1/1",
                                        objectFit: "cover",
                                        borderRadius: "50%",
                                    }}
                                />
                                {edit && (
                                    <ImageCropper
                                        text="Profile"
                                        btnWidth={120}
                                        handleSubmit={handleProfileImgUpload}
                                        loading={imageUploading}
                                        aspect={1}
                                    />
                                )}
                            </Box>
                        </Grid>

                        <Grid item sx={{
                            '&.MuiGrid-item': {
                                paddingTop: { md: "160px", xs: "20px" }
                            },
                        }}>
                            <Typography paragraph>Cover Photo</Typography>

                            <Box
                                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                            >
                                <Box sx={{ height: "120px" }}>
                                    <img
                                        src={profileDetails.cover_photo}
                                        alt={profileDetails.name}
                                        style={{
                                            height: "100%",
                                            aspectRatio: "21/9",
                                            objectFit: "cover",
                                        }}
                                    />
                                </Box>
                                {edit && (
                                    <ImageCropper
                                        text="Cover"
                                        btnWidth={120}
                                        handleSubmit={handleCoverImgUpload}
                                        loading={imageUploading}
                                        aspect={21}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Box sx={{ display: "flex", flexWrap: { sm: "nowrap", xs: "wrap" }, gap: "30px", mt: 3, width: { md: "60%", sm: "100%" } }}>
                <TextField
                    id="name"
                    label="Name of Org"
                    placeholder="Name of Org"
                    variant="standard"
                    required
                    value={profileDetails.name}
                    onChange={handleChange}
                    name="name"
                    disabled={!edit}
                    helperText="3-30 characters"
                    fullWidth
                    error={error && !profileDetails.name}
                    inputProps={{
                        maxLength: 30,
                    }}
                />
                <TextField
                    id="highlight"
                    label="Highlights"
                    placeholder="Highlights"
                    variant="standard"
                    required
                    value={profileDetails.highlight}
                    onChange={handleChange}
                    name="highlight"
                    disabled={!edit}
                    helperText="10-300 characters"
                    fullWidth
                    multiline
                    error={error && !profileDetails.highlight}
                    inputProps={{
                        maxLength: 300,
                    }}
                />
            </Box>

            <Box sx={{ display: "flex", flexWrap: { sm: "nowrap", xs: "wrap" }, gap: "30px", mt: 3, width: { md: "60%", sm: "100%" } }}>
                <TextField
                    id="email"
                    label="Email Id"
                    placeholder="Email Id"
                    variant="standard"
                    required
                    value={profileDetails.email}
                    onChange={handleChange}
                    name="email"
                    disabled={!edit}
                    fullWidth
                    error={error && !profileDetails.email}
                />
                <FormControl fullWidth required variant="standard">
                    <InputLabel>City</InputLabel>
                    <Select
                        value={profileDetails.city_id}
                        label="City"
                        onChange={handleChange}
                        name="city_id"
                        disabled={!edit}
                        error={error && !profileDetails.city_id}
                        renderValue={(value) => {
                            const city = cities?.find((city) => city?.id === value);
                            return city?.name;
                        }}
                    >
                        {cities?.map((city) => (
                            <MenuItem key={city?.id} value={city?.id}>
                                {city?.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <Box sx={{ display: "flex", flexWrap: { sm: "nowrap", xs: "wrap" }, gap: "30px", mt: 3, width: { md: "60%", sm: "100%" } }}>
                <TextField
                    id="facebook_link"
                    label="Facebook Link"
                    placeholder="Facebook Link"
                    variant="standard"
                    value={profileDetails.facebook_link || ""}
                    onChange={handleChange}
                    name="facebook_link"
                    disabled={!edit}
                    fullWidth
                    error={
                        error && profileDetails.facebook_link && !validateUrl(profileDetails.facebook_link)
                    }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Facebook color="primary" />
                            </InputAdornment>
                        ),
                    }}
                    helperText={
                        error && profileDetails.facebook_link && !validateUrl(profileDetails.facebook_link) ?
                            "Please enter a valid facebook link" : "Optional"
                    }
                />
                <TextField
                    id="instagram_link"
                    label="Instagram Link"
                    placeholder="Instagram Link"
                    variant="standard"
                    value={profileDetails.instagram_link || ""}
                    onChange={handleChange}
                    name="instagram_link"
                    disabled={!edit}
                    fullWidth
                    error={
                        error && profileDetails.instagram_link && !validateUrl(profileDetails.instagram_link)
                    }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Instagram color="error" />
                            </InputAdornment>
                        ),
                    }}
                    helperText={
                        error && profileDetails.instagram_link && !validateUrl(profileDetails.instagram_link) ?
                            "Please enter a valid instagram link" : "Optional"
                    }
                />
                <TextField
                    id="twitter_link"
                    label="Twitter Link"
                    placeholder="Twitter Link"
                    variant="standard"
                    value={profileDetails.twitter_link || ""}
                    onChange={handleChange}
                    name="twitter_link"
                    disabled={!edit}
                    fullWidth
                    error={
                        error && profileDetails.twitter_link && !validateUrl(profileDetails.twitter_link)
                    }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Twitter color="info" />
                            </InputAdornment>
                        ),
                    }}
                    helperText={
                        error && profileDetails.twitter_link && !validateUrl(profileDetails.twitter_link) ?
                            "Please enter a valid twitter link" : "Optional"
                    }
                />
            </Box>

            <Box sx={{ mt: 3, width: { md: "60%", sm: "100%" } }}>
                {/* <TextField
                    id="description"
                    label="Description"
                    placeholder="Description"
                    variant="standard"
                    required
                    value={profileDetails.description}
                    onChange={handleChange}
                    name="description"
                    disabled={!edit}
                    multiline
                    helperText="50-1500 characters"
                    fullWidth
                    error={error && !profileDetails.description}
                    inputProps={{
                        maxLength: 1500,
                    }}
                /> */}
                <Editor
                    name="description"
                    value={profileDetails.description}
                    placeholder="Enter Description"
                    lable="Description"
                    error={error && (
                        profileDetails.description.length < 100 ||
                        profileDetails.description.length > 1500
                    )}
                    onChange={(data) => {
                        setProfileDetails({
                            ...profileDetails,
                            description: data,
                        });
                    }}
                    min={100}
                    max={1500}
                    required={true}
                    disabled={!edit}
                />
            </Box>

            <Box sx={{ mt: 3, display: "flex", gap: "20px" }}>
                {edit ? (
                    <>
                        <Button
                            variant="contained"
                            color="error"
                            size="large"
                            sx={{ width: "100px" }}
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            size="large"
                            sx={{ width: "100px" }}
                            onClick={() => setDialogBox(true)}
                            disabled={!profileDetails.name || !profileDetails.highlight || !profileDetails.email}
                        >
                            Save
                        </Button>
                    </>
                ) : (
                    <Button
                        variant="contained"
                        color="info"
                        size="large"
                        sx={{ width: "100px" }}
                        onClick={handleEdit}
                    >
                        Edit
                    </Button>
                )}
            </Box>

            <DialogBox
                open={dialogBox}
                title="Confirmation"
                description="Are you sure you want to edit your details ?"
                handleClose={() => setDialogBox(false)}
                onProceedClick={() => {
                    handleSave()
                }}
            />

            <ConfirmBox
                {...confirmBox}
                handleClose={() => {
                    setConfirmBox({
                        open: false,
                        type: "",
                        message: "",
                    })
                }}
                onOkClick={() => {
                    setConfirmBox({
                        open: false,
                        type: "",
                        message: "",
                    })
                }}
            />
        </Box>
    );
};

export default ProfileDetails;

import {
    GET_GRADES,
    GET_GRADES_SUCCESS,
    GET_GRADES_FAILURE,
} from '../actions/grades';

const initialState = {
    grades: [],
    loading: false,
    error: null
}

export const grades = (data = initialState, action) => {
    switch (action.type) {
        case GET_GRADES:
            return {
                ...data,
                loading: true,
                error: null
            }

        case GET_GRADES_SUCCESS:
            return {
                ...data,
                loading: false,
                grades: action.payload
            }

        case GET_GRADES_FAILURE:
            return {
                ...data,
                loading: false,
                error: action.payload,
            }

        default:
            return data
    }
}

// endpoints 
export const authEndpoints = {
    sendOtp: '/login',
    verifyOtp: 'loginverify',
    resendOtp: '/resendotp',
    getUser: '/loggedIn',
}

export const expEndpoints = {
    getAllExperiences: '/experience',
    getExperience: '/experience/',
    createExperience: '/experience',
    updateExperience: '/experience',
    deleteExperience: '/experience/',
}

export const subjectsEndpoints = {
    getAllSubjects: '/subject',
    getSubject: '/subject/',
    createSubject: '/subject',
    updateSubject: '/subject',
    deleteSubject: '/subject',
}

export const countiresEndpoints = {
    getAllCountries: '/country',
    getCountry: '/country/',
    createCountry: '/country',
    updateCountry: '/country',
    deleteCountry: '/country',
}

export const hobbiesEndpoints = {
    getAllHobbies: '/hobby',
    getHobby: '/hobby/',
    createHobby: '/hobby',
    updateHobby: '/hobby',
    deleteHobby: '/hobby',
}

export const statesEndpoints = {
    getAllStates: '/state',
    getState: '/state/',
    createState: '/state',
    updateState: '/state',
    deleteState: '/state',
}

export const citiesEndpoints = {
    getAllCities: '/city',
    getCity: '/city/',
    createCity: '/city',
    updateCity: '/city',
    deleteCity: '/city',
}

export const rolesEndpoints = {
    getAllRoles: '/role',
    getRole: '/role/',
    createRole: '/role',
    updateRole: '/role',
    deleteRole: '/role',
}

export const gradesEndpoints = {
    getAllGrades: '/grade',
    getGrade: '/grade/',
    createGrade: '/grade',
    updateGrade: '/grade',
    deleteGrade: '/grade',
}

export const schoolsEndpoints = {
    getAllSchools: '/school',
    getSchool: '/school/',
    createSchool: '/school',
    updateSchool: '/school',
    deleteSchool: '/school',
}


export const partnerEndpoints = {
    getAllPartners: '/partner',
    getPartner: '/partner/',
    createPartner: '/partner',
    updatePartner: '/partner',
    deletePartner: '/partner',
}

export const mediaEndpoints = {
    uploadMedia: '/media-upload'
}

export const collectionsEndpoints = {
    getAllCollections: '/collection',
    getCollection: '/collection/',
    createCollection: '/collection',
    updateCollection: '/collection',
    deleteCollection: '/collection',
}

export const callbacksEndpoints = {
    getAllCallbacks: '/callback',
}

export const partnerImagesEndpoints = {
    getAllPartnersImages: '/partner/image',
    getPartnerImages: '/partner/',
    createPartnerImage: '/partner/image',
    deletePartnerImage: '/partner/image',
}

export const dashboardEndpoints = {
    getUpcommingTours: '/dashboard/upcoming-tour',
    getPastTours: '/dashboard/past-tour',
}

export const experienceTypesEndpoints = {
    getAllExperienceTypes: '/experience-type',
    getExperienceType: '/experience-type/',
    createExperienceType: '/experience-type',
    updateExperienceType: '/experience-type',
    deleteExperienceType: '/experience-type',
}

export const destinationTypesEndpoints = {
    getAllDestinationTypes: '/destination-type',
    getDestinationType: '/destination-type/',
    createDestinationType: '/destination-type',
    updateDestinationType: '/destination-type',
    deleteDestinationType: '/destination-type',
}

export const schoolTripsEndpoints = {
    getAllSchoolTrips: '/school-booking',
}

export const bannerEdnpoints = {
    getAllBanners: '/banner',
    getBanner: '/banner/',
    createBanner: '/banner',
    updateBanner: '/banner',
    deleteBanner: '/banner',
}

export const transactionEndpoints = {
    getAllTransactions: '/transactions',
}


import {
    GET_COLLECTIONS,
    GET_COLLECTIONS_SUCCESS,
    GET_COLLECTIONS_FAILURE,
} from '../actions/collections';

import { collectionsApi } from '../api';

import { call, put, takeEvery } from 'redux-saga/effects';

function* getAllCollections() {
    try {
        const response = yield call(collectionsApi.getAllCollections);
        yield put({ type: GET_COLLECTIONS_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_COLLECTIONS_FAILURE, payload: error });
    }
}

export default function* collectionsSaga() {
    yield takeEvery(GET_COLLECTIONS, getAllCollections);
}
import {
    Box,
    TextField,
    Grid,
    Button,
} from "@mui/material"

import { useState } from "react"
import { useDispatch } from "react-redux";
import { updatePartner } from "../../services/actions/auth";
import { partnersApi } from "../../services/api";
import { DialogBox, ConfirmBox } from "../../components";

const AccountDetails = ({ profileDetails, setProfileDetails }) => {
    const [edit, setEdit] = useState(false)
    const dispatch = useDispatch();
    const [confAccNum, setConfAccNum] = useState(profileDetails.account_number || "")
    const [dialogBox, setDialogBox] = useState(false)
    const [confirmBox, setConfirmBox] = useState({
        open: false,
        type: "",
        message: "",
    });

    const [error, setError] = useState("")

    const handleEdit = () => {
        setEdit(true)
    }

    const handleCancel = () => {
        setEdit(false)
    }

    const handleSave = async () => {
        if (
            !profileDetails.account_holder_name ||
            !profileDetails.account_number ||
            !profileDetails.ifsc_code ||
            profileDetails.account_number.toString() !== confAccNum.toString()
        ) {
            setError("Account number does not match")
            return;
        }
        setEdit(false);
        setDialogBox(false)
        setError("")
        const { data } = await partnersApi.updatePartner(profileDetails);
        if (data.success) {
            setConfirmBox({
                open: true,
                type: "success",
                message: "Profile edited successfully",
            })
            dispatch(updatePartner(profileDetails.id));
        } else {
            setConfirmBox({
                open: true,
                type: "error",
                message: "Something went wrong. Try again!",
            })
        }
    };

    return (
        <Box sx={{ pt: 2 }}>
            <Grid container spacing={5} sx={{ flexDirection: "column" }}>
                <Grid item>
                    <Grid container spacing={5}>
                        <Grid item>
                            <TextField
                                id="account-holder-name"
                                label="Account Holder Name"
                                placeholder="Name"
                                variant="standard"
                                required
                                value={profileDetails.account_holder_name || ""}
                                onChange={(e) => {
                                    setProfileDetails({ ...profileDetails, account_holder_name: e.target.value })
                                }}
                                name="accName"
                                disabled={!edit}
                            />

                        </Grid>

                        <Grid item>
                            <TextField
                                id="account-number"
                                label="Account Number"
                                placeholder="Account Number"
                                variant="standard"
                                type="number"
                                required
                                value={profileDetails.account_number || ""}
                                onChange={(e) => {
                                    setProfileDetails({ ...profileDetails, account_number: e.target.value })
                                }}
                                name="accNumber"
                                disabled={!edit}
                            />
                            {
                                error && <p style={{ color: "red", marginTop: "4px", fontSize: "12px" }}>{error}</p>
                            }
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Grid container spacing={5}>
                        <Grid item>
                            <TextField
                                id="confirm-account-number"
                                label="Confirm Account Number"
                                placeholder="Confirm Account Number"
                                variant="standard"
                                required
                                value={confAccNum}
                                onChange={(e) => {
                                    setConfAccNum(e.target.value)
                                }}
                                name="confirmAccNumber"
                                disabled={!edit}
                            />
                            {
                                error && <p style={{ color: "red", marginTop: "4px", fontSize: "12px" }}>{error}</p>
                            }
                        </Grid>

                        <Grid item>
                            <TextField
                                id="ifsc-code"
                                label="IFSC Code"
                                placeholder="IFSC Code"
                                variant="standard"
                                required
                                value={profileDetails.ifsc_code || ""}
                                onChange={(e) => {
                                    setProfileDetails({ ...profileDetails, ifsc_code: e.target.value })
                                }}
                                name="ifscCode"
                                disabled={!edit}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Box sx={{ mt: 3, display: "flex", gap: "20px" }}>
                {
                    edit ? (
                        <>
                            <Button
                                variant="contained"
                                color="error"
                                size="large"
                                sx={{ width: "100px" }}
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="success"
                                size="large"
                                sx={{ width: "100px" }}
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        </>
                    ) : (
                        <Button
                            variant="contained"
                            color="info"
                            size="large"
                            sx={{ width: "100px" }}
                            onClick={handleEdit}
                        >
                            Edit
                        </Button>

                    )
                }
            </Box>

            <DialogBox
                open={dialogBox}
                title="Confirmation"
                description="Are you sure you want to edit your details ?"
                handleClose={() => setDialogBox(false)}
                onProceedClick={() => {
                    handleSave()
                }}
            />

            <ConfirmBox
                {...confirmBox}
                handleClose={() => {
                    setConfirmBox({
                        open: false,
                        type: "",
                        message: "",
                    })
                }}
                onOkClick={() => {
                    setConfirmBox({
                        open: false,
                        type: "",
                        message: "",
                    })
                }}
            />
        </Box>
    )
}

export default AccountDetails
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

const DeleteConfirmBox = ({ open, handleClose, onProceedClick, loading }) => {

    return (
        <Dialog open={open}>
            <DialogTitle>
                Deleting one item
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this ?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="success" onClick={handleClose} autoFocus>
                    No
                </Button>
                <Button color="error" disabled={loading} onClick={onProceedClick} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteConfirmBox;
import {
    Typography,
    Box,
    Modal,
    Container,
    Button,
    TextField,
    Grid,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getPartners } from "../../services/actions/partner"
import { getRoles } from "../../services/actions/roles"
import HandshakeIcon from '@mui/icons-material/Handshake';
import { partnersApi } from "../../services/api"
import { PartnerTable } from "./Table"
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from "@mui/material";
import { BackdropLoading, DeleteConfirmBox } from "../../components"

export const Partners = () => {
    const dispatch = useDispatch()
    const { partners, loading, error } = useSelector(state => state.partners)
    const { roles, loading: rolesLoading } = useSelector(state => state.roles)

    const [openAddNew, setOpenAddNew] = useState(false)
    const [partnerData, setPartnerData] = useState({
        name: "",
        email: "",
        mobile_number: "",
        role_id: "",
    })
    const [action, setAction] = useState("add")
    const [partnerId, setPartnerId] = useState(null)
    const [openDeleteConf, setOpenDeleteConf] = useState(false)

    const handleChange = (e) => {
        setPartnerData({
            ...partnerData,
            [e.target.name]: e.target.value
        })
    }

    const handleOpenModal = () => {
        setOpenAddNew(true)
    }

    const handleCloseModal = () => {
        setOpenAddNew(false)
        setPartnerData({})
        setAction("add")
        setPartnerId(null)
    }

    const handleOpenDeleteConf = () => {
        setOpenDeleteConf(true)
    }

    const handleCloseDeleteConf = () => {
        setOpenDeleteConf(false)
        setPartnerId(null)
    }

    const handleSubmit = async () => {
        try {
            const response = await partnersApi.createPartner({
                ...partnerData,
                city_id: 5,
                description: "",
                highlight: "New Partner",
                cover_photo: "",
                profile_photo: "",
            })
            if (response.data.success) {
                dispatch(getPartners())
                handleCloseModal()
            } else {
                console.error("Error", response.data.message)
            }
        } catch (error) {
            console.error("Error", error)
        }
    }

    const handleEditModal = (id) => {
        setAction("edit")
        handleOpenModal()
        setPartnerData(() => {
            const data = partners.find(partner => partner.id === id)
            return {
                id: data.id,
                name: data.name,
                email: data.email,
                mobile_number: data.user.mobile_number,
                role_id: data.role.role.id,
                highlight: data.highlight,
                description: data.description,
                cover_photo: data.cover_photo,
                profile_photo: data.profile_photo,
                city_id: data.city?.id  || 1,
            }
        })
        setPartnerId(id)
    }

    const handleEditSubmit = async () => {
        try {
            const response = await partnersApi.updatePartner(partnerData)
            if (response.data.success) {
                dispatch(getPartners())
                handleCloseModal()
            } else {
                console.error("Error", response.data.message)
            }
        } catch (error) {
            console.error("Error", error)
        }
    }

    const handleDelete = async (id) => {
        if (!id) return
        try {
            const response = await partnersApi.deletePartner({
                id
            })
            if (response.data.success) {
                dispatch(getPartners())
            } else {
                console.error("Error", response.data.message)
            }
        } catch (error) {
            console.error("Error", error)
        } finally {
            handleCloseDeleteConf()
        }
    }

    const handleDeleteConfrim = (id) => {
        handleOpenDeleteConf()
        setPartnerId(id)
    }

    useEffect(() => {
        dispatch(getPartners())
        dispatch(getRoles())
    }, [dispatch])

    if (loading || rolesLoading) return <BackdropLoading open={true} />
    if (error) return <div>Error</div>

    return (
        <Box>
            <Grid container sx={{ justifyContent: "space-between", py: 2, px: 5 }}>
                <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <HandshakeIcon sx={{ fontSize: 40 }} />
                        <Typography variant='h5' component='h5' ml={1}>
                            Partners
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <Button
                            variant='contained'
                            onClick={handleOpenModal}
                        >
                            Add New
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            <Box sx={{ px: 2, py: 3 }}>
                {
                    partners && partners.length > 0 ? (
                        <PartnerTable
                            data={partners}
                            handleEdit={handleEditModal}
                            handleDelete={handleDeleteConfrim}
                        />
                    ) : (
                        <Typography variant="h5" component="h5" align="center">
                            No partners yet.
                        </Typography>
                    )
                }
            </Box>

            <Modal open={openAddNew}>
                <Box sx={{
                    p: 2,
                    pb: 4,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "100%",
                    maxWidth: "600px",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    borderRadius: "5px",
                }}
                >

                    <Container>
                        <Grid container sx={{ justifyContent: "space-between", }}>
                            <Grid item>
                                <Typography variant='h5' component='h5' ml={1}>
                                    {action === "edit" ? "Update" : "Add new"} partner
                                </Typography>

                            </Grid>
                            <Grid item>
                                <IconButton onClick={handleCloseModal}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>

                        <Box sx={{ px: 2 }}>

                            <TextField
                                name="name"
                                fullWidth
                                label="Name"
                                placeholder="Enter name"
                                variant="standard"
                                autoComplete="off"
                                sx={{ my: 1 }}
                                value={partnerData.name}
                                onChange={handleChange}
                            />

                            <TextField
                                name="email"
                                fullWidth
                                label="Email"
                                placeholder="Enter email"
                                variant="standard"
                                autoComplete="off"
                                sx={{ my: 1 }}
                                value={partnerData.email}
                                onChange={handleChange}
                            />

                            <TextField
                                name="mobile_number"
                                fullWidth
                                label="Phone Number"
                                placeholder="Enter phone number"
                                variant="standard"
                                autoComplete="off"
                                type="number"
                                sx={{ my: 1 }}
                                value={partnerData.mobile_number}
                                onChange={handleChange}
                                disabled={action === "edit"}
                            />

                            {
                                action === "add" && (
                                    <FormControl
                                        fullWidth
                                        required
                                        variant="standard"
                                        sx={{ my: 1 }}
                                    >
                                        <InputLabel>Role</InputLabel>
                                        <Select
                                            value={partnerData.role_id}
                                            label="Role"
                                            onChange={handleChange}
                                            name="role_id"
                                            renderValue={(selected) => {
                                                const role = roles.find(role => role.id === selected);
                                                return role?.name;
                                            }}
                                        >
                                            {roles.map((role) => (
                                                <MenuItem key={role.id} value={role.id}>
                                                    {role.name}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                    </FormControl>
                                )
                            }

                            <Button
                                variant="contained"
                                fullWidth size="large"
                                disabled={
                                    loading ||
                                    !partnerData.name ||
                                    !partnerData.email ||
                                    !partnerData.mobile_number ||
                                    !partnerData.role_id
                                }
                                onClick={() => {
                                    action === "edit" ? handleEditSubmit(partnerId) : handleSubmit()
                                }}
                                sx={{ my: 2 }}
                            >
                                {
                                    loading ? (
                                        <CircularProgress />
                                    ) : (
                                        action === "edit" ? "Update" : "Add Partner"
                                    )
                                }
                            </Button>
                        </Box>
                    </Container>

                </Box>
            </Modal >

            <DeleteConfirmBox
                open={openDeleteConf}
                handleClose={handleCloseDeleteConf}
                onProceedClick={() => handleDelete(partnerId)}
            />
        </Box >
    )
}
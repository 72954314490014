import {
    GET_UPCOMMING_TOURS,
    GET_UPCOMMING_TOURS_SUCCESS,
    GET_UPCOMMING_TOURS_FAILURE,
    GET_PAST_TOURS,
    GET_PAST_TOURS_SUCCESS,
    GET_PAST_TOURS_FAILURE,
} from "../actions/dashboardData";
import { takeEvery, call, put } from "redux-saga/effects";
import { dashboardDataApi } from '../api';

function* getUpcommingTours() {
    try {
        const response = yield call(dashboardDataApi.getUpcommingTours);
        yield put({ type: GET_UPCOMMING_TOURS_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_UPCOMMING_TOURS_FAILURE, payload: error });
    }
}

function* getPastTours() {
    try {
        const response = yield call(dashboardDataApi.getPastTours);
        yield put({ type: GET_PAST_TOURS_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_PAST_TOURS_FAILURE, payload: error });
    }
}

export default function* dashboardDataSaga() {
    yield takeEvery(GET_UPCOMMING_TOURS, getUpcommingTours);
    yield takeEvery(GET_PAST_TOURS, getPastTours);
}
import { call, put, takeEvery } from 'redux-saga/effects';
import { subjectsApi } from '../api';
import {
    GET_SUBJECTS,
    GET_SUBJECTS_SUCCESS,
    GET_SUBJECTS_FAILURE,
    GET_SUBJECT,
    GET_SUBJECT_SUCCESS,
    GET_SUBJECT_FAILURE,
} from '../actions/subjects';


function* getSubjects() {
    try {
        const response = yield call(subjectsApi.getSubjects);
        yield put({ type: GET_SUBJECTS_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_SUBJECTS_FAILURE, payload: error });
    }
}

function* getSubject(payload) {
    try {
        const response = yield call(subjectsApi.getSubject, payload);
        yield put({ type: GET_SUBJECT_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_SUBJECT_FAILURE, payload: error });
    }
}

export default function* subjectsSaga() {
    yield takeEvery(GET_SUBJECTS, getSubjects);
    yield takeEvery(GET_SUBJECT, getSubject);
}


import {
    GET_HOBBIES,
    GET_HOBBIES_SUCCESS,
    GET_HOBBIES_FAILURE,
} from '../actions/hobby';

import { hobbiesApi } from '../api';

import { call, put, takeEvery } from 'redux-saga/effects';

function* getHoobies() {
    try {
        const response = yield call(hobbiesApi.getAllHobbies);
        yield put({ type: GET_HOBBIES_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_HOBBIES_FAILURE, payload: error });
    }
}

export default function* hobbiesSaga() {
    yield takeEvery(GET_HOBBIES, getHoobies);
}
